import { ui } from './ui';
import { auth } from './auth';
import { users } from './users';
import { solutions } from './solutions';
import { company } from './company';
import { challenges } from './challenges';
import { inbox } from './inbox';
import { notifications } from './notifications';
import { licenses } from './licenses';
import { wallet } from './wallet'


const middleware = {
  ...ui,
  ...auth,
  ...users,
  ...solutions,
  ...company,
  ...challenges,
  ...inbox,
  ...notifications,
  ...licenses,
  ...wallet
}

export default middleware