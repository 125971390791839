export const LOAD_USER_SOLUTIONS = '[solutions] load user solutions';
export const LOAD_SOLUTIONS_SUCCESS = '[solutions] load solutions success';
export const LOAD_USER_SOLUTIONS_SUCCESS = '[solutions] load user solutions success';
export const SUBMIT_SOLUTION = '[solutions] submit solution';
export const UPLOAD_SOLUTION_PHOTOS = '[solutions] upload solution photos';
export const LOAD_SOLUTIONS = '[solutions] load solutions';
export const SEARCH_SOLUTIONS = '[solutions] search solutions';
export const LOAD_SOLUTION_BY_ID = '[solutions] load solution by id';
export const VOTE_SOLUTION = '[solutions] vote solution';
export const LOAD_SOLUTION_COMMENTS = '[solutions] load solution comments'
export const SUBMIT_COMMENT = '[solutions] submit comment'
export const LOAD_SOLUTION_FEED = '[solutions] load solution feed'
export const FAVORITE_SOLUTION = '[solutions] favorite solution'
export const LOAD_FAVORITE_SOLUTIONS = '[solutions] load favorite solutions'
export const LOAD_SOLUTIONS_FOR_CHALLENGE = '[solutions] load solutions for challenge'
export const LOAD_BOUGHT_SOLUTIONS = '[solutions] load bought solutions'
export const SHARE_SOLUTION = '[solutions] share'
export const LOAD_SOLUTION_OVERVIEW = '[solutions] load solution overview'
export const DELETE_SOLUTION = '[solutions] delete'


export const loadUserSolutions = users => ({
    type: LOAD_USER_SOLUTIONS,
    payload: users,
});

export const loadSolutionsForChallenge = challenge => ({
    type: LOAD_SOLUTIONS_FOR_CHALLENGE,
    payload: challenge,
})

export const voteSolution = solution => ({
    type: VOTE_SOLUTION,
    payload: solution,
});

export const deleteSolution = id => ({
    type: DELETE_SOLUTION,
    payload: id,
});

export const loadSolutionById = id => ({
    type: LOAD_SOLUTION_BY_ID,
    payload: id,
});

export const loadSolutionOverview = id => ({
    type: LOAD_SOLUTION_OVERVIEW,
    payload: id,
});

export const loadSolutionFeed = {
    type: LOAD_SOLUTION_FEED
}

export const loadSolutionComments = id => ({
    type: LOAD_SOLUTION_COMMENTS,
    payload: id,
});

export const loadUserSolutionsSuccess = users => ({
    type: LOAD_USER_SOLUTIONS_SUCCESS,
    payload: users,
});

export const submitSolution = solution => ({
    type: SUBMIT_SOLUTION,
    payload: solution,
});

export const submitComment = comment => ({
    type: SUBMIT_COMMENT,
    payload: comment,
});

export const uploadSolutionPhotos = pl => ({
    type: UPLOAD_SOLUTION_PHOTOS,
    payload: pl,
});

export const searchSolutions = query => ({
    type: SEARCH_SOLUTIONS,
    payload: query,
});

export const shareSolution = solution => ({
    type: SHARE_SOLUTION,
    payload: solution,
});

export const loadSolutions = {
    type: LOAD_SOLUTIONS
}

export const favoriteSolution = solution => ({
    type: FAVORITE_SOLUTION,
    payload: solution,
});

export const loadFavoriteSolutions = {
    type: LOAD_FAVORITE_SOLUTIONS
}

export const loadBoughtSolutions = {
    type: LOAD_BOUGHT_SOLUTIONS
}

