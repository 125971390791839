import * as actions from '../actions/inbox';

const initialState = {
  inbox: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case (actions.LOAD_INBOXES_SUCCESS):
      return { ...state, inbox: action.payload }
    default:
      return state;
  }
}