import { useStyletron } from 'baseui';
import { Combobox } from 'baseui/combobox';
import { ChevronDown } from 'baseui/icon';
import { ParagraphMedium } from 'baseui/typography';


const ReDoPicklist = ({ name, required, placeholder, options, label, ...props }) => {
  const [css, theme] = useStyletron();

  return (
    <div>
      <ParagraphMedium marginTop='0' paddingLeft='5px'>{label}</ParagraphMedium>
      <div className={css({ position: 'relative' })}>

        <Combobox
          mapOptionToString={o => o.label}
          options={options}
          {...props}
          overrides={{
            Input: {
              props: {
                required: required,
                name: name,
                endEnhancer: <ChevronDown size={theme.sizing.scale950} />,
                overrides: {
                  Root: {
                    style: ({ $theme }) => ({
                      borderRadius: '200px',
                      height: $theme.sizing.scale1400,
                      border: `2px solid ${theme.colors.primary300}`,
                      backgroundColor: theme.colors.primary100,
                      padding: '0 1ch !important',
                    })
                  },
                  Input: {
                    style: ({ $theme }) => ({
                      backgroundColor: theme.colors.primary100,
                      paddingLeft: $theme.sizing.scale600,
                      paddingRight: $theme.sizing.scale600,
                      color: $theme.colors.black,
                      '::placeholder': {
                        color: $theme.colors.black,
                      },
                    }),
                    props: {
                      placeholder: placeholder
                    },
                  },
                  EndEnhancer: {
                    style: ({ $theme }) => ({
                      backgroundColor: theme.colors.primary100
                    })
                  }
                }
              }
            }
          }}
        />

      </div>
    </div>
  );
}

export default ReDoPicklist;