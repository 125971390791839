export const PAGE_LOADED = '[ui] page loaded';
export const ROUTE_TO = '[ui] route to';
export const ROUTE_BACK = '[ui] route back';
export const SET_VIEW = '[ui] render view';
export const CACHE_VIEW = '[ui] cache view';
export const SET_ACTIVE_TAB = '[ui] set active key';
export const SET_PORTAL_ELEMENT = '[ui] set portal element';
export const PROCESS_IMAGE_UPLOAD = '[ui] process image upload';

export const pageLoaded = {
  type: PAGE_LOADED
};

export const routeTo = pl => ({
  type: ROUTE_TO,
  payload: pl
});

export const setView = pl => ({
  type: SET_VIEW,
  payload: pl
});

export const cacheView = pl => ({
  type: CACHE_VIEW,
  payload: pl
});

export const setPortalElement = pl => ({
  type: SET_PORTAL_ELEMENT,
  payload: pl
});

export const routeBack = {
  type: ROUTE_BACK,
}

export const setActiveTab = activeTab => ({
  type: SET_ACTIVE_TAB,
  payload: activeTab,
});

export const processImageUpload = images => ({
  type: PROCESS_IMAGE_UPLOAD,
  payload: images,
});