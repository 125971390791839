import { LOAD_INBOXES, SUBMIT_INBOX, loadInboxesSuccess } from "../actions/inbox";


export const inbox = {

  [LOAD_INBOXES]: async ({ api, dispatch, getState, action }) => {
    const response = await api.inbox.getInboxes();

    const timeSince = (dateString) => {
      const date = new Date(dateString);
      const today = new Date();
      const timeDiff = today.getTime() - date.getTime();
      const minutesDiff = Math.round(timeDiff / (1000 * 60));

      if (minutesDiff < 60 * 24) {
        return new Date(date).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })
      } else if (minutesDiff < 60 * 24 * 30) {
        const daysDiff = Math.floor(minutesDiff / (60 * 24));
        return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
      } else if (minutesDiff < 60 * 24 * 365) {
        const monthsDiff = Math.floor(minutesDiff / (60 * 24 * 30));
        return `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ago`;
      } else {
        const yearsDiff = Math.floor(minutesDiff / (60 * 24 * 365));
        return `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} ago`;
      }
    }

    const mappedInboxes = response.data?.map((inbox) => ({
      ...inbox,
      id: inbox.id,
      user: { photo: inbox.recipientPhotoURL },
      headline: inbox.recipientDisplayName,
      subHeadline: timeSince(inbox.updatedAt),
      subtitle: inbox.lastMessage,
      unread: inbox.unreadMessageCount,
    }))

    dispatch(loadInboxesSuccess(mappedInboxes))

    return mappedInboxes
  },

  [SUBMIT_INBOX]: async ({ api, dispatch, getState, action }) => {
    const response = await api.inbox.submitInbox(action.payload);
    return response.data
  },


}