import { Auth } from 'aws-amplify';


export const withAuth = (endpointFn) => {
  return async (params, options) => {
    const cs = await Auth.currentSession()
    const token = cs.getIdToken().getJwtToken();

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    options = { ...options, headers }

    const response = await endpointFn(params, options);
    return response;
  };
};


export const baseUrl = process.env.REACT_APP_BASE_URL;