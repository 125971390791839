import axios from 'axios';
import { baseUrl, withAuth } from '../auth/helper';

export default {
  getCompany: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/company`, options)
    return response
  }),
  getCompanyById: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/company/${params}`, options)
    return response
  }),
  createCompany: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/company`, params, options)
    return response
  }),
  uploadLogo: withAuth(async (params, options) => {
    const response = await axios.put(`${baseUrl}/company/photo`, params, options)
    return response
  })
}