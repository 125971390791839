import { useStyletron } from "baseui";
import { ProgressBar } from "baseui/progress-bar";
import { DisplaySmall, ParagraphLarge } from "baseui/typography";

const ReDoProgressBar = ({ label, maxValue, value, kind }) => {

  const [css, theme] = useStyletron();

  if (kind === 'text') {
    return (
      <div className={css({ display: 'flex', alignItems: 'center' })}>
        <ParagraphLarge margin='0'>{value}</ParagraphLarge>
        <ParagraphLarge margin='0' color='rgba(0,0,0,0.5)'>/{maxValue}</ParagraphLarge>
      </div>
    )
  }

  return (
    <div>
      <DisplaySmall margin='0'>{label}</DisplaySmall>
      <div className={css({ display: 'flex', alignItems: 'center' })}>
        <ProgressBar
          overrides={{
            Bar: {
              style: () => ({
                position: 'relative',
                backgroundColor: 'transparent',
                borderRadius: '5px',
                ':after': {
                  content: '""',
                  position: 'absolute',
                  left: '0',
                  height: '1px',
                  width: '100%',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }
              })
            },
            BarProgress: {
              style: () => ({
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
                backgroundColor: theme.colors.black,
              })
            },
            BarContainer: {
              style: () => ({
                marginLeft: '0'
              })
            },
          }}
          value={value}
          maxValue={maxValue}
        />
        <div className={css({ display: 'flex', alignItems: 'center' })}>
          <ParagraphLarge>{value}</ParagraphLarge>
          <ParagraphLarge color='rgba(0,0,0,0.5)'>/{maxValue}</ParagraphLarge>
        </div>
      </div>
    </div>
  )
}

export default ReDoProgressBar;