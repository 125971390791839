import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { NotificationCircle } from 'baseui/badge';
import { Block } from 'baseui/block';
import { LabelMedium, ParagraphMedium, ParagraphSmall } from 'baseui/typography';


const ReDoMenuCard = ({ options, onClick }) => {
  const [css, theme] = useStyletron();

  return (
    <Block backgroundColor='white' padding='0 1.5ch' overrides={{ Block: { style: { borderRadius: '8px', ':hover': { cursor: 'pointer' } } } }}>

      {!!options.length ? options.map((item, index) =>
        <Block key={item.id} onClick={(e) => onClick && onClick(e, item)} alignItems='baseline' width='100%' padding='16px 0' overrides={{ Block: { style: { borderBottom: options.length > 1 && index !== options.length - 1 ? `1px solid ${theme.colors.primary100}` : '' } } }} display='flex' justifyContent='space-between'>

          <Block width='100%' gridGap='1ch' display='flex'>

            <Block paddingTop='1px' overrides={{ Block: { style: { flexShrink: '0' } } }}>
              <Avatar
                name={item.user.name}
                size="48px"
                src={item.user.photo}
              />
            </Block>

            <Block width='100%' display='flex' flexDirection='column'>

              <Block display='flex' alignItems='center' flexWrap='wrap'>
                {!!item.headline &&
                  <Block width='100%' display='flex' alignItems='center' justifyContent='space-between'>
                    <ParagraphMedium overrides={{ Block: { style: { margin: '0', fontWeight: '700', marginRight: '0.5ch' } } }}>
                      {item.headline}
                    </ParagraphMedium>
                    <ParagraphSmall color={theme.colors.primary200} overrides={{ Block: { style: { margin: '0' } } }}>
                      {item.subHeadline}
                    </ParagraphSmall>
                  </Block>
                }

                <ParagraphSmall overrides={{ Block: { style: { margin: '0', fontWeight: '700', marginRight: '0.5ch' } } }}>
                  {item.user.name}
                </ParagraphSmall>
                <ParagraphSmall color={!item.unread ? theme.colors.primary200 : 'black'} overrides={{ Block: { style: { fontWeight: '500', margin: '0' } } }}>
                  {item.title}
                </ParagraphSmall>

              </Block>

              <Block display='flex' alignItems='center' justifyContent='space-between'>
                <ParagraphSmall color={item.unread ? 'black' : theme.colors.primary200} overrides={{ Block: { style: { margin: '0' } } }}>
                  {item.subtitle}
                </ParagraphSmall>
                {!!item.unread && !item.type && <NotificationCircle content={item.unread} overrides={{ Badge: { style: { backgroundColor: theme.colors.badge } } }} />}
              </Block>

            </Block>

          </Block>

          {item.icon &&
            <Block overrides={{ Block: { style: { flexShrink: '0' } } }} height='48px' width='62px' position='relative'>
              <img className={css({ borderRadius: '8px', objectFit: 'cover', marginLeft: '1ch' })} height='48px' width='48px' src={item.icon} alt='' />
              {!!item.unread && <NotificationCircle content={item.unread} overrides={{ Badge: { style: { top: '-10px', right: '-5px', position: 'absolute', backgroundColor: theme.colors.badge } } }} />}
            </Block>
          }
        </Block>
      ) : (
        <Block marginTop='2ch' /*overrides={{ Block: { style: { textAlign: 'center' } } }}*/>
          <LabelMedium>Nothing to show yet. Check back later.</LabelMedium>
        </Block>
      )}

    </Block>
  );
}

export default ReDoMenuCard