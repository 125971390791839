import { LOAD_USER_SOLUTIONS_SUCCESS } from "../actions/solutions";


const initialState = {
  userSolutions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_SOLUTIONS_SUCCESS:
      return { ...state, userSolutions: action.payload };
    default:
      return state;
  }
}