export const LOAD_COMPANY_CHALLENGES = '[challenges] load company challenges';
export const LOAD_CHALLENGES_SUCCESS = '[challenges] load challenges success';
export const LOAD_COMPANY_CHALLENGES_SUCCESS = '[challenges] load company challenges success';
export const SUBMIT_CHALLENGE = '[challenges] submit challenge';
export const UPLOAD_CHALLENGE_PHOTOS = '[challenges] upload challenge photos';
export const LOAD_CHALLENGE_FEED = '[challenges] load challenge feed';
export const LOAD_CHALLENGE_BY_ID = '[challenges] load challenge by id';
export const LOAD_CHALLENGE_COMMENTS = '[challenges] load challenge comments';
export const SUBMIT_COMMENT = '[challenges] submit comment';
export const FAVORITE_CHALLENGE = '[challenges] favorite challenge';
export const LOAD_FAVORITE_CHALLENGES = '[challenges] load favorite challenges';
export const LOAD_CHALLENGES_FOR_SOLUTION = '[challenges] load challenges for solution'
export const SHARE_CHALLENGE = '[challenges] share challenge';
export const DELETE_CHALLENGE = '[challenges] delete challenge'


export const loadCompanyChallenges = companys => ({
    type: LOAD_COMPANY_CHALLENGES,
    payload: companys,
});

export const loadCompanyChallengesSuccess = companys => ({
    type: LOAD_COMPANY_CHALLENGES_SUCCESS,
    payload: companys,
});

export const loadChallengesForSolution = challenge => ({
    type: LOAD_CHALLENGES_FOR_SOLUTION,
    payload: challenge,
})

export const submitChallenge = challenge => ({
    type: SUBMIT_CHALLENGE,
    payload: challenge,
});

export const shareChallenge = challenge => ({
    type: SHARE_CHALLENGE,
    payload: challenge,
});

export const uploadChallengePhotos = pl => ({
    type: UPLOAD_CHALLENGE_PHOTOS,
    payload: pl,
});

export const loadChallengeFeed = {
    type: LOAD_CHALLENGE_FEED
}

export const loadChallengeById = id => ({
    type: LOAD_CHALLENGE_BY_ID,
    payload: id,
});

export const loadChallengeComments = id => ({
    type: LOAD_CHALLENGE_COMMENTS,
    payload: id,
});

export const submitComment = comment => ({
    type: SUBMIT_COMMENT,
    payload: comment,
});

export const favoriteChallenge = challenge => ({
    type: FAVORITE_CHALLENGE,
    payload: challenge,
});

export const loadFavoriteChallenges = {
    type: LOAD_FAVORITE_CHALLENGES
}

export const deleteChallenge = id => ({
    type: DELETE_CHALLENGE,
    payload: id,
});