import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { ANCHOR, Drawer, SIZE } from "baseui/drawer";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { ChevronLeft, ChevronRight, Delete } from "baseui/icon";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { HeadingXLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { forwardRef, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { submitSolution, uploadSolutionPhotos } from "../application/actions/solutions";
import { routeTo } from "../application/actions/ui";
import create_solution from '../assets/images/create_solution.png';
import diagram from '../assets/images/diagram.png';
import useBreakpoint from "../hooks/useBreakpoint";
import ReDoBanner from "./ReDoBanner";
import ReDoButton from "./ReDoButton";
import ReDoFileUpload from "./ReDoFileUpload";
import ReDoInterests from "./ReDoInterests";
import ReDoProgressBar from "./ReDoProgressBar";
import ReDoSpinner from './ReDoSpinner';
import ReDoTags from './ReDoTags';
import ReDoTextarea from "./RedoTextarea";


const stages = [
  { label: 'Title', curr: 1, max: 9 },
  { label: 'Status Quo', curr: 2, max: 9 },
  { label: 'Solution', curr: 3, max: 9 },
  { label: 'Value', curr: 4, max: 9 },
  { label: 'Life Cycle', curr: 5, max: 9 },
  { label: 'Areas', curr: 6, max: 9 },
  { label: 'Why', curr: 7, max: 9 },
  { label: 'Visuals', curr: 8, max: 9 },
  { label: 'Stage', curr: 9, max: 9 },
]

const areas = [
  'Energy',
  'Water',
  'Waste',
  'Procurement',
  'Materials',
  'Communication',
  'Supply Chains',
  'Packaging',
  'End of Life',
  'Infrastructure',
]

const solutionStage = [
  { group: '', list: ['EARLY \n(Idea or concept stage)', 'MID \n(First proof of concept exists, first user testing done)', 'FINAL \n(Prototype exists, research and testing results exist)'], color: '#fff' },
]

const ReDoCreateSolution = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const breakpoint = useBreakpoint()

  const { enqueue } = useSnackbar();

  const [stage, setStage] = useState(stages[0])
  const [payload, setPayload] = useState(props.solution || {})

  const [photos, setPhotos] = useState([])
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false)

  const scrollable = useRef()

  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    marginTop: '7ch',
    gap: '3ch'
  });

  const onNext = (e) => {
    e.preventDefault();
    scrollable.current.scrollTo(0, 0)

    const data = new FormData(e.target);
    const stepPayload = {}

    for (const pair of data.entries()) {
      if (stage.curr === 3 && pair[0] === 'type') {


        stepPayload[pair[0]] = pair[1]?.toLowerCase()?.split(' ')?.[0]
      } else if (stage.curr === 9 && pair[0] === 'stage') {
        stepPayload[pair[0]] = pair[1]?.toLowerCase()?.split(' ')?.[0]
      } else {
        stepPayload[pair[0]] = pair[1]
      }
    }

    const finalPayload = { ...payload, ...stepPayload }

    setPayload(finalPayload)

    const currStage = stages.findIndex(s => s.curr === stage.curr)
    if (currStage < stages.length - 1) {
      setStage(stages[currStage + 1])
    } else {
      setLoading(true)
      if (props.challenge?.id) {
        finalPayload.challengeId = props.challenge.id
      }
      dispatch(submitSolution(finalPayload)).then(async res => {
        let solution = res
        if (photos.length) {
          for (const photo of photos) {
            try {
              const photoRes = await dispatch(uploadSolutionPhotos({ solution: solution.id, title: photo.file ? photo.file.file.name : photo.title, photo: photo.photo }))
              solution = photoRes
            } catch (e) {
              setLoading(false)
              enqueue({ message: e.message + ' ' + e.response?.data?.error, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
            }
          }
        }
        setLoading(false)
        dispatch(routeTo({ view: 'solution', payload: { solution } }))
      }).catch(e => {
        setLoading(false)
        enqueue({ message: e.response.data.error, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> })
      })

    }
  }

  const onPrev = (e) => {
    e.preventDefault();

    const currStage = stages.findIndex(s => s.curr === stage.curr)
    if (currStage > 0) {
      setStage(stages[currStage - 1])
    }
  }

  const onUpload = (e) => {
    setPhotos(e.map(f => f.preview ? ({ ...f, photo: f.preview }) : f))
  };

  if (breakpoint === 'large') {
    return (
      <>
        {loading && <ReDoSpinner />}
        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 2]}
          flexGridRowGap="scale800"
          height='100%'
          alignItems='center'
          overflow='hidden'
          margin='0 -28px'
        >
          <FlexGridItem position='relative' height='100%' display='flex'>
            <ReDoBanner align>
              <div className={css({ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', padding: `${theme.sizing.scale1600} ${theme.sizing.scale3200}` })}>
                <div className={css({ paddingTop: theme.sizing.scale1600, maxWidth: '500px' })}>
                  <HeadingXLarge overrides={{ Block: { style: { lineHeight: '48px', fontWeight: '300' } } }} >Create Solution</HeadingXLarge>
                  {stage.curr === 1 &&
                    <Block paddingTop='2ch'>
                      <ParagraphMedium color={theme.colors.primary300}>
                        Complete this form and tell
                        us about your solution. Your
                        input helps us understand your
                        goals and approach. Take your time
                         - the more detail you share, the better!

                        *Please note that all of the information
                        you provide will be visible to the community.
                        There is an option in section
                        3 to upload 'hidden information'
                        which will only be accessible once your solution
                        is purchased.
                      </ParagraphMedium>
                    </Block>
                  }
                </div>
                <Block display='flex' flexDirection='column' marginTop='auto'>
                  <ReDoProgressBar label={stage.label} value={stage.curr} maxValue={stage.max} />
                </Block>
              </div>
            </ReDoBanner>
          </FlexGridItem>

          <FlexGridItem height='100%'>
            <div className={column}>
              <Block position='relative' marginTop={'70px'} height='calc(100% - 215px)'>

                <Block overrides={{ Block: { style: { zIndex: '1' } } }} display={['none', 'none', 'none', 'block']} position='absolute' top='0px' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgb(243,242,238) 0%, rgba(255, 255, 255, 0) 100%)' />

                <form ref={scrollable} className={css({ height: '100%', overflow: 'auto' })} onSubmit={onNext}>
                  <div className={css({ paddingTop: '20px', maxWidth: '375px', margin: 'auto', paddingBottom: '28px', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', paddingRight: '1ch' })}>

                    {stage.curr === 1 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Give your solution a catchy and memorable title.'
                              placeholder="Title"
                              name='title'
                              required
                              value={payload.title}
                              maxLength={20}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Summarize your solution in one sentence.'
                              placeholder='Summary'
                              maxLength={65}
                              name='summary'
                              required
                              value={payload.summary}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 2 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Which problem are you solving and why is it relevant?'
                              placeholder='Type here'
                              maxLength={500}
                              name='problem'
                              required
                              value={payload.problem}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='What product or service do you want to redo?'
                              placeholder='e.g. plastic packaging'
                              maxLength={40}
                              name='what'
                              required
                              value={payload.what}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 3 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Who is your solution adressed to? Who can 
                              generate the change you want to see? Name a 
                              company or industry.'
                              id="target-input"
                              name='target'
                              required
                              placeholder='Type here'
                              maxLength={40}
                              value={payload.target}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Describe your solution to the community. (This 
                                will be visible to the community)'
                              placeholder='Type here'
                              maxLength={500}
                              name='description'
                              required
                              value={payload.description}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Do you have additional information or research 
                              about your solution that you want to save to your 
                              dashboard? (This will not be visible to the 
                              community)'
                              placeholder='Type here'
                              maxLength={500}
                              name='note'
                              value={payload.note}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 4 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='What value is created with your solution for 
                              consumers, companies and our planet?'
                              placeholder='Type here'
                              maxLength={500}
                              name='createdValue'
                              required
                              value={payload.createdValue}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 5 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <ParagraphMedium marginTop='0' paddingLeft='5px'>
                            Take a look at the life cycle. At which
                            stages does your solution create a
                            positive impact? Please share your
                            thoughts.
                          </ParagraphMedium>
                          <img src={diagram} alt=''></img>
                          <a className={css({ textUnderlineOffset: '.5ch', fontWeight: 'normal', fontSize: '10px', color: theme.colors.secondary200 })} href="./">What is a life cycle?</a>
                        </Block>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              id="lifeCycle"
                              placeholder="Type here"
                              name='lifeCycle'
                              value={payload.lifeCycle}
                              maxLength={500}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 6 &&
                      <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                        <FormControl>
                          <ReDoTags
                            onChange={(e) => setPayload({ ...payload, areas: e.map(e => e.toLowerCase()) })}
                            label='Which areas is your solution positively 
                          affecting? Select up to 4 topics or 
                          write your own.'
                            options={areas}
                            limit={1}
                            additionLabel='Add your area'
                            values={payload.areas}
                            submitUpTo={4}
                          />
                        </FormControl>
                      </Block>
                    }

                    {stage.curr === 7 &&
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='What support do you need from the community to improve your idea?'
                            placeholder='Type here'
                            maxLength={500}
                            name='communityReason'
                            required
                            value={payload.communityReason}
                          />
                        </FormControl>
                      </Block>
                    }

                    {stage.curr === 8 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoFileUpload
                              multiple
                              maxCount={3}
                              onUpload={onUpload}
                              onRemove={onUpload}
                              label='Upload up to 3 images or drawings to promote 
                              and visualize your solution.'
                              values={payload?.photos?.map(f => ({ ...f, preview: f.photo }))}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 9 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <ReDoInterests
                            label='Please select which stage your solution is 
                            currently in.'
                            options={solutionStage}
                            values={payload?.stage}
                            name='stage'
                            radio
                          />
                        </Block>
                      </>
                    }

                    <Block paddingBottom='50px' justifyContent='space-between' display='flex' width='100%' marginTop='5%'>
                      {stage.curr !== 1 &&
                        <ReDoButton
                          type='button'
                          onClick={onPrev}
                          kind='inverse'
                          overrides={{
                            BaseButton: {
                              style: {
                                marginTop: '0',
                                marginBottom: '0',
                                paddingLeft: '0 !important',
                                paddingTop: '0 !important',
                                paddingBottom: '0 !important',
                                backgroundColor: 'transparent',
                                color: theme.colors.black,
                                width: 'auto',
                                fontWeight: 'bold'
                              }
                            },
                            StartEnhancer: {
                              style: {
                                marginRight: '0'
                              }
                            }
                          }}
                          startEnhancer={() => <ChevronLeft size={theme.sizing.scale1000} />}
                        >
                          Back
                        </ReDoButton>
                      }
                      <ReDoButton
                        type='submit'
                        kind='brand'
                        overrides={{
                          BaseButton: {
                            style: {
                              marginBottom: '0',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              paddingRight: '0',
                              width: 'auto',
                              marginLeft: 'auto'
                            }
                          },
                          EndEnhancer: {
                            style: {
                              marginLeft: '0'
                            }
                          }
                        }}
                        endEnhancer={() => <ChevronRight size={theme.sizing.scale1000} />}
                      >
                        {stage.curr !== 9 ? 'Next' : 'Submit'}
                      </ReDoButton>
                    </Block>

                  </div>
                </form>

                <Block display={['none', 'none', 'none', 'block']} position='absolute' bottom='0' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(243,242,238) 100%)' />

              </Block>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </>
    );
  } else {
    return (
      <>
        <Card
          title='Create Solution!'
          overrides={{
            Root: {
              style: {
                height: '75%', border: 'none', borderRadius: '25px !important',
                backgroundImage: `url(${create_solution})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                margin: 'auto 0'
              }
            },
            Title: {
              style: {
                color: 'white',
                fontSize: '2rem',
                fontWeight: 'normal',
              }
            },
            Contents: {
              style: {
                height: '100%',
                margin: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '2ch'
              }
            }
          }}
        >
          <StyledBody>
            <ParagraphMedium color='white'>
              Complete this form and tell
              us about your solution. Your
              input helps us understand your
              goals and approach. Take your time
              - the more detail you share, the better!

              *Please note that all of the information
              you provide will be visible to the community.
              There is an option in section
              3 to upload 'hidden information'
              which will only be accessible once your solution
              is purchased.
            </ParagraphMedium>
          </StyledBody>
          <StyledAction>
            <Block display='flex' alignItems='center' justifyContent='flex-end'>
              <Button onClick={() => setIsOpen(true)} shape="pill" overrides={{ BaseButton: { style: { fontWeight: 'bold', color: 'black', backgroundColor: 'white', minWidth: '30%', padding: '1ch 2ch !important', ':hover': { backgroundColor: theme.colors.secondary200 } } } }}>
                Upload now
              </Button>
            </Block>
          </StyledAction>
        </Card>
        <Drawer
          isOpen={isOpen}
          autoFocus
          onClose={() => {
            setIsOpen(false)
          }}
          anchor={ANCHOR.bottom}
          size={SIZE.default}
          showBackdrop={true}
          overrides={{
            DrawerContainer: {
              style: {
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                height: 'calc(100vh - 190px)'
              }
            },
            DrawerBody: {
              style: {
                margin: '0 !important',
                padding: '0',
                overflow: 'hidden'
              }
            },
            Close: {
              style: {
                color: theme.colors.primary300,
                width: '48px',
                height: '48px',
              }
            }
          }}
        >
          {loading && <ReDoSpinner />}
          <div ref={scrollable} className={`${column} ${css({ padding: '3ch 0', overflow: 'auto' })}`}>

            <Block padding='0 3ch' justifyContent='space-between' display='flex' flexDirection='column'>
              <Block alignSelf='flex-end' justifyContent='space-between' display='flex'>
                <ReDoProgressBar kind='text' label={stage.label} value={stage.curr} maxValue={stage.max} />
              </Block>

              <HeadingXLarge paddingTop='1ch' margin='0'>{stage.label}</HeadingXLarge>
            </Block>

            <Block height='100%'>
              <form className={css({ padding: '0 3ch', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%' })} onSubmit={onNext}>
                <div className={css({ width: '100%', maxWidth: '375px', paddingBottom: '10ch' })}>
                  {stage.curr === 1 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Give your solution a catchy and memorable title.'
                            id="title-input"
                            placeholder="Title"
                            name='title'
                            required
                            value={payload.title}
                            maxLength={20}
                            shadow
                          />
                        </FormControl>
                      </Block>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Summarize your solution in one sentence.'
                            placeholder='Summary'
                            maxLength={65}
                            name='summary'
                            required
                            value={payload.summary}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 2 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Which problem are you solving and why is it relevant?'
                            placeholder='Type here'
                            maxLength={500}
                            name='problem'
                            required
                            value={payload.problem}
                            shadow
                          />
                        </FormControl>
                      </Block>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='What product or service do you want to redo?'
                            placeholder='e.g. plastic packaging'
                            maxLength={40}
                            name='what'
                            required
                            value={payload.what}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 3 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Who is your solution adressed to? Who can
                generate the change you want to see? Name a
                company or industry.'
                            id="target-input"
                            name='target'
                            required
                            placeholder='Type here'
                            maxLength={40}
                            shadow
                          />
                        </FormControl>
                      </Block>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Describe your solution to the community. (This
                  will be visible to the community)'
                            placeholder='Type here'
                            maxLength={500}
                            name='description'
                            required
                            value={payload.description}
                            shadow
                          />
                        </FormControl>
                      </Block>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Do you have additional information or research
                about your solution that you want to save to your
                dashboard? (This will not be visible to the
                community)'
                            placeholder='Type here'
                            maxLength={500}
                            name='note'
                            value={payload.note}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 4 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='What value is created with your solution for
                consumers, companies and our planet?'
                            placeholder='Type here'
                            maxLength={500}
                            name='createdValue'
                            required
                            value={payload.createdValue}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 5 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <ParagraphSmall marginTop='0' paddingLeft='5px'>
                          The following step only applys, if your solution is
                          a physical product. Cause 80% of the ecological
                          impacts of a product are locked in at the design
                          phase*. So let‘s have a closer look at the life cycle
                          of your solution.
                        </ParagraphSmall>
                        <img src={diagram} alt=''></img>
                        <a className={css({ textUnderlineOffset: '.5ch', fontWeight: 'normal', fontSize: '10px', color: theme.colors.secondary200 })} href="./">What is a life cycle?</a>
                      </Block>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            id="lifeCycle"
                            placeholder="Type here"
                            name='lifeCycle'
                            value={payload.lifeCycle}
                            maxLength={500}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 6 &&
                    <Block width='100%' marginTop='5%'>
                      <FormControl>
                        <ReDoTags
                          onChange={(e) => setPayload({ ...payload, areas: e })}
                          label='Which areas is your solution positively 
                          affecting? Select up to 4 topics or 
                          write your own.'
                          options={areas}
                          limit={1}
                          additionLabel='Add your area'
                          values={payload.areas}
                          submitUpTo={4}
                          background={theme.colors.primary100}
                        />
                      </FormControl>
                    </Block>
                  }
                  {stage.curr === 7 &&
                    <Block width='100%' marginTop='5%'>
                      <FormControl>
                        <ReDoTextarea
                          label='What support do you need from the community to improve your idea?'
                          placeholder='Type here'
                          maxLength={500}
                          name='communityReason'
                          required
                          value={payload.communityReason}
                          shadow
                        />
                      </FormControl>
                    </Block>
                  }
                  {stage.curr === 8 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoFileUpload
                            multiple
                            onUpload={onUpload}
                            onRemove={onUpload}
                            maxCount={3}
                            label='Upload up to 3 images or drawings to promote
                and visualize your solution.'
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 9 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <ReDoInterests
                          label='Please select which stage your solution is
              currently in.'
                          options={solutionStage.map((type) => ({ ...type, color: theme.colors.primary100 }))}
                          values={payload?.stage}
                          name='stage'
                          radio
                          invert
                        />
                      </Block>
                    </>
                  }
                  <Block justifyContent='space-between' display='flex' width='100%' marginTop='5%'>
                    {stage.curr !== 1 &&
                      <ReDoButton
                        type='button'
                        onClick={onPrev}
                        kind='inverse'
                        overrides={{
                          BaseButton: {
                            style: {
                              marginTop: '0',
                              marginBottom: '0',
                              paddingLeft: '0 !important',
                              paddingTop: '0 !important',
                              paddingBottom: '0 !important',
                              backgroundColor: 'transparent',
                              color: theme.colors.black,
                              width: 'auto',
                              fontWeight: 'bold'
                            }
                          },
                          StartEnhancer: {
                            style: {
                              marginRight: '0'
                            }
                          }
                        }}
                        startEnhancer={() => <ChevronLeft size={theme.sizing.scale1000} />}
                      >
                        Back
                      </ReDoButton>
                    }
                    <ReDoButton
                      type='submit'
                      kind='brand'
                      overrides={{
                        BaseButton: {
                          style: {
                            marginBottom: '0',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '0',
                            width: 'auto',
                            marginLeft: 'auto'
                          }
                        },
                        EndEnhancer: {
                          style: {
                            marginLeft: '0'
                          }
                        }
                      }}
                      endEnhancer={() => <ChevronRight size={theme.sizing.scale1000} />}
                    >
                      {stage.curr !== 9 ? 'Next' : 'Submit'}
                    </ReDoButton>
                  </Block>
                </div>
              </form>
            </Block>

          </div >
        </Drawer >
      </>
    )
  }
});

export default ReDoCreateSolution;