import { IonContent, IonPage } from "@ionic/react";
import { useStyletron } from "baseui";
import { forwardRef } from "react";
import ReDoChat from "../../components/ReDoChat";


const Chat = forwardRef((props, ref) => {
  const [css] = useStyletron();

  return (
    <IonPage ref={ref} >
      <IonContent className={`ion-padding ${css({ '--background': 'white' })}`}>

        <ReDoChat item={props.item} />

      </IonContent>
    </IonPage >
  );
});

export default Chat;