import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { Block } from 'baseui/block';
import { Button, SHAPE } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { Card } from 'baseui/card';
import { HeadingLarge, LabelSmall, ParagraphMedium } from 'baseui/typography';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadUserSolutions } from '../application/actions/solutions';
import { routeTo } from '../application/actions/ui';
import { getUserSolutions } from '../application/selectors/solutions';
import { getUserProfile } from '../application/selectors/users';


const ReDoProfile = ({ profile }) => {
  const dispatch = useDispatch();
  const [, theme] = useStyletron();
  const [selectedTab, setSelectedTab] = useState('info');
  const ownProfile = useSelector(getUserProfile);

  const userProfile = profile || ownProfile;
  const userSolutions = useSelector(getUserSolutions);

  const onInfoClicked = () => {
    setSelectedTab('info');
  }

  const onSolutionsClicked = () => {
    dispatch(loadUserSolutions(userProfile.userId));
    setSelectedTab('solutions');
  }

  return (
    <Block display='flex' flexDirection='column'>
      <Block alignSelf='center'>
        <Avatar
          name={userProfile.fullname}
          size="96px"
          src={userProfile.photo}
        />
      </Block>
      <Block alignSelf='center' display='flex' flexDirection='column' alignItems='center'>
        <HeadingLarge marginBottom='0' overrides={{ Block: { style: { fontWeight: 'bold' } } }}>{userProfile.fullname}</HeadingLarge>
        <ParagraphMedium>{userProfile.city}, {userProfile.country}</ParagraphMedium>
      </Block>
      <Block alignSelf='center' marginTop='16px'>
        <ButtonGroup overrides={{ Root: { style: { width: 'fit-content', height: '42px', justifyContent: 'center', margin: '0', gap: '1ch' } } }} shape={SHAPE.pill}>
          <Button onClick={onInfoClicked} overrides={{ BaseButton: { style: { fontWeight: selectedTab === 'info' ? 'bold' : '500', boxShadow: 'none', width: '105px', backgroundColor: selectedTab === 'info' ? 'black' : 'transparent', border: selectedTab !== 'info' ? '1px solid black' : 'none' } } }} isSelected={selectedTab === 'info'}>Info</Button>
          <Button onClick={onSolutionsClicked} overrides={{ BaseButton: { style: { fontWeight: selectedTab === 'solutions' ? 'bold' : '500', boxShadow: 'none', width: '105px', backgroundColor: selectedTab === 'solutions' ? 'black' : 'transparent', border: selectedTab !== 'solutions' ? '1px solid black' : 'none' } } }} isSelected={selectedTab === 'solutions'}>Solutions</Button>
        </ButtonGroup>
      </Block>
      {selectedTab === 'info' &&
        <>
          <Block marginTop='48px'>
            <LabelSmall color={theme.colors.primary200}>ABOUT ME</LabelSmall>
            <ParagraphMedium marginBottom='0' marginTop='8px'>{userProfile.about}</ParagraphMedium>
          </Block>
          <Block marginTop='48px'>
            <LabelSmall color={theme.colors.primary200}>SKILLS</LabelSmall>
            <ParagraphMedium marginBottom='0' marginTop='8px'>{userProfile.skills?.join(', ')}</ParagraphMedium>
          </Block>
        </>
      }
      {selectedTab === 'solutions' &&
        <>
          <Block display='grid' gridTemplateColumns='1fr 1fr' flexWrap='wrap' marginTop='48px' gridGap='1ch' padding='8px'>
            {userSolutions.map((solution) => (
              <div key={solution.id} onClick={() => dispatch(routeTo({ view: 'solution', payload: { solution } }))}>
                <Card
                  overrides={{
                    Root: { style: { cursor: 'pointer', border: '0', height: '150px', display: 'flex', flexDirection: 'column', flex: '1', boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)` } },
                    Title: { style: { fontSize: '16px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important' } },
                    Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0' } },
                    HeaderImage: { style: { height: '75%', objectFit: 'cover' } },
                    Body: { style: { display: 'none' } }
                  }}
                  headerImage={solution.photos?.[0]?.photo}
                  title={solution.title}
                />
              </div>))
            }
          </Block>

        </>
      }
    </Block>
  )
}

export default ReDoProfile;