import { BUY_LICENSE_SUCCESS } from "../actions/licenses";



const initialState = {
  purchasedLicenses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BUY_LICENSE_SUCCESS:
      return { ...state, purchasedLicenses: action.payload };
    default:
      return state;
  }
}