import { FEDERATED_SIGN_IN, FORGOT_PASSWORD, LOAD_USER_ROLE, RESET_PASSWORD, SAVE_USER_ROLE, SIGN_IN, SIGN_OUT, SIGN_UP } from "../actions/auth";



export const auth = {

  [SIGN_IN]: async ({ api, dispatch, getState, action }) => {
    const { username, password } = action.payload;
    const user = await api.auth.signIn({ username, password });

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const res = await api.auth.completeNewPassword(user, password)
      return res;
    }

    return user;
  },

  [SIGN_OUT]: async ({ api, preferences, dispatch, getState, action }) => {
    await preferences.set({
      key: 'role',
      value: '',
    })
    await api.auth.signOut();
    window.location.reload(false);
  },

  [SIGN_UP]: async ({ api, dispatch, getState, action }) => {
    const pl = action.payload;
    const user = await api.auth.signUp({ ...pl, attributes: { email: pl.username } })
    return user;
  },

  [FORGOT_PASSWORD]: async ({ api, dispatch, getState, action }) => {
    const { username } = action.payload;
    const user = await api.auth.forgotPassword({ username });
    return user;
  },

  [RESET_PASSWORD]: async ({ api, dispatch, getState, action }) => {
    const { code, password, username } = action.payload;
    const user = await api.auth.resetPassword({ username, code, password });
    return user;
  },

  [FEDERATED_SIGN_IN]: async ({ api, dispatch, getState, action }) => {
    const { config, provider } = action.payload;
    await api.auth.federatedSignIn({ config, provider });
  },

  [SAVE_USER_ROLE]: async ({ api, preferences, dispatch, getState, action }) => {
    await preferences.set({
      key: 'role',
      value: action.payload,
    })
  },

  [LOAD_USER_ROLE]: async ({ api, preferences, dispatch, getState, action }) => {
    const { value } = await preferences.get({ key: 'role' });

    return value
  },

}