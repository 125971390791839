import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Modal } from "baseui/modal";
import ReDoProfile from "../components/ReDoProfile";
import { ANCHOR, Drawer, SIZE } from "baseui/drawer";
import { useState } from 'react';
import useBreakpoint from '../hooks/useBreakpoint';
import { setPortalElement } from '../application/actions/ui';
import { useDispatch } from 'react-redux'


const ReDoProfileContainer = ({ target, className, user, ...props }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(true);
  const [, theme] = useStyletron();
  const breakpoint = useBreakpoint()

  const onClose = () => {
    setIsOpen(false)
    dispatch(setPortalElement())
  }

  

  return (
    <>
      {breakpoint === 'large' &&
        <Modal
          overrides={{
            Close: {
              style: {
                color: theme.colors.primary300,
                width: '36px',
                height: '36px',
                transform: 'scale(2)',
              }
            }
          }}
          onClose={onClose}
          isOpen={isOpen}>
          <Block overflow='auto' maxHeight='70vh' marginTop='7ch' padding='0 3ch 3ch 3ch' display='flex' flexDirection='column'>
            <ReDoProfile profile={user} />
          </Block>

        </Modal>
      }
      {
        breakpoint !== 'large' &&
        <Drawer
          isOpen={isOpen}
          autoFocus
          onClose={onClose}
          anchor={ANCHOR.bottom}
          size={SIZE.default}
          showBackdrop={true}
          overrides={{
            DrawerContainer: {
              style: {
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                height: 'calc(100vh - 190px)'
              }
            },
            DrawerBody: {
              style: {
                margin: '0 !important',
                padding: '0',
                overflow: 'hidden'
              }
            },
            Close: {
              style: {
                color: theme.colors.primary300,
                width: '48px',
                height: '48px',
              }
            }
          }}
        >
          <Block height='100%' overflow='auto' padding='0 3ch 10ch 3ch' marginTop='7ch' display='flex' flexDirection='column'>
            <ReDoProfile profile={user} />
          </Block>
        </Drawer>
      }
    </>
  );
};

export default ReDoProfileContainer;