import { ROUTE_BACK, ROUTE_TO, cacheView, setView } from "../actions/ui";

export const ui = {

  [ROUTE_TO]: async ({ api, dispatch, getState, action }) => {
    dispatch(cacheView(getState().ui.currentView))
    window.history.pushState({ view: action.payload.view, payload: action.payload.payload, tab: action.payload.tab }, null);
    dispatch(setView(action.payload));
  },

  [ROUTE_BACK]: async ({ api, dispatch, getState, action }) => {
    window.history.back();
    dispatch(setView(getState().ui.lastView));
  },

}