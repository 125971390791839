import { IonActionSheet } from "@ionic/react";
import { useStyletron } from "baseui";
import { Avatar } from "baseui/avatar";
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Card, StyledAction, StyledBody } from 'baseui/card';
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Check, ChevronRight } from "baseui/icon";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { HeadingLarge, HeadingXLarge, LabelMedium, LabelSmall, LabelXSmall, ParagraphMedium } from "baseui/typography";
import { useEffect, useState } from 'react';
import { FaShare } from "react-icons/fa";
import { HiUpload } from "react-icons/hi";
import { IoIosWallet } from "react-icons/io";
import { MdBookmarkBorder, MdOutlineHowToVote } from 'react-icons/md';
import { SlOptionsVertical } from 'react-icons/sl';
import { useDispatch, useSelector } from "react-redux";
import { deleteChallenge, loadCompanyChallenges, loadFavoriteChallenges } from "../application/actions/challenges";
import { buyLicenseForChallenge } from "../application/actions/licenses";
import { deleteSolution, loadBoughtSolutions, loadFavoriteSolutions, loadSolutionsForChallenge, loadUserSolutions } from "../application/actions/solutions";
import { routeTo, setActiveTab, setPortalElement } from "../application/actions/ui";
import { getCompanyChallenges } from "../application/selectors/challenges";
import { getUserSolutions } from "../application/selectors/solutions";
import { getWallet } from "../application/selectors/wallet";
import useBreakpoint from "../hooks/useBreakpoint";
import useWhoAmI from "../hooks/useWhoAmI";
import ReDoBackButton from "./ReDoBackButton";
import ReDoBanner from "./ReDoBanner";
import ReDoButton from "./ReDoButton";
import ReDoProjectCard from "./ReDoProjectCard";
import ReDoSettings from './ReDoSettings';
import ReDoTabs from './ReDoTabs';


const ReDoDashboard = () => {
  const dispatch = useDispatch();
  const userSolutions = useSelector(getUserSolutions);
  const companyChallenges = useSelector(getCompanyChallenges);
  const wallet = useSelector(getWallet)

  const { isCompany, userProfile } = useWhoAmI();
  const { enqueue } = useSnackbar();

  const [favoriteSolutions, setFavoriteSolutions] = useState([])
  const [favoriteChallenges, setFavoriteChallenges] = useState([])
  const [clickedChallenge, setClickedChallenge] = useState('')
  const [solutionsForChallenge, setSolutionsForChallenge] = useState([])

  const [activeKey, setActiveKey] = useState('0');
  const [actionSheetOpen, setActionSheetOpen] = useState(false)
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [savedSolutionsVisible, setSavedSolutionsVisible] = useState(false)
  const [boughtSolutionsVisible, setBoughtSolutionsVisible] = useState(false)
  const [header, setHeader] = useState('Saved Projects')

  const breakpoint = useBreakpoint()
  const [css, theme] = useStyletron();

  const onUserSolutionOptionClick = (e, solution) => {
    e.stopPropagation();
    setActionSheetOpen(solution)
  }

  const onMyChallengeClick = (challenge) => {
    setBoughtSolutionsVisible(false)
    setSavedSolutionsVisible(false)
    setClickedChallenge(challenge)
    dispatch(loadSolutionsForChallenge(challenge.id)).then(r => setSolutionsForChallenge(r))
    setHeader('Submitted Solutions')
  }

  const rightSideProjects = isCompany ? solutionsForChallenge : [...favoriteChallenges, ...favoriteSolutions]

  const onUpgrade = () => {
    dispatch(setPortalElement({ element: 'redoPricing', payload: { subscriptions: true } }))
  }

  const onExtraCoins = () => {
    dispatch(setPortalElement({ element: 'redoPricing', payload: { products: true } }))
  }

  const onFavorite = () => {
    if (savedSolutionsVisible) {
      dispatch(loadFavoriteSolutions).then((res) => {
        setFavoriteSolutions(res)
        setSolutionsForChallenge(res)
      })
    }
    if (boughtSolutionsVisible) {
      dispatch(loadBoughtSolutions).then(r => {
        setSolutionsForChallenge(r)
      })
    }
  }


  const showSavedSolutions = () => {
    setBoughtSolutionsVisible(false)
    setSavedSolutionsVisible(true)
    setClickedChallenge()
    setSolutionsForChallenge(favoriteSolutions)
    setHeader('Saved Solutions')
  }

  const showBoughtSolutions = () => {
    setBoughtSolutionsVisible(true)
    setSavedSolutionsVisible(false)
    setClickedChallenge()
    dispatch(loadBoughtSolutions).then(r => setSolutionsForChallenge(r))
    setHeader('Bought Solutions')
  }

  const onCardClick = (solution) => {
    if (solution.purchased) {
      !solution.companyId ? dispatch(routeTo({ view: 'solution', payload: { solution } })) : dispatch(routeTo({ view: 'challenge', payload: { challenge: solution } }))
    } else {
      if (!clickedChallenge.id) {
        dispatch(buyLicenseForChallenge({ solutionId: solution.id }))
          .then(() => {
            enqueue({ message: 'Purchase successful', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> });
            dispatch(loadFavoriteSolutions).then((res) => {
              setFavoriteSolutions(res)
              setSolutionsForChallenge(res)
            })
          }
          )
      } else {
        dispatch(buyLicenseForChallenge({ solutionId: solution.id, challengeId: clickedChallenge.id }))
          .then(() => {
            enqueue({ message: 'Purchase successful', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> });
            dispatch(loadSolutionsForChallenge(clickedChallenge.id)).then(r =>
              setSolutionsForChallenge(r))
          })
      }
    }
  }

  const getActionSheetButtons = () => {
    if (actionSheetOpen.companyId) {
      return [
        {
          text: 'View',
          data: {
            action: 'view',
          },
          handler: () => dispatch(routeTo({ view: 'challenge', payload: { challenge: actionSheetOpen } }))
        },
        {
          text: 'Edit',
          data: {
            action: 'edit',
          },
          handler: () => !isCompany ? dispatch(routeTo({ view: 'createSolution', payload: { solution: actionSheetOpen } })) : dispatch(routeTo({ view: 'createChallenge', payload: { challenge: actionSheetOpen } }))
        },
        {
          text: 'Private Information',
          data: {
            action: 'privateInformation',
          },
          handler: () => dispatch(setPortalElement({ element: 'hiddenFieldContainer', payload: { note: actionSheetOpen?.note || actionSheetOpen?.why } }))
        },
        {
          text: 'Delete Solution',
          role: 'destructive',
          data: {
            action: 'delete',
          },
          handler: () => dispatch(deleteChallenge(actionSheetOpen.id))
        },
      ]
    } else {
      return [
        {
          text: 'Edit',
          data: {
            action: 'edit',
          },
          handler: () => !isCompany ? dispatch(routeTo({ view: 'createSolution', payload: { solution: actionSheetOpen } })) : dispatch(routeTo({ view: 'createChallenge', payload: { challenge: actionSheetOpen } }))
        },
        {
          text: 'Private Information',
          data: {
            action: 'privateInformation',
          },
          handler: () => dispatch(setPortalElement({ element: 'hiddenFieldContainer', payload: { note: actionSheetOpen?.note || actionSheetOpen?.why } }))
        },
        {
          text: 'Delete Solution',
          role: 'destructive',
          data: {
            action: 'delete',
          },
          handler: () => dispatch(deleteSolution(actionSheetOpen.id))
        },
      ]
    }
  }

  useEffect(() => {
    !isCompany && dispatch(loadUserSolutions(userProfile.userId));
    isCompany && dispatch(loadCompanyChallenges())
    dispatch(loadFavoriteSolutions).then((res) => {
      setFavoriteSolutions(res)
    })
    dispatch(loadFavoriteChallenges).then((res) => {
      setFavoriteChallenges(res)
    })
  }, [dispatch, userProfile.userId, isCompany])

  return (
    <>
      <IonActionSheet
        isOpen={!!actionSheetOpen}
        buttons={getActionSheetButtons()}
        onDidDismiss={() => setActionSheetOpen(false)}
      />

      {breakpoint === 'large' &&
        <FlexGrid
          flexGridColumnCount={2}
          flexGridRowGap="scale800"
          height='calc(100% - 54px)'
          alignItems='flex-start'
        >
          <FlexGridItem position='relative' height='100%'>
            <ReDoBanner overrides={{ overflow: 'auto' }} align padding={`${theme.sizing.scale850} ${theme.sizing.scale1400}`}>
              <Block paddingTop='4ch' minHeight='100%' display='flex' flexDirection='column'>

                {settingsVisible ?
                  <>
                    <ReDoBackButton onClick={() => setSettingsVisible(!settingsVisible)} />
                    <HeadingXLarge>Settings</HeadingXLarge>
                  </>
                  :
                  <>
                    <Block justifyContent='space-between' alignItems='center' display='flex' marginTop='1ch' gridGap='1ch'>
                      <Block alignItems='center' display='flex' marginTop='1ch' gridGap='2ch'>
                        <Avatar name={userProfile.fullname} size="84px" src={userProfile.photo} />
                        <Block display='flex' flexDirection='column'>
                          <ParagraphMedium marginTop='0' marginBottom='0' overrides={{ Block: { style: { fontWeight: 'bold' } } }}>
                            {userProfile.fullname}
                          </ParagraphMedium>
                          <Block alignItems='center' display='flex'>
                            <Block margin='0.5ch 0 0 -2px'>
                              <ReDoButton overrides={{ BaseButton: { style: { boxShadow: 'none' } } }} kind='link' onClick={() => setSettingsVisible('wallet')}>
                                <ParagraphMedium color={theme.colors.primary400} marginTop='0' marginBottom='0'>
                                  You have {wallet.balance} Coins
                                </ParagraphMedium>
                              </ReDoButton>
                              {isCompany && '|'}
                            </Block>
                            {isCompany &&
                              <Block paddingBottom='4px'>
                                <ReDoButton onClick={onExtraCoins} kind='link'><LabelXSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }} color='black'>Get extra coins</LabelXSmall></ReDoButton>
                              </Block>
                            }
                          </Block>
                        </Block>
                      </Block>
                      <Block>
                        <ReDoButton onClick={() => setSettingsVisible(!settingsVisible)} overrides={{ BaseButton: { style: { paddingTop: '.5ch', paddingBottom: '.5ch', fontWeight: '400' } } }} kind='outline'>Settings</ReDoButton>
                      </Block>
                    </Block>

                    <HeadingLarge padding='4ch 12px 4px 12px'>{!isCompany ? 'My Solutions' : 'My Challenges'}</HeadingLarge>
                    <Block display='grid' gridTemplateColumns='1fr 1fr' flexWrap='wrap' gridGap='calc(1ch + 8px)' padding='8px'>
                      {[...userSolutions, ...companyChallenges].map((solution) => (
                        <div key={solution.id} onClick={() => !isCompany ? dispatch(routeTo({ view: 'solution', payload: { solution } })) : onMyChallengeClick(solution)}>
                          <Card
                            overrides={{
                              Root: { style: { borderRadius: '8px', padding: '1ch', cursor: 'pointer', border: '0 !important', height: '100px', display: 'flex', flexDirection: 'row', flex: '1', boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)` } },
                              Title: { style: { width: '100%', fontSize: '12px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important', height: '100%' } },
                              Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0', width: '100%' } },
                              HeaderImage: {
                                component: () =>
                                  <Block overrides={{ Block: { style: { flexShrink: '0' } } }} width='30%' position='relative'>
                                    <img className={css({ objectFit: 'cover', width: '100%', height: '100%', opacity: solution.status === 'matched' ? 0.5 : 1 })} src={solution.photos?.[0]?.photo} alt="" />
                                    {solution.status === 'matched' && <LabelSmall color='white' overrides={{ Block: { style: { zIndex: 2, top: '50%', transform: 'translateY(-50%)', width: '100%', textAlign: 'center' } } }} position='absolute' margin='0'>MATCHED</LabelSmall>}
                                  </Block>
                              },
                              Body: { style: { display: 'none' } }
                            }}
                            headerImage={solution.photos?.[0]?.photo}
                            title={
                              <Block display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' height='100%' width='100%'>
                                <Block width='100%' display='flex' position='relative'>
                                  <Block>{solution.title}</Block>
                                  <Block marginLeft='auto' marginRight='0'>
                                    <Button onClick={(e) => onUserSolutionOptionClick(e, solution)} overrides={{ BaseButton: { style: { position: 'absolute', top: '-2ch', right: '-2ch', height: '4ch', width: '4ch', backgroundColor: 'white', padding: '0', ':hover': { backgroundColor: 'white' } } } }}>
                                      <SlOptionsVertical color="black" />
                                    </Button>
                                  </Block>
                                </Block>
                                <Block width='100%' display='flex' gridGap='2ch'>
                                  {solution.companyId && <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                    <HiUpload />
                                    {solution.submittedSolutionCount || 0}
                                  </Block>}
                                  {!solution.companyId &&
                                    <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                      <MdOutlineHowToVote />
                                      {solution.voteCount}
                                    </Block>
                                  }
                                  <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                    <MdBookmarkBorder />
                                    {solution.favoriteCount}
                                  </Block>

                                  {!solution.companyId && <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                    <IoIosWallet />
                                    {solution.licenseCount}
                                  </Block>}
                                  <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                    <FaShare />
                                    {solution.shareCount}
                                  </Block>
                                </Block>
                              </Block>
                            }
                          />
                        </div>))
                      }
                    </Block>

                    {isCompany &&
                      <>
                        <Block marginTop='3ch' marginBottom='1ch' padding='0 8px'>
                          <Card
                            overrides={{
                              Root: { style: { backgroundColor: savedSolutionsVisible ? theme.colors.primary100 : '', borderRadius: '8px', padding: '1ch', cursor: 'pointer', border: '0 !important', display: 'flex', flexDirection: 'row', flex: '1', boxShadow: !savedSolutionsVisible ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : '' } },
                              Title: { style: { width: '100%', fontSize: '12px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important', height: '100%' } },
                              Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0', width: '100%' } },
                              HeaderImage: { style: { objectFit: 'cover', width: '30%', flexShrink: '0' } },
                              Body: { style: { display: 'none' } }
                            }}
                            title={
                              <Block onClick={showSavedSolutions} display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' height='100%' width='100%'>
                                <Block justifyContent='space-between' width='100%' display='flex'>
                                  <HeadingLarge margin='0'>Saved Solutions</HeadingLarge>
                                  <ChevronRight size={40} />
                                </Block>
                              </Block>
                            }
                          />
                        </Block>
                      </>
                    }

                    {isCompany &&
                      <>
                        <Block marginTop='3ch' marginBottom='1ch' padding='0 8px'>
                          <Card
                            overrides={{
                              Root: { style: { backgroundColor: boughtSolutionsVisible ? theme.colors.primary100 : '', borderRadius: '8px', padding: '1ch', cursor: 'pointer', border: '0 !important', display: 'flex', flexDirection: 'row', flex: '1', boxShadow: !boughtSolutionsVisible ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : '' } },
                              Title: { style: { width: '100%', fontSize: '12px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important', height: '100%' } },
                              Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0', width: '100%' } },
                              HeaderImage: { style: { objectFit: 'cover', width: '30%', flexShrink: '0' } },
                              Body: { style: { display: 'none' } }
                            }}
                            title={
                              <Block onClick={showBoughtSolutions} display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' height='100%' width='100%'>
                                <Block justifyContent='space-between' width='100%' display='flex'>
                                  <HeadingLarge margin='0'>Bought Solutions</HeadingLarge>
                                  <ChevronRight size={40} />
                                </Block>
                              </Block>
                            }
                          />
                        </Block>
                      </>
                    }

                  </>
                }

                {!settingsVisible && isCompany &&
                  <Block marginTop='auto' maxWidth='200px' paddingBottom='3ch' paddingTop='3ch'>
                    {!wallet.subscription ?
                      <ReDoButton onClick={onUpgrade} overrides={{ BaseButton: { style: { height: '40px', backgroundColor: theme.colors.secondary0, color: 'white' } } }} fontSize='10px' kind='inverse'>Upgrade Now</ReDoButton>
                      :
                      <ReDoButton overrides={{ BaseButton: { style: { height: '40px', backgroundColor: theme.colors.secondary0, color: 'white' } } }} fontSize='10px' kind='inverse'>
                        <a className={css({ color: 'white', fontWeight: '500', textDecoration: 'none' })} target='_blank' rel='noreferrer' href="https://billing.stripe.com/p/login/test_5kA7thai2a0W1Bm9AA">Manage Wallet</a>
                      </ReDoButton>
                    }
                  </Block>
                }

              </Block>
            </ReDoBanner>
          </FlexGridItem>

          <FlexGridItem position='relative' height='100%'>
            <Block height='100%' display='flex' flexDirection='column' padding={`1ch ${theme.sizing.scale900} 0 ${theme.sizing.scale900}`}>
              <Block display='flex' flexDirection='column' height='calc(100% - 56px)'>
                {settingsVisible ?
                  <Block paddingTop='94px' paddingLeft='2ch' paddingRight='2ch'>
                    <ReDoSettings defaultItem={settingsVisible === 'wallet'} />
                  </Block> :
                  <Block display='flex' flexDirection='column' height='100%'>
                    <Block>
                      <HeadingLarge padding='4ch 12px 12px 12px'>{!!rightSideProjects.length && header}</HeadingLarge>
                    </Block>

                    <Block overflow={['unset', 'unset', 'unset', 'auto']} marginTop={['4ch', '4ch', '4ch', '50px']} paddingBottom='68px'>
                      <Block padding={['0', '0', '0', '0 5ch 0 0']} overrides={{ Block: { style: { columnGap: '8ch', columnWidth: '17em' } } }}>
                        {!!rightSideProjects.length && <Block marginBottom={['4ch', '4ch', '4ch', '10ch']}>
                          <Card
                            overrides={{ Root: { style: { border: 'none', borderRadius: '25px !important' } } }}
                          >
                            <StyledBody>
                              <div className={css({ fontSize: '14px' })}>
                                Your saved these projects, start
                                interacting with them now! Or continue
                                exploring to find new innovations
                              </div>
                            </StyledBody>
                            <StyledAction>
                              <Block display='flex' alignItems='center' justifyContent='flex-end'>
                                <Button onClick={() => dispatch(setActiveTab('0'))} shape="pill" overrides={{ BaseButton: { style: { border: '1px solid black', color: 'black', backgroundColor: 'transparent', minWidth: '30%', padding: '1ch 2ch !important', ':hover': { backgroundColor: theme.colors.secondary200 } } } }}>
                                  Explore
                                </Button>
                              </Block>
                            </StyledAction>
                          </Card>
                        </Block>}
                        {rightSideProjects?.map((solution) => (
                          <Block height='550px' key={solution.id} marginBottom={['4ch', '4ch', '4ch', '10ch']}>
                            <ReDoProjectCard onFavorite={onFavorite} onClick={() => onCardClick(solution)} key={solution.id} project={solution} />
                          </Block>
                        ))}
                      </Block>
                    </Block>
                  </Block>
                }
              </Block>
            </Block>
          </FlexGridItem >
        </FlexGrid >
      }

      {breakpoint !== 'large' &&
        <ReDoTabs
          activeKey={activeKey}
          onTabChange={(activeKey) => setActiveKey(activeKey)}
          tabs={[
            {
              title: !isCompany ? 'My Solutions' : 'My Challenges',
              content:
                <Block paddingBottom='calc(1ch + 68px)' paddingTop='1ch' height='100%' display='flex' flexDirection='column' gridGap='1ch'>
                  {[...userSolutions, ...companyChallenges].map((solution) => (
                    <div key={solution.id} onClick={() => !isCompany ? dispatch(routeTo({ view: 'solution', payload: { solution } })) : onMyChallengeClick(solution)}>
                      <Card
                        overrides={{
                          Root: { style: { borderRadius: '8px', padding: '1ch', cursor: 'pointer', border: '0 !important', height: '100px', display: 'flex', flexDirection: 'row', flex: '1', boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)` } },
                          Title: { style: { width: '100%', fontSize: '12px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important', height: '100%' } },
                          Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0', width: '100%' } },
                          HeaderImage: {
                            component: () =>
                              <Block overrides={{ Block: { style: { flexShrink: '0' } } }} width='30%' position='relative'>
                                <img className={css({ objectFit: 'cover', width: '100%', height: '100%', opacity: solution.status === 'matched' ? 0.5 : 1 })} src={solution.photos?.[0]?.photo} alt="" />
                                {solution.status === 'matched' && <LabelSmall color='white' overrides={{ Block: { style: { zIndex: 2, top: '50%', transform: 'translateY(-50%)', width: '100%', textAlign: 'center' } } }} position='absolute' margin='0'>MATCHED</LabelSmall>}
                              </Block>
                          },
                          Body: { style: { display: 'none' } }
                        }}
                        headerImage={solution.photos?.[0]?.photo}
                        title={
                          <Block display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' height='100%' width='100%'>
                            <Block width='100%' display='flex' position='relative'>
                              <Block>{solution.title}</Block>
                              <Block marginLeft='auto' marginRight='0'>
                                <Button onClick={(e) => onUserSolutionOptionClick(e, solution)} overrides={{ BaseButton: { style: { position: 'absolute', top: '-2ch', right: '-2ch', height: '4ch', width: '4ch', backgroundColor: 'white', padding: '0', ':hover': { backgroundColor: 'white' } } } }}>
                                  <SlOptionsVertical color="black" />
                                </Button>
                              </Block>
                            </Block>
                            <Block width='100%' display='flex' gridGap='2ch'>
                              {solution.companyId && <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                <HiUpload />
                                {solution.submittedSolutionCount}
                              </Block>}
                              <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                <MdOutlineHowToVote />
                                {solution.voteCount}
                              </Block>
                              <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                <MdBookmarkBorder />
                                {solution.favoriteCount}
                              </Block>

                              {!solution.companyId && <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                <IoIosWallet />
                                {solution.licenseCount}
                              </Block>}
                              <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                <FaShare />
                                {solution.shareCount}
                              </Block>
                            </Block>
                          </Block>
                        }
                      />

                      {!!solutionsForChallenge.length &&
                        <>
                          <LabelMedium marginTop='1ch' marginBottom='1ch'>Submitted solutions</LabelMedium>
                          <Block display='grid' gridTemplateColumns='1fr 1fr' flexWrap='wrap' gridGap='calc(1ch + 8px)'>

                            {solutionsForChallenge.map((solution) => (
                              <div key={solution.id} onClick={() => dispatch(routeTo({ view: 'solution', payload: { solution } }))}>
                                <Card
                                  overrides={{
                                    Root: { style: { cursor: 'pointer', border: '0', height: '150px', display: 'flex', flexDirection: 'column', flex: '1', boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)` } },
                                    Title: { style: { fontSize: '16px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important' } },
                                    Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0' } },
                                    HeaderImage: { style: { height: '75%', objectFit: 'cover' } },
                                    Body: { style: { display: 'none' } }
                                  }}
                                  headerImage={solution.photos?.[0]?.photo}
                                  title={solution.title}
                                />
                              </div>))
                            }
                          </Block>
                        </>

                      }
                    </div>))
                  }
                </Block>
            },
            {
              title: 'Saved',
              content:
                <Block paddingBottom='calc(1ch + 68px)'>
                  <LabelMedium marginTop='1ch'>Solutions</LabelMedium>
                  <Block display='grid' gridTemplateColumns='1fr 1fr' flexWrap='wrap' gridGap='calc(1ch + 8px)' paddingTop='1ch'>
                    {favoriteSolutions?.map((solution) => (
                      <div key={solution.id} onClick={() => dispatch(routeTo({ view: 'solution', payload: { solution } }))}>
                        <Card
                          overrides={{
                            Root: { style: { cursor: 'pointer', border: '0', height: '150px', display: 'flex', flexDirection: 'column', flex: '1', boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)` } },
                            Title: { style: { fontSize: '16px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important' } },
                            Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0' } },
                            HeaderImage: { style: { height: '75%', objectFit: 'cover' } },
                            Body: { style: { display: 'none' } }
                          }}
                          headerImage={solution.photos?.[0]?.photo}
                          title={solution.title}
                        />
                      </div>))
                    }

                  </Block>
                  {!isCompany && <>
                    <br />
                    <LabelMedium>Challenges</LabelMedium>
                    <Block display='grid' gridTemplateColumns='1fr 1fr' flexWrap='wrap' gridGap='calc(1ch + 8px)' paddingTop='1ch'>
                      {favoriteChallenges?.map((solution) => (
                        <div key={solution.id} onClick={() => dispatch(routeTo({ view: 'solution', payload: { solution } }))}>
                          <Card
                            overrides={{
                              Root: { style: { cursor: 'pointer', border: '0', height: '150px', display: 'flex', flexDirection: 'column', flex: '1', boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)` } },
                              Title: { style: { fontSize: '16px', marginTop: '0', marginBottom: '0', lineHeight: '1', padding: '8px !important' } },
                              Contents: { style: { marginTop: '0', marginBottom: '0', marginLeft: '0', marginRight: '0' } },
                              HeaderImage: { style: { height: '75%', objectFit: 'cover' } },
                              Body: { style: { display: 'none' } }
                            }}
                            headerImage={solution.photos?.[0]?.photo}
                            title={solution.title}
                          />
                        </div>))
                      }
                    </Block>
                  </>}
                </Block>
            },
            {
              title: 'Settings', content:
                <Block paddingBottom='calc(1ch + 68px)'>
                  <ReDoSettings defaultItem={settingsVisible === 'wallet'} />
                </Block>
            },
          ]}
        />
      }
    </>

  );
};

export default ReDoDashboard;