import { useStyletron } from 'baseui';
import useBreakpoint from '../hooks/useBreakpoint';
import { Modal, ModalBody } from 'baseui/modal';
import { Block } from 'baseui/block';
import { useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import { loadChallengeById } from '../application/actions/challenges';
import ReDoProjectCard from './ReDoProjectCard';
import { HeadingSmall, ParagraphMedium } from 'baseui/typography';
import { TbArrowsUpDown } from 'react-icons/tb';
import { ANCHOR, Drawer, SIZE } from 'baseui/drawer';
import { routeTo } from '../application/actions/ui';


const ReDoMatched = ({ solution, challengeId, onClose }) => {
  const dispatch = useDispatch()
  const [css, theme] = useStyletron();
  const breakpoint = useBreakpoint()

  const [challenge, setChallenge] = useState()

  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    marginTop: '6ch',
    gap: '3ch'
  });

  const navigateToChallenge = (challenge) => {
    dispatch(routeTo({ view: 'challenge', payload: { challenge } }))
  }

  const navigateToSolution = (solution) => {
    dispatch(routeTo({ view: 'solution', payload: { solution } }))
  }

  useEffect(() => {
    dispatch(loadChallengeById(challengeId)).then((challenge) => {
      setChallenge(challenge)
    })
  }, [dispatch, challengeId])

  return (
    <>
      {breakpoint === 'large' ?
        <Modal
          overrides={{
            Dialog: {
              style: {
                width: '90vw',
                maxWidth: '900px',
                borderRadius: '21px'
              }
            },
            Close: {
              style: {
                color: theme.colors.primary300,
                width: '48px',
                height: '48px',
                transform: 'scale(2)',
              }
            }
          }}
          onClose={() => onClose && onClose()}
          isOpen={true}>
          <ModalBody>
            <Block marginTop='4ch' padding={breakpoint === 'large' ? '2ch' : 0} display='flex' flexDirection='column'>

              <Block gridGap='1ch' display='flex' alignItems='center'>
                <Block display='flex' alignItems='center' justifyContent='center' width='34px' height='34px' overrides={{ Block: { style: { borderRadius: '100%', fontSize: '24px', zIndex: '2' } } }} backgroundColor='black'>
                  <TbArrowsUpDown color="white" />
                </Block>
                <HeadingSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Connected!</HeadingSmall>
              </Block>
              <ParagraphMedium marginTop='0'>This solution has been submitted
                to a challenge. Select one </ParagraphMedium>

              <Block marginTop='2ch' display='flex' justifyContent='space-between'>
                <Block display='flex' justifyContent='center' width='50%' overrides={{ Block: { style: { flexShrink: '0' } } }}>
                  <ReDoProjectCard shadow project={solution} />
                </Block>
                {challenge &&
                  <Block display='flex' justifyContent='center' width='50%' overrides={{ Block: { style: { flexShrink: '0' } } }}>
                    <ReDoProjectCard shadow project={challenge} />
                  </Block>
                }
              </Block>

            </Block>
          </ModalBody>
        </Modal> :
        <Drawer
          isOpen={true}
          autoFocus
          onClose={() => onClose && onClose()}
          anchor={ANCHOR.bottom}
          size={SIZE.default}
          showBackdrop={true}
          overrides={{
            DrawerContainer: {
              style: {
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                height: '440px'
              }
            },
            DrawerBody: {
              style: {
                margin: '0 !important',
                padding: '0',
                overflow: 'hidden'
              }
            },
            Close: {
              style: {
                color: theme.colors.primary300,
                width: '48px',
                height: '48px',
                background: 'white'
              }
            }
          }}
        >
          <div className={`${column} ${css({ padding: '0 3ch 3ch 3ch', overflow: 'auto' })}`}>

            <Block height='100%'>

              <Block gridGap='1ch' display='flex' alignItems='center'>
                <Block display='flex' alignItems='center' justifyContent='center' width='34px' height='34px' overrides={{ Block: { style: { borderRadius: '100%', fontSize: '24px', zIndex: '2' } } }} backgroundColor='black'>
                  <TbArrowsUpDown color="white" />
                </Block>
                <HeadingSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Connected!</HeadingSmall>
              </Block>
              <ParagraphMedium marginTop='0'>This solution has been submitted
                to a challenge. Select one </ParagraphMedium>

              <Block marginTop='2ch' display='flex' justifyContent='space-between'>

                <Block onClick={() => navigateToSolution(solution)} top='100px' left='-24%' position='absolute' display='flex' justifyContent='center' width='100%' overrides={{ Block: { style: { flexShrink: '0', transform: 'scale(0.45)' } } }}>
                  <ReDoProjectCard shadow project={solution} />
                </Block>

                {challenge &&
                  <Block onClick={() => navigateToChallenge(challenge)} top='100px' right='-24%' position='absolute' display='flex' justifyContent='center' width='100%' overrides={{ Block: { style: { flexShrink: '0', transform: 'scale(0.45)' } } }}>
                    <ReDoProjectCard shadow project={challenge} />
                  </Block>
                }
              </Block>


            </Block>

          </div >
        </Drawer >
      }
    </>
  );
};

export default ReDoMatched;