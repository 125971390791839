import { useLayoutEffect, useState } from 'react';


const breakpoints = {
  0: 'xsmall',
  320: 'small',
  600: 'medium',
  1136: 'large'
};

const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState('');
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    if (0 < windowSize.width && windowSize.width < 320) {
      setBreakPoint(breakpoints[0]);
    }
    if (320 < windowSize.width && windowSize.width < 600) {
      setBreakPoint(breakpoints[320]);
    }
    if (600 < windowSize.width && windowSize.width < 1136) {
      setBreakPoint(breakpoints[600]);
    }
    if (windowSize.width >= 1136) {
      setBreakPoint(breakpoints[1136]);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize.width]);


  return breakpoint;
};

export default useBreakpoint;