import { LOAD_PROFILE_SUCCESS } from "../actions/users";

const initialState = {
  profile: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE_SUCCESS:
      return { ...state, profile: action.payload };
    default:
      return state;
  }
}