import { Tab, Tabs } from 'baseui/tabs-motion';


const ReDoTabs = ({ onTabChange, tabs, activeKey }) => {

  const handleTabChange = ({ activeKey }) => {
    onTabChange && onTabChange(activeKey)
  }

  return (
    <>
      <div className="explore">
        <Tabs
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                backgroundColor: 'transparent'
              })
            },
            TabBorder: {
              style: ({ $theme }) => ({
                backgroundColor: 'transparent'
              })
            },
            TabHighlight: {
              style: () => ({
                height: '2px',
              })
            },
            TabList: {
              style: () => ({
                gap: '2ch'
              })
            }
          }}
          activeKey={activeKey}
          onChange={handleTabChange}
          activateOnFocus
        >

          {tabs.map((tab, index) => (

            <Tab
              key={index}
              overrides={{
                Tab: {
                  style: () => ({
                    background: 'transparent',
                    fontSize: '18px',
                    fontWeight: activeKey === `${index}` ? 'bold' : 'regular',
                    padding: '2px',
                    ':hover': {
                      background: 'transparent',
                    }
                  })
                },
              }}
              title={tab.title}
            />
          ))}

        </Tabs>
      </div>

      {tabs[Number(activeKey)].content}

    </>
  );
};

export default ReDoTabs;