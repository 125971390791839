import { useStyletron } from 'baseui';
import { Checkbox, StatefulCheckbox } from 'baseui/checkbox';
import { Blank, Delete } from 'baseui/icon';


const ReDoCheckbox = ({ label, name, onChange, ...props }) => {

  const [css, theme] = useStyletron();

  const checked = props.checked


  return (
    <>
      {Object.keys(props).includes('checked') ?
        <Checkbox
          checked={checked}
          name={name}
          overrides={{
            Checkmark: () => (
              checked ? (
                <div className={css({ lineHeight: '1', height: '1.5ch', width: '1.5ch', alignSelf: 'center', background: theme.colors.primary100, borderRadius: '5px', color: theme.colors.black })}>
                  <Delete />
                </div>
              ) : (
                <div className={css({ lineHeight: '1', height: '1.5ch', width: '1.5ch', alignSelf: 'center', background: theme.colors.primary100, borderRadius: '5px' })}>
                  <Blank />
                </div>
              )
            ),
            Label: {
              style: () => ({
                fontSize: theme.sizing.scale500,
                whiteSpace: 'pre-line',
                color: theme.colors.primary200,
              })
            }
          }}
          onChange={onChange}
        >
          {label}
        </Checkbox>
        :
        <StatefulCheckbox
          name={name}
          overrides={{
            Checkmark: ({ $checked }) => (
              $checked ? (
                <div className={css({ height: '2ch', width: '2ch', alignSelf: 'center', background: theme.colors.primary100, borderRadius: '5px', color: theme.colors.black })}>
                  <Delete size='100%' />
                </div>
              ) : (
                <div className={css({ height: '2ch', width: '2ch', alignSelf: 'center', background: theme.colors.primary100, borderRadius: '5px' })}>
                  <Blank />
                </div>
              )
            ),
            Label: {
              style: () => ({
                fontSize: theme.sizing.scale500,
                whiteSpace: 'pre-line',
                color: theme.colors.primary200,
              })
            }
          }}
          onChange={onChange}
        >
          {label}
        </StatefulCheckbox>
      }
    </>
  );
};

export default ReDoCheckbox;