import { useStyletron } from "baseui";
import { Panel } from "baseui/accordion"
import { ChevronDown, ChevronUp } from "baseui/icon"

const ReDoCustomPanel = ({ title, overrides, list, ...props }) => {

  const [css, theme] = useStyletron();

  return (
    <Panel
      overrides={{
        Header: {
          style: {
            fontWeight: '600',
            borderTopRightRadius: '12px',
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: !list?.includes(title) ? '12px' : '0px',
            borderBottomRightRadius: !list?.includes(title) ? '12px' : '0px',
            fontSize: '14px',
            color: theme.colors.primary200,
            ':hover': {
              color: theme.colors.primary200,
            },
            padding: '12px 20px 12px 1.5ch',
          },
        },
        Content: {
          style: {
            borderBottomRightRadius: '12px',
            borderBottomLeftRadius: '12px',
            padding: '4px 20px 24px 20px'
          }
        },
        PanelContainer: {
          style: {
            border: '0'
          }
        },
        ToggleIcon: {
          style: {
            display: 'none'
          }
        }
      }}
      title={
        <div className={css({ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' })}>
          {!list?.includes(title) ? <ChevronDown color="black" size={36} /> : <ChevronUp color="black" size={36} />}
          {title}
        </div>
      }
      {...props}
    >
      <div className={css({ fontSize: '16px', lineHeight: '1.4' })}>
        {props.children}
      </div>
    </Panel>
  )
}

export default ReDoCustomPanel