import { IonActionSheet, IonContent, IonModal, IonPage } from "@ionic/react";
import { useStyletron } from "baseui";
import { StatelessAccordion } from "baseui/accordion";
import { Avatar } from "baseui/avatar";
import { Badge } from "baseui/badge";
import { Block } from "baseui/block";
import { Button, SHAPE } from "baseui/button";
import { Check, ChevronLeft, ChevronRight, Delete } from "baseui/icon";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { LabelLarge, LabelSmall, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { forwardRef, useEffect, useState } from "react";
import { BsSend } from "react-icons/bs";
import { FaShare } from 'react-icons/fa';
import { IoLocationSharp } from "react-icons/io5";
import { MdBookmarkAdded, MdBookmarkBorder, MdOutlineHowToVote } from 'react-icons/md';
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import { CommentSection } from 'react-swift-nested-comments';
import { submitInbox } from "../../application/actions/inbox";
import { buyLicenseForChallenge } from "../../application/actions/licenses";
import { favoriteSolution, loadSolutionComments, submitComment, voteSolution } from "../../application/actions/solutions";
import { routeTo, setPortalElement } from "../../application/actions/ui";
import { loadProfileById } from "../../application/actions/users";
import diagram_mobile from '../../assets/images/diagram_mobile.png';
import ReDoButton from "../../components/ReDoButton";
import ReDoChat from "../../components/ReDoChat";
import ReDoCustomPanel from "../../components/ReDoCustomPanel";
import ReDoShareModal from "../../components/ReDoShareModal";
import ReDoSplitView from "../../components/ReDoSplitView";
import useBreakpoint from "../../hooks/useBreakpoint";
import useWhoAmI from "../../hooks/useWhoAmI";
import ReDoBackButton from "../../components/ReDoBackButton";


const Solution = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const [user, setUser] = useState({});
  const [comments, setComments] = useState([])
  const [expanded, setExpanded] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState();
  const [solution, setSolution] = useState(props.solution);
  const [currentSlide, setCurrentSlide] = useState();
  const [showModal, setShowModal] = useState(false);
  const [chat, setChat] = useState();
  const breakpoint = useBreakpoint();
  const [actionSheetOpen, setActionSheetOpen] = useState(false)

  const { enqueue } = useSnackbar();
  const { isCompany, userProfile } = useWhoAmI();

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button onClick={onClick} overrides={{ BaseButton: { style: { zIndex: '2', position: 'absolute', top: '50%', left: '2.5%', transform: 'translateY(-50%)', backgroundColor: theme.colors.secondary200, height: '64px', width: '64px' } } }} kind="tertiary" shape={SHAPE.circle}>
        <ChevronLeft size={64} />
      </Button>
    );
  }

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button onClick={onClick} overrides={{ BaseButton: { style: { zIndex: '2', position: 'absolute', top: '50%', right: '2.5%', transform: 'translateY(-50%)', backgroundColor: theme.colors.secondary200, height: '64px', width: '64px' } } }} kind="tertiary" shape={SHAPE.circle}>
        <ChevronRight size={64} />
      </Button>

    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const currentUser = !!Object.keys(userProfile).length ? {
    currentUserId: userProfile.userId,
    currentUserFullName: userProfile.username || userProfile.fullname
  } : null

  const share = () => {
    setShareModalOpen({ visible: true, title: 'Share', description: 'Forward this project to your friends and colleagues.' })
  }

  const vote = () => {
    dispatch(voteSolution({ solutionId: solution.id, vote: 'up' })).then((response) => {

      setSolution(response)
      setShareModalOpen({ visible: true, title: 'Thank you for voting!', description: 'Your vote shows support and helps the inventor. Want to share this with your friends?' })
    }).catch(e => {
      enqueue({ message: e.message + ' ' + e.response?.data?.error, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
    })
  }

  const favorite = () => {
    dispatch(favoriteSolution({ solutionId: solution.id })).then((response) => {

      setSolution(response)
    })
  }

  const handleImageClick = (event, photo) => {
    event.stopPropagation();
    setCurrentSlide(photo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseChat = () => {
    setChat(null)
  }

  const onGetLicense = () => {
    dispatch(buyLicenseForChallenge({ solutionId: solution.id, challengeId: solution.challengeID })).then((response) => {
      enqueue({ message: 'Purchase successful', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> });
    })
  }

  const onSubmitComment = (data) => {

    let comment = {}

    if (data.repliedToCommentId) {
      comment = {
        projectId: solution.id,
        content: data.text,
        rootId: data.parentOfRepliedCommentId ? data.parentOfRepliedCommentId : data.repliedToCommentId,
        parentId: data.repliedToCommentId
      }
    } else {
      comment = {
        projectId: solution.id,
        content: data.text
      }
    }


    dispatch(submitComment(comment)).then(r => dispatch(loadSolutionComments(solution.id))
      .then((response) => {

        setComments(response.map(res => ({
          ...res,
          customAvatar: <Avatar
            name={res.fullName}
            size="28px"
            src={res.avatarUrl}
          />
        })))
      }))
  }

  const onContactClick = () => {

    dispatch(submitInbox({ recipient: solution.userID, project: solution.id, projectType: 'solution' })).then(r => {
      breakpoint === 'large' ? setChat({ ...r, title: r.recipientDisplayName }) : dispatch(routeTo({ view: 'chat', payload: { item: { ...r, title: r.recipientDisplayName } } }))
    })
  }

  const onAvatarClick = () => {
    dispatch(setPortalElement({ element: 'profileContainer', payload: { user } }))
  }

  useEffect(() => {
    dispatch(loadProfileById(solution.userID))
      .then((response) => {
        setUser(response);
      })
    dispatch(loadSolutionComments(solution.id))
      .then((response) => {

        setComments(response.map(res => ({
          ...res,
          customAvatar: <Avatar
            name={res.fullName}
            size="28px"
            src={res.avatarUrl}
          />
        })))
      })
  }, [dispatch, solution.id, solution.userID])

  return (
    <IonPage ref={ref} >
      <IonContent className="ion-padding">

        <IonActionSheet
          isOpen={!!actionSheetOpen}
          buttons={[
            {
              text: 'Edit',
              data: {
                action: 'edit',
              },
              handler: () => !isCompany ? dispatch(routeTo({ view: 'createSolution', payload: { solution: actionSheetOpen } })) : dispatch(routeTo({ view: 'createChallenge', payload: { challenge: actionSheetOpen } }))
            },
            {
              text: 'Private Information',
              data: {
                action: 'privateInformation',
              },
              handler: () => dispatch(setPortalElement({ element: 'hiddenFieldContainer', payload: { note: actionSheetOpen?.note || actionSheetOpen?.why } }))
            },
            {
              text: 'Delete Solution',
              role: 'destructive',
              data: {
                action: 'delete',
              },
            },
          ]}
          onDidDismiss={() => setActionSheetOpen(false)}
        />

        <IonModal className={css({ '--height': '90vh', '--width': '90vw' })} isOpen={showModal} onDidDismiss={handleCloseModal}>
          <img onClick={handleCloseModal} className={css({ objectFit: 'contain', height: '100%', width: '100%', margin: 'auto' })} src={currentSlide} alt="" />
        </IonModal>

        <IonModal className={css({ '--height': '90vh', '--width': '90vw' })} isOpen={!!chat} onDidDismiss={handleCloseChat}>
          <Block padding='28px 28px 0 28px' height='100%'>
            <ReDoChat align item={chat} />
          </Block>
        </IonModal>

        <ReDoShareModal
          modalTitle={shareModalOpen?.title}
          modalDescription={shareModalOpen?.description}
          link={`/solution/${solution.id}`}
          visible={shareModalOpen?.visible}
          onClose={() => setShareModalOpen({ visible: false })}
          title='Check out this project on ReDo!'
          url={`${window.location.origin}/solution/${solution.id}`}
        />


        <ReDoSplitView
          title={
            <Block>
              <ReDoBackButton />
              {solution.title}
            </Block>
          }
          titleEndEnhancer={
            <Block display='flex' gridGap='2ch'>
              <Button startEnhancer={!solution.isFavorite ? <MdBookmarkBorder /> : <MdBookmarkAdded />} onClick={favorite} overrides={{ BaseButton: { style: { boxShadow: breakpoint === 'large' ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : 'none', backgroundColor: 'white', height: '4ch', minWidth: '7ch' } } }} kind="tertiary" shape={SHAPE.pill}>
                {solution.favoriteCount}
              </Button>
              <Button startEnhancer={<MdOutlineHowToVote />} onClick={vote} overrides={{ BaseButton: { style: { boxShadow: breakpoint === 'large' ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : 'none', backgroundColor: 'white', height: '4ch', minWidth: '7ch' } } }} kind="tertiary" shape={SHAPE.pill}>
                {solution.voteCount}
              </Button>
              <Button onClick={share} overrides={{ BaseButton: { style: { boxShadow: breakpoint === 'large' ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : 'none', backgroundColor: 'white', height: '4ch', width: '4ch' } } }} kind="tertiary" shape={SHAPE.circle}>
                <FaShare />
              </Button>
            </Block>
          }
          cover={
            <Block position='relative'>

              <Slider {...settings}>
                {solution.photos.map((photo, index) =>
                  <div key={index}>
                    <img onClick={(e) => handleImageClick(e, photo.photo)} className={css({ objectFit: 'cover !important', height: '400px !important', width: '100% !important' })} src={photo.photo} alt='' />
                  </div>
                )}
              </Slider>



            </Block>
          }
          titleBottomEnhancer={
            <Block display='flex' alignItems='center' gridGap='1ch' marginTop='.5ch'>
              <Badge overrides={{ Badge: { style: { fontSize: '12px', backgroundColor: theme.colors.secondary0, color: 'black', textTransform: 'uppercase', fontWeight: '300' } } }} content={solution.stage + ' stage'} />
              <Badge overrides={{ Badge: { style: { fontSize: '12px', backgroundColor: theme.colors.secondary0, color: 'black', textTransform: 'uppercase', fontWeight: '300' } } }} content={solution.what} />
            </Block>
          }
          leftSection={
            <div className={css({ width: '100%', textAlign: 'center', alignSelf: 'center', marginTop: 'auto' })}>
              <Block>
                {
                  isCompany ?
                    <Block display='flex' gridGap='2ch'>

                      <Block alignItems='flex-start' alignSelf='center' display='flex' flexDirection='column'>
                        <LabelLarge overrides={{ Block: { style: { fontWeight: 'bold' } } }}>This solution is by {user.fullname}</LabelLarge>
                        <LabelSmall>Get in touch with the inventor.</LabelSmall>
                      </Block>

                      <Block display='flex' flex='1' gridGap='2ch' justifyContent='flex-end'>
                        <ReDoButton onClick={onContactClick} overrides={{ BaseButton: { style: { width: 'auto', padding: '1ch 2ch', fontSize: '14px' } } }} kind='outline'>Contact inventor</ReDoButton>
                        {!solution.purchased && <ReDoButton onClick={onGetLicense} overrides={{ BaseButton: { style: { width: 'auto', padding: '1ch 3ch', fontSize: '14px' } } }} kind='brand'>Get licence</ReDoButton>}
                      </Block>

                    </Block> :
                    <>
                      {userProfile.userId === solution.userID ?
                        <Block onClick={() => setActionSheetOpen(solution)} margin='auto' width='56px'><ReDoButton kind='brand'>...</ReDoButton></Block>
                        :
                        <Block display='flex' justifyContent='center'><Block width='75%' display='flex' justifyContent='center'><ReDoButton onClick={vote} overrides={{ BaseButton: { style: { width: '75% !important' } } }} kind='brand'>Vote for this solution</ReDoButton></Block></Block>
                      }
                    </>
                }
              </Block>
            </div>
          }
          mainSection={
            <>
              <Block overrides={{ Block: { style: { borderRadius: '12px' } } }} padding='20px 20px 24px 20px' backgroundColor='white'>
                <ParagraphSmall overrides={{ Block: { style: { margin: '0 0 16px 0', fontWeight: '600', textAlign: 'end', color: theme.colors.primary200 } } }}>DESCRIPTION</ParagraphSmall>

                <b>Dear {solution.target},</b>
                <br />
                <br />
                {solution.description}
                <br />
                <br />
                Yours sincerely,
                <br />
                {user.fullname}
                <br />
                & the ReDo Community

              </Block>

              <StatelessAccordion
                expanded={expanded}
                onChange={(props) => setExpanded(props.expanded)}
                accordion={false}
                overrides={{
                  Root: {
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4ch',
                    },
                  },
                }
                }
              >
                <ReDoCustomPanel
                  title='STATUS QUO'
                  key='STATUS QUO'
                  list={expanded}
                >

                  <b>Which problem am I solving?</b>
                  <br /><br />
                  {solution.problem}
                  <br /><br /><br />
                  <b>What am I redoing?</b>
                  <br /><br />
                  {solution.what}

                </ReDoCustomPanel>

                <ReDoCustomPanel
                  title='VALUE'
                  key='VALUE'
                  list={expanded}
                >

                  <b>What value is created?</b>
                  <br /><br />
                  {solution.createdValue}

                </ReDoCustomPanel>

                <ReDoCustomPanel
                  title='SUSTAINABILITY PROFILE'
                  key='SUSTAINABILITY PROFILE'
                  list={expanded}
                >

                  <b>Impact Areas</b>
                  <br /><br />
                  <Block overrides={{ Block: { style: { gap: '1ch' } } }} display='flex' flexDirection='row' flexWrap='wrap' gap='8px'>
                    {solution.areas?.join(', ')}
                  </Block>
                  <br /><br /><br />
                  <b>Life Cycle</b> <br />
                  <div className={css({ textAlign: 'center' })}>
                    <img className={css({ paddingTop: '1ch', paddingBottom: '1ch', maxWidth: 'min(100%, 330px)' })} src={diagram_mobile} alt=''></img>
                  </div>
                  <br />
                  {solution.lifeCycle}

                </ReDoCustomPanel>
              </StatelessAccordion>

              <Block overrides={{ Block: { style: { borderRadius: '12px' } } }} padding='20px 20px 24px 20px' backgroundColor='white'>
                <ParagraphSmall overrides={{ Block: { style: { margin: '0 0 16px 0', fontWeight: '600', textAlign: 'end', color: theme.colors.primary200 } } }}>VOTE FOR MY PROJECT</ParagraphSmall>

                <b>Dear Community,</b>
                <br />
                <br />
                {solution.communityReason} <br /> <br />
                Thank you,
                <Block alignItems='center' display='flex' marginTop='1.5ch' gridGap='1.5ch'>
                  <Block overrides={{ Block: { style: { cursor: 'pointer' } } }} onClick={onAvatarClick}><Avatar name={user.fullname} size="52px" src={user.photo} /></Block>
                  <Block display='flex' flexDirection='column' gridGap='.5ch'>
                    <ParagraphMedium marginTop='0' marginBottom='0' overrides={{ Block: { style: { fontWeight: 'bold' } } }}>
                      {user.fullname}
                    </ParagraphMedium>
                    <Block alignItems='center' display='flex'>
                      <IoLocationSharp color={theme.colors.secondary200} /><ParagraphMedium color={theme.colors.secondary200} marginTop='0' marginBottom='0'>{user.city}, {user.country}</ParagraphMedium>
                    </Block>
                  </Block>
                </Block>

              </Block>

              <Block position='relative'>
                <CommentSection
                  key={currentUser}
                  customAvatar={
                    <Avatar
                      name={userProfile.fullname}
                      size="38px"
                      src={userProfile.photo}
                    />
                  }
                  placeholder='comment here...'
                  submitBtnContent={<BsSend color="black" />}
                  cancelBtnContent={<RxCross1 color="black" />}
                  cancelBtnStyle={{ border: '0', backgroundColor: 'transparent', margin: '0 1ch 0 auto', padding: '0' }}
                  currentUser={currentUser}
                  logIn={{
                    loginLink: 'http://localhost:3001/',
                    signupLink: 'http://localhost:3001/'
                  }}
                  imgStyle={{ maxWidth: 'none', objectFit: 'cover' }}
                  inputStyle={{ border: '0', marginLeft: '1ch' }}
                  replyInputStyle={{ border: '0' }}
                  submitBtnStyle={{ border: '0', backgroundColor: 'transparent', margin: '0 1ch 0 0', padding: '0' }}
                  overlayStyle={{ fontFamily: 'unset', padding: '0' }}
                  titleStyle={{ fontFamily: 'unset', fontSize: '18px' }}
                  formStyle={{ backgroundColor: 'transparent', padding: '0 0 1ch 0', margin: '2ch 0', borderBottom: `1px solid ${theme.colors.secondary200}`, borderRadius: '0' }}
                  hrStyle={{ display: 'none' }}
                  commentData={comments}
                  onSubmitAction={onSubmitComment}
                  onReplyAction={onSubmitComment}
                  removeEmoji
                />
              </Block>
            </>
          }
          bottomSection={
            <Block display={['block', 'block', 'block', 'none']}>
              {
                isCompany ?
                  <Block marginTop='1ch' display='flex' flexDirection='column' gridGap='2ch'>

                    <Block display='flex' flexDirection='column'>
                      <LabelLarge overrides={{ Block: { style: { fontWeight: 'bold' } } }}>This solution is by {user.fullname}</LabelLarge>
                      <LabelSmall>Get in touch with the inventor.</LabelSmall>
                    </Block>

                    <Block display='flex' flex='1' gridGap='2ch' justifyContent='space-between'>
                      <ReDoButton onClick={onContactClick} overrides={{ BaseButton: { style: { width: 'auto', padding: '1ch 2ch', fontSize: '14px' } } }} kind='outline'>Contact inventor</ReDoButton>
                      {!solution.purchased && <ReDoButton onClick={onGetLicense} overrides={{ BaseButton: { style: { width: 'auto', padding: '1ch 3ch', fontSize: '14px' } } }} kind='brand'>Get licence</ReDoButton>}
                    </Block>

                  </Block> :
                  <>
                    {userProfile.userId === solution.userID ?
                      <Block onClick={() => setActionSheetOpen(solution)} margin='auto' width='56px'><ReDoButton kind='brand'>...</ReDoButton></Block>
                      :
                      <ReDoButton kind='brand'>Vote now</ReDoButton>
                    }
                  </>

              }
            </Block>
          }
        />

      </IonContent>
    </IonPage >
  );
});

export default Solution