import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Check, Delete, Search } from "baseui/icon";
import { Input } from "baseui/input";
import { Cell, Grid } from "baseui/layout-grid";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { Tab, Tabs } from 'baseui/tabs-motion';
import { KIND, Tag, VARIANT } from "baseui/tag";
import { HeadingXLarge, LabelSmall } from 'baseui/typography';
import { debounce } from "lodash";
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { buyLicenseForChallenge } from "../../application/actions/licenses";
import { loadSolutionById, loadSolutions, searchSolutions } from "../../application/actions/solutions";
import { routeTo } from "../../application/actions/ui";
import { getWallet } from '../../application/selectors/wallet';
import ReDoBanner from "../../components/ReDoBanner";
import ReDoButton from "../../components/ReDoButton";
import ReDoCheckbox from "../../components/ReDoCheckbox";
import ReDoProjectCard from '../../components/ReDoProjectCard';
import ReDoSpinner from "../../components/ReDoSpinner";
import useBreakpoint from "../../hooks/useBreakpoint";


const filters = [
  {
    areas: [
      'Energy',
      'Water',
      'Waste',
      'Procurement',
      'Materials',
    ]
  },
  {
    areas: [
      'Communication',
      'Supply Chains',
      'Packaging',
      'End of Life',
      'Infrastructure',
    ]
  },
  { voteCount: ['High to low', 'Low to high'] },
  { stages: ['Early stage', 'Mid stage', 'Final stage'] },
]

const filterNames = {
  areas: 'Area',
  voteCount: 'Votes',
  stages: 'Stage',
}

const SolutionsOverview = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();

  const wallet = useSelector(getWallet)

  const [solutions, setSolutions] = useState([])
  const [loading, setLoading] = useState()
  const [chosenFilters, setChosenFilters] = useState({})
  const [searchTerm, setSearchTerm] = useState()
  const [searchResults, setSearchResults] = useState()
  const [activeKey, setActiveKey] = useState(0);

  const { enqueue } = useSnackbar();

  const breakpoint = useBreakpoint()

  const filteredSolutions = useMemo(() => {
    if (searchTerm || Object.values(chosenFilters)?.length) {
      return searchResults
    } else {
      return solutions
    }
  }, [solutions, searchTerm, searchResults, chosenFilters])

  const debouncedSearch = useRef(
    debounce(async (criteria, filters) => {
      setSearchTerm(criteria)
      const payload = { term: criteria, ...filters }
      const response = await dispatch(searchSolutions(payload))

      setSearchResults(response)
    }, 300)
  ).current;

  const onSearch = (e) => {
    debouncedSearch(e.target.value, chosenFilters);
  }

  const onFavorite = () => {
    setChosenFilters({})
    setSearchTerm('')
    setSearchResults([])
    dispatch(loadSolutions).then((data) => {
      setSolutions(data)
      setLoading(false)
    })
  }

  const resetFilters = () => {
    setChosenFilters({})
    debouncedSearch(searchTerm, {});
  }

  const onFilter = useCallback((e, synthetic) => {
    const checkedFilterName = e?.target?.labels?.[0]?.outerText || synthetic?.checkedFilterName

    let checkedFilterGroup
    filters.some(filterObj => {
      const filterKey = Object.keys(filterObj)[0];
      if (filterObj[filterKey].includes(checkedFilterName)) {
        checkedFilterGroup = filterKey;
        return true
      }
      return false
    })

    let filterPayload
    const checkedFilterAPIName = (checkedFilterGroup === 'areas' ? checkedFilterName.toLowerCase() : e?.target?.labels?.[0]?.outerText?.toLowerCase()?.split(' ')?.[0]) || synthetic?.checkedFilterAPIName
    const checkedFilterValue = e?.target?.checked || synthetic?.checkedFilterValue


    if (checkedFilterValue) {
      filterPayload = { ...chosenFilters, [checkedFilterGroup]: [...(chosenFilters?.[checkedFilterGroup] || []), checkedFilterAPIName] }
      setChosenFilters(filterPayload)
    } else {
      filterPayload = { ...chosenFilters, [checkedFilterGroup]: chosenFilters?.[checkedFilterGroup]?.filter(filter => filter !== checkedFilterAPIName) }
      setChosenFilters(filterPayload)
    }
    debouncedSearch(searchTerm, filterPayload);
  }, [chosenFilters, debouncedSearch, searchTerm])

  const navigateToSolution = (solution) => {
    if (solution.purchased) {
      dispatch(loadSolutionById(solution.id)).then(sol => {
        dispatch(routeTo({ view: 'solution', payload: { solution: sol } }))
      })
    } else {
      setLoading(true)
      dispatch(buyLicenseForChallenge({ solutionId: solution.id })).then(() => {
        enqueue({ message: 'Purchase successful', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> })
        setChosenFilters({})
        setSearchTerm('')
        setSearchResults([])
        dispatch(loadSolutions).then((data) => {
          setSolutions(data)
          setLoading(false)
        })
      }).catch(e => {
        setLoading(false)
        enqueue({ message: e?.response?.data?.error, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> })
      })
    }
  }

  const getIsChecked = useCallback((filterName, filterValue) => {
    return chosenFilters?.[filterName]?.includes(filterValue?.toLowerCase()?.split(' ')?.[0]) || chosenFilters?.[filterName]?.includes(filterValue.toLowerCase())
  }, [chosenFilters])

  const getChosenFilterFullName = (filterName, filterValue) => {
    return filters?.find(filter => filter[filterName])?.[filterName]?.find(filter => filter?.toLowerCase()?.split(' ')?.[0] === filterValue) || filterValue
  }

  useEffect(() => {
    setLoading(true)
    dispatch(loadSolutions).then((data) => {
      setSolutions(data)
      setLoading(false)
    })
    return () => {
      debouncedSearch.cancel();
    };
  }, [dispatch, debouncedSearch])

  return (
    <>

      {loading ? <ReDoSpinner /> :
        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 2]}
          flexGridRowGap="scale800"
          height='100%'
          alignItems='center'
          overflow={breakpoint === 'large' ? 'hidden' : 'unset'}
          margin={breakpoint === 'large' ? '0 -28px' : 'unset'}
        >
          <FlexGridItem position='relative' height='100%' display={['none', 'none', 'none', 'flex']}>
            <ReDoBanner>
              <div className={css({ height: '100%', display: 'flex', flexDirection: 'column', padding: `${theme.sizing.scale900} 6ch` })}>
                <HeadingXLarge marginTop='2ch' overrides={{ Block: { style: { fontWeight: '300', lineHeight: '48px' } } }}>Find new <br /> innovations</HeadingXLarge>
                <Block marginTop='7%' height={theme.sizing.scale1400}>
                  <Input
                    onChange={onSearch}
                    startEnhancer={<Search size="24px" />}
                    placeholder="Search for solutions..."
                    overrides={{
                      Root: {
                        style: {
                          height: '100%',
                          borderRadius: '50px',
                          backgroundColor: 'transparent',
                          borderColor: 'transparent',
                          boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)`
                        }
                      },
                      StartEnhancer: {
                        style: {
                          backgroundColor: 'transparent',
                        }
                      },
                      InputContainer: {
                        style: {
                          backgroundColor: 'transparent',
                        }
                      },
                    }}
                  />
                </Block>
                <Block paddingBottom='1ch' overrides={{ Block: { style: { borderBottom: `1px solid ${theme.colors.primary100}` } } }} marginTop='7%'>
                  <Grid gridColumns={4} gridMargins={4}>
                    <Cell >
                      <LabelSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Area</LabelSmall>
                    </Cell>
                    <Cell >
                      <LabelSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}></LabelSmall>
                    </Cell>
                    <Cell >
                      <LabelSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Votes</LabelSmall>
                    </Cell>
                    <Cell >
                      <LabelSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Stage</LabelSmall>
                    </Cell>
                  </Grid>
                </Block>
                <Block marginTop='1ch'>
                  <Grid gridColumns={4} gridMargins={4}>
                    {filters.map((filter, index) => (
                      <Cell key={index}>
                        {Object.values(filter)[0].map((filterValue, index) => (
                          <Block padding='5px 0' key={filterValue}>
                            <ReDoCheckbox checked={getIsChecked(Object.keys(filter)[0], filterValue)} onChange={onFilter} label={filterValue} />
                          </Block>
                        ))}
                      </Cell>
                    ))}
                  </Grid>
                </Block>
              </div>
            </ReDoBanner>
          </FlexGridItem>

          <FlexGridItem display='flex' flexDirection='column' height='100%'>

            <Block display={['flex', 'flex', 'flex', 'none']} flexDirection='column'>

              <Block marginTop='3ch' display={['unset', 'unset', 'unset', 'none']}>
                <Block height={theme.sizing.scale1400}>
                  <Input
                    onChange={onSearch}
                    startEnhancer={<Search size="24px" />}
                    placeholder="Search for solutions..."
                    overrides={{
                      Root: {
                        style: {
                          height: '100%',
                          backgroundColor: 'white',
                          borderColor: 'transparent !important',
                          borderBottomRightRadius: '0',
                          borderBottomLeftRadius: '0',
                          ':focus-visible': {
                            outline: 'none',
                          },
                        }
                      },
                      StartEnhancer: {
                        style: {
                          backgroundColor: 'white',
                        }
                      },
                      InputContainer: {
                        style: {
                          backgroundColor: 'white',
                        }
                      },
                    }}
                  />
                </Block>
                <Tabs
                  activeKey={activeKey}
                  onChange={({ activeKey }) => setActiveKey(activeKey)}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        width: '100%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                      })
                    },
                    TabBorder: {
                      style: ({ $theme }) => ({
                        display: 'none'
                      })
                    },
                    TabHighlight: {
                      style: ({ $theme }) => ({
                        height: '2px',
                        borderRadius: '25px',
                      })
                    },
                    TabList: {
                      style: ({ $theme }) => ({
                        marginBottom: '3ch',
                        gap: '5%',
                        padding: '0 22px'
                      })
                    },
                  }}
                >
                  {[{ areas: [...filters[0].areas, ...filters[1].areas] }, filters[2], filters[3]].map((filter, index) => (
                    <Tab overrides={{
                      Tab: {
                        style: () => ({
                          backgroundColor: 'transparent',
                          ':hover': {
                            backgroundColor: 'transparent',
                          },
                          paddingBottom: '0.5ch',
                          paddingLeft: '0',
                          paddingRight: '0',
                        })
                      },
                      TabPanel: {
                        style: () => ({
                          padding: '0 22px 22px 22px',
                        })
                      }
                    }} key={index} title={filterNames[Object.keys(filter)[0]]}>
                      <Block overrides={{ Block: { style: { columnCount: '2' } } }}>
                        {Object.values(filter)[0].map((filterValue, index) => (
                          <Block padding='5px 0' key={filterValue}>
                            <ReDoCheckbox checked={getIsChecked(Object.keys(filter)[0], filterValue)} onChange={onFilter} label={filterValue} />
                          </Block>
                        ))}
                      </Block>
                    </Tab>
                  ))}

                </Tabs>
              </Block>

            </Block>

            {!!Object.values(chosenFilters).length &&
              <Block marginTop={['4ch', '4ch', '4ch', '50px']} display='flex' alignItems='center'>
                <Block alignItems='center' display='flex' flexWrap='wrap'>
                  {Object.values(chosenFilters)?.map((filter, index) => (
                    filter.map((filterValue, index) => (
                      <Block marginRight='1ch' key={filterValue}>
                        <Tag
                          overrides={{ Root: { style: { borderRadius: '4px !important', fontSize: '13px' } } }}
                          kind={KIND.primary} variant={VARIANT.solid} onActionClick={() => onFilter(null, {
                            checkedFilterName: getChosenFilterFullName(Object.keys(chosenFilters).find(key => chosenFilters[key] === filter), filterValue),
                            checkedFilterValue: false,
                            checkedFilterAPIName: filterValue
                          })} closeable>{getChosenFilterFullName(Object.keys(chosenFilters).find(key => chosenFilters[key] === filter), filterValue)}</Tag>
                      </Block>
                    ))
                  ))}
                  {!!Object.values(chosenFilters)?.flat()?.length &&
                    <Block>
                      <ReDoButton fontSize='14px' color={theme.colors.secondary200} kind='link' size='compact' onClick={resetFilters}>clear all</ReDoButton>
                    </Block>}
                </Block>
              </Block>
            }

            <Block overflow={['unset', 'unset', 'unset', 'auto']} marginTop={['4ch', '4ch', '4ch', '50px']} paddingBottom='68px'>
              <Block padding={['0', '0', '0', '0 5ch 0 0']} overrides={{ Block: { style: { columnGap: '8ch', columnWidth: '17em' } } }}>
                {!!filteredSolutions?.length &&
                  <Block display={['none', 'none', 'none', 'block']} marginBottom={['4ch', '4ch', '4ch', '8ch']}>
                    <Card
                      overrides={{ Root: { style: { border: 'none', borderRadius: '25px !important', padding: '1ch' } } }}
                    >
                      <StyledBody>
                        <div className={css({ fontSize: '14px' })}>
                          {wallet.balance} coins in my wallet
                        </div>
                        <div className={css({ fontSize: '14px' })}>
                          New coins: {new Date(wallet.subscription?.nextPayment).toLocaleDateString()}
                        </div>
                      </StyledBody>
                      <StyledAction>
                        <Block display='flex' alignItems='center' justifyContent='flex-end'>
                          <Button shape="pill" overrides={{ BaseButton: { style: { border: '1px solid black', color: 'black', backgroundColor: 'transparent', minWidth: '30%', padding: '1ch 2ch !important', ':hover': { backgroundColor: theme.colors.secondary200 } } } }}>
                            Get extra coins
                          </Button>
                        </Block>
                      </StyledAction>
                    </Card>
                  </Block>
                }
                {filteredSolutions?.map((solution) => (
                  <Block key={solution.id} height='550px' marginBottom={['4ch', '4ch', '4ch', '8ch']}>
                    <ReDoProjectCard noBanner onFavorite={onFavorite} onClick={() => navigateToSolution(solution)} project={solution} />
                  </Block>
                ))}
              </Block>
            </Block>

          </FlexGridItem>
        </FlexGrid>
      }

    </ >
  );
});

export default SolutionsOverview;