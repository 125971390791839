import { IonSlide, IonSlides } from '@ionic/react';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button, SHAPE } from 'baseui/button';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { Check, ChevronLeft, ChevronRight } from 'baseui/icon';
import { Tab, Tabs } from 'baseui/tabs-motion';
import { HeadingXLarge } from 'baseui/typography';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadChallengeFeed } from '../application/actions/challenges';
import { loadSolutionById, loadSolutionFeed } from '../application/actions/solutions';
import { routeTo } from '../application/actions/ui';
import useBreakpoint from '../hooks/useBreakpoint';
import ReDoProjectCard from './ReDoProjectCard';
import { DURATION, useSnackbar } from 'baseui/snackbar';
import Slider from "react-slick";


const ReDoExploreTab = ({ target, className, mode, ...props }) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const [checkboxes, setCheckboxes] = useState(false);
  const [data, setData] = useState([])
  const slideRef = useRef()
  const breakpoint = useBreakpoint()
  const [activeKey, setActiveKey] = useState("0");

  const { enqueue } = useSnackbar();


  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <Button onClick={onClick} overrides={{ BaseButton: { style: { zIndex: '2', position: 'absolute', top: '50%', left: '2.5%', transform: 'translateY(-50%)', backgroundColor: theme.colors.secondary200, height: '64px', width: '64px' } } }} kind="tertiary" shape={SHAPE.circle}>
        <ChevronLeft size={64} />
      </Button>
    );
  }

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <Button onClick={onClick} overrides={{ BaseButton: { style: { zIndex: '2', position: 'absolute', top: '50%', right: '2.5%', transform: 'translateY(-50%)', backgroundColor: theme.colors.secondary200, height: '64px', width: '64px' } } }} kind="tertiary" shape={SHAPE.circle}>
        <ChevronRight size={64} />
      </Button>

    );
  }

  const settings = {
    className: "feed center",
    centerMode: true,
    infinite: true,
    centerPadding: "64px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const navigateToProject = (project) => {
    if (checkboxes || activeKey === '1') {
      dispatch(routeTo({ view: 'challenge', payload: { challenge: project } }))
    } else {
      dispatch(loadSolutionById(project.id)).then(sol => {
        dispatch(routeTo({ view: 'solution', payload: { solution: sol } }))
      })
    }
  }

  const onToggle = (e) => {
    setCheckboxes(e.currentTarget.checked)
    if (e.currentTarget.checked) {
      dispatch(loadChallengeFeed).then((data) => {

        setData(data)
      })
    } else {
      dispatch(loadSolutionFeed).then((data) => {

        setData(data)
      })
    }
  }

  const onFavorite = () => {
    dispatch(loadSolutionFeed).then((data) => {
      enqueue({ message: 'Saved to Favorites', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> })
      setData(data)
    })
  }

  const onTabChange = ({ activeKey }) => {
    setActiveKey(activeKey)
    if (activeKey === '0') {
      dispatch(loadSolutionFeed).then((data) => {
        setData(data)
      })
    } else {
      dispatch(loadChallengeFeed).then((data) => {
        setData(data)
      })
    }
  }

  useEffect(() => {
    dispatch(loadSolutionFeed).then((data) => {
      setData(data)
    })
  }, [dispatch])

  return (
    <>
      {breakpoint === 'large' &&
        <Block display='flex' flexDirection='column' height='100%' padding={`0 ${theme.sizing.scale850}`}>

          <Block paddingTop='6ch' height='100%' display='flex'>

            <Block display='flex' flexDirection='column' width='15%'>
              <HeadingXLarge overrides={{ Block: { style: { lineHeight: '48px', fontWeight: '300' } } }} paddingTop='1ch' margin='0'>Let's explore</HeadingXLarge>

              {!mode &&
                <Block paddingTop='3ch' display='flex' alignItems='center' gridGap='1ch'>
                  Solutions
                  <Checkbox
                    checked={checkboxes}
                    onChange={onToggle}
                    checkmarkType={STYLE_TYPE.toggle_round}
                  />
                  Challenges
                </Block>
              }

            </Block>

            <Block position='relative' height='100%' width='70%'>

              {/* <Button onClick={handlePrev} overrides={{ BaseButton: { style: { zIndex: '2', position: 'absolute', top: '50%', left: '5.5%', transform: 'translateY(-50%)', backgroundColor: theme.colors.secondary200, height: '72px', width: '72px' } } }} kind="tertiary" shape={SHAPE.circle}>
                <ChevronLeft size={128} />
              </Button>

              <IonSlides key={JSON.stringify(data)} options={{ speed: 800, spaceBetween: '-45%', slidesPerView: 3, loop: data.length > 2, centeredSlides: true }} ref={slideRef} pager className={`feed ${css({ height: '550px', width: '100%', '--bullet-background-active': 'transparent', '--bullet-background': 'transparent' })}`}>

                {data.map((project, i) => (
                  <IonSlide key={project.id}>

                    <ReDoProjectCard onFavorite={onFavorite} onClick={navigateToProject} project={project} />

                  </IonSlide>
                ))}


              </IonSlides>

              <Button onClick={handleNext} overrides={{ BaseButton: { style: { zIndex: '2', position: 'absolute', top: '50%', right: '5.5%', transform: 'translateY(-50%)', backgroundColor: theme.colors.secondary200, height: '72px', width: '72px' } } }} kind="tertiary" shape={SHAPE.circle}>
                <ChevronRight size={128} />
              </Button> */}

              {!!data.length &&
                <Block display='flex' flexDirection='column' justifyContent='center' height='100%' width='100%'>
                  <Slider key={JSON.stringify(data)} {...settings}>
                    {data.map((project, i) => (
                      <Block height='550px' width='375px'>
                        <ReDoProjectCard key={project.id} onFavorite={onFavorite} onClick={navigateToProject} project={project} />
                      </Block>
                    ))}
                  </Slider>
                </Block>
              }

            </Block>

            <Block display='flex' flexDirection='column' width='15%' />

          </Block>

        </Block>
      }

      {breakpoint !== 'large' &&
        <>
          {!mode && <div className="explore">
            <Tabs
              overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    backgroundColor: 'transparent'
                  })
                },
                TabBorder: {
                  style: ({ $theme }) => ({
                    backgroundColor: 'transparent'
                  })
                },
                TabHighlight: {
                  style: () => ({
                    height: '2px',
                    minWidth: '88px',
                  })
                },
                TabList: {
                  style: () => ({
                    gap: '2ch'
                  })
                }
              }}
              activeKey={activeKey}
              onChange={onTabChange}
              activateOnFocus
            >

              <Tab
                overrides={{
                  Tab: {
                    style: () => ({
                      background: 'transparent',
                      fontSize: '18px',
                      fontWeight: activeKey === '0' ? 'bold' : 'regular',
                      padding: '2px',
                      ':hover': {
                        background: 'transparent',
                      }
                    })
                  },
                }}
                title="Solutions"
              />

              <Tab
                overrides={{
                  Tab: {
                    style: () => ({
                      background: 'transparent',
                      fontSize: '18px',
                      fontWeight: activeKey === '1' ? 'bold' : 'regular',
                      padding: '2px',
                      ':hover': {
                        background: 'transparent',
                      }
                    })
                  }
                }}
                title="Challenges"
              />

            </Tabs>
          </div>}

          <Block height='calc(100% - 90px - 19ch)' justifyContent='center' display='flex' position='relative' margin='1ch -28px 0 -28px' width='100vw'>
            <IonSlides key={JSON.stringify(data)} options={{ speed: 800, spaceBetween: '-45%', slidesPerView: 1 }} ref={slideRef} pager className={`feed ${css({ height: '100%', transform: 'scale(1)', width: '100%', '--bullet-background-active': 'transparent', '--bullet-background': 'transparent' })}`}>
              {data.map((project, i) => (
                <IonSlide key={project.id}>
                  <Block height='100%' width='90%' display='flex' alignItems='center'>
                    <Block height='400px' width='100%'>
                      <ReDoProjectCard onClick={navigateToProject} project={project} />
                    </Block>
                  </Block>
                </IonSlide>
              ))}
            </IonSlides>
          </Block>
        </>
      }
    </>
  );
};

export default ReDoExploreTab;