import * as actions from '../actions/ui';

const initialState = {
  activeTab: '0',
  currentView: '',
  lastView: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case (actions.SET_ACTIVE_TAB):
      return { ...state, activeTab: action.payload };
    case (actions.SET_VIEW):
      return { ...state, currentView: { view: action.payload.view, payload: action.payload.payload, tab: action.payload.tab } };
    case (actions.CACHE_VIEW):
      return { ...state, lastView: { view: action.payload.view, payload: action.payload.payload, tab: action.payload.tab } };
    case (actions.SET_PORTAL_ELEMENT):
      return { ...state, currentPortalElement: action.payload }
    default:
      return state;
  }
}