import { CREATE_COMPANY, loadCompanySuccess, LOAD_COMPANY, LOAD_COMPANY_BY_ID, UPLOAD_LOGO } from "../actions/company";
import { loadWallet } from "../actions/wallet";




export const company = {

  [LOAD_COMPANY]: async ({ api, dispatch, getState, action }) => {
    const response = await api.company.getCompany();
    dispatch(loadCompanySuccess(response.data));
    dispatch(loadWallet)
    return response.data
  },

  [CREATE_COMPANY]: async ({ api, dispatch, getState, action }) => {
    await api.company.createCompany(action.payload);
  },

  [UPLOAD_LOGO]: async ({ api, dispatch, getState, action }) => {
    await api.company.uploadLogo(action.payload);
  },

  [LOAD_COMPANY_BY_ID]: async ({ api, dispatch, getState, action }) => {
    const response = await api.company.getCompanyById(action.payload);
    return response.data;
  },

}