import { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { routeTo } from "../application/actions/ui";
import { loadSolutionOverview } from "../application/actions/solutions";
import { loadChallengeById } from "../application/actions/challenges";
import { loadProfile } from "../application/actions/users";
import { loadCompany } from "../application/actions/company";
import { saveUserRole } from "../application/actions/auth";


export default function useUrlRedirect({ cognito }) {
  const user = cognito.user
  const role = cognito.role
  const cognitoLoading = cognito.loading
  const dispatch = useDispatch();
  const [loading, setLoading] = useState()

  useLayoutEffect(() => {
    setLoading(true)

    const handleLoadProfiles = async () => {
      let profile
      let company

      try {
        profile = await dispatch(loadProfile())
      } catch (error) {
        try {
          company = await dispatch(loadCompany())
        } catch (error) { }
      }

      setLoading(false)
      if (profile?.userId) dispatch(routeTo({ view: 'userProfile' }));
      if (company?.userId) dispatch(routeTo({ view: 'companyProfile' }));

      console.log(profile?.userId, company?.userId);

      if (!profile?.userId && !company?.userId) {
        if (role === 'user') {
          dispatch(routeTo({ view: 'userCreateProfile' }));
        } else {
          dispatch(routeTo({ view: 'companyCreateProfile' }))
        }
      }
    }

    const url = window.location.pathname;
    const parts = url.split('/');
    const obj = parts?.[1];
    const id = parts?.[2];

    if (cognitoLoading || !role) return

    console.log(user, role);

    switch (obj) {
      case 'solution':
        dispatch(loadSolutionOverview(id)).then((solution) => {
          setLoading(false)
          if (user) {
            dispatch(routeTo({ view: 'solution', payload: { solution } }));
          } else {
            dispatch(routeTo({ view: 'signup', payload: { project: solution } }));
          }
        })
        break;
      case 'challenge':
        dispatch(loadChallengeById(id)).then((challenge) => {
          setLoading(false)
          if (user) {
            dispatch(routeTo({ view: 'challenge', payload: { challenge } }));
          } else {
            dispatch(routeTo({ view: 'signup', payload: { project: challenge } }));
          }
        })
        break;
      case 'company':
        dispatch(saveUserRole(obj)).then(() => {
          setLoading(false)
          if (!user) {
            dispatch(routeTo({ view: 'login', payload: { scope: 'company' } }))
          } else {
            handleLoadProfiles()
          }
        })
        break;
      case 'user':
        dispatch(saveUserRole(obj)).then(() => {
          setLoading(false)
          if (!user) {
            dispatch(routeTo({ view: 'login', payload: { scope: 'user' } }))
          } else {
            handleLoadProfiles()
          }
        })
        break;
      default:
        if (user) {
          handleLoadProfiles()
        } else {
          setLoading(false)
        }
        break;
    }
  }, [dispatch, user, role, cognitoLoading]);

  return { loading }
}