export const LOAD_PROFILE = '[users] load profile';
export const LOAD_PROFILE_SUCCESS = '[users] load profile success';
export const CREATE_PROFILE = '[users] create profile';
export const UPLOAD_AVATAR = '[users] upload avatar';
export const LOAD_PROFILE_BY_ID = '[users] load profile by id';


export const loadProfile = users => ({
    type: LOAD_PROFILE,
    payload: users,
});

export const loadProfileSuccess = users => ({
    type: LOAD_PROFILE_SUCCESS,
    payload: users,
});

export const createProfile = users => ({
    type: CREATE_PROFILE,
    payload: users,
});

export const uploadAvatar = users => ({
    type: UPLOAD_AVATAR,
    payload: users,
});

export const loadProfileById = id => ({
    type: LOAD_PROFILE_BY_ID,
    payload: id,
})