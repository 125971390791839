import { IonContent, IonPage } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadInboxes } from "../../application/actions/inbox";
import { loadNotifications } from "../../application/actions/notifications";
import { getActiveTab } from "../../application/selectors/ui";
import ReDoBanner from "../../components/ReDoBanner";
import ReDoChat from "../../components/ReDoChat";
import ReDoCreateChallenge from "../../components/ReDoCreateChallenge";
import ReDoDashboard from "../../components/ReDoDashboard";
import ReDoLogo from "../../components/ReDoLogo";
import ReDoNavBar from "../../components/ReDoNavBar";
import ReDoNotifications from "../../components/ReDoNotifications";
import useBreakpoint from "../../hooks/useBreakpoint";
import SolutionsOverview from "../solution/SolutionsOverview";


const CompanyProfile = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const activeTab = useSelector(getActiveTab);

  const [chat, setChat] = useState();

  const breakpoint = useBreakpoint()

  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    margin: '0 auto',
    gap: '1ch'
  });

  const onInboxClick = ({ item }) => {
    setChat(item)
  }

  useEffect(() => {
    // Load data initially
    dispatch(loadNotifications);
    dispatch(loadInboxes);

    // Set up an interval to fetch data every, for example, 5 minutes
    const intervalId = setInterval(() => {
      dispatch(loadNotifications);
      dispatch(loadInboxes);
    }, 0.5 * 60 * 1000); // 0.5 minutes in milliseconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <IonPage ref={ref} >
      <IonContent className="ion-padding">



        {breakpoint === 'large' &&
          <Block position='relative' height='100%' display='flex' flexDirection='column'>

            <Block margin='-28px' display='flex' justifyContent='space-between'>
              <Block padding='28px 0 0 56px' width='calc(50% - 28px)' backgroundColor='white'>
                <ReDoNavBar />
              </Block>
              <Block padding='28px 56px 0 0'>
                <ReDoLogo size={theme.sizing.scale2400} />
              </Block>
            </Block>

            {activeTab === '0' &&
              <SolutionsOverview />
            }

            {activeTab === '1' &&
              <ReDoCreateChallenge />
            }

            {activeTab === '2' &&
              <FlexGrid
                flexGridColumnCount={2}
                flexGridRowGap="scale800"
                height='100%'
                alignItems='flex-start'
                overflow='hidden'
                margin='0 -28px'
              >
                <FlexGridItem position='relative' height='100%'>
                  <ReDoBanner align padding={`${theme.sizing.scale850} ${theme.sizing.scale1400}`}>
                    <Block paddingTop='4ch' paddingLeft='3ch'>
                      <ReDoNotifications onChatClick={onInboxClick} />
                    </Block>
                  </ReDoBanner>
                </FlexGridItem>

                <FlexGridItem position='relative' height='100%'>
                  <Block height='100%' display='flex' flexDirection='column' padding={`0 ${theme.sizing.scale900}`}>
                    {chat &&
                      <Block key={chat.id} display='flex' flexDirection='column' height='100%'>
                        <Block height='calc(100% - 40px);' marginTop='auto'>
                          <ReDoChat align item={chat} />
                        </Block>
                      </Block>
                    }
                  </Block>
                </FlexGridItem>
              </FlexGrid>
            }

            {activeTab === '3' &&
              <ReDoDashboard />
            }



          </Block>
        }


        {breakpoint !== 'large' &&
          <Block height='calc(100% - 96px)' display='flex'>
            <div className={column}>

              <Block alignSelf='flex-end' display={['unset', 'unset', 'unset', 'none']}>
                <ReDoLogo size={theme.sizing.scale1600} />
              </Block>

              {activeTab === '0' &&
                <SolutionsOverview />
              }

              {activeTab === '1' &&
                <ReDoCreateChallenge />
              }

              {activeTab === '2' &&
                <ReDoNotifications />
              }

              {activeTab === '3' &&
                <ReDoDashboard />
              }

              <ReDoNavBar />
            </div>
          </Block>
        }


      </IonContent>
    </IonPage >
  );
});

export default CompanyProfile;