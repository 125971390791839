import * as actions from '../actions/notifications';

const initialState = {
  notifications: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case (actions.LOAD_NOTIFICATIONS_SUCESS):
      return { ...state, notifications: action.payload }
    default:
      return state;
  }
}