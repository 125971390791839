import { IonApp, setupIonicReact } from '@ionic/react';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import 'react-swift-nested-comments/dist/index.css';
import './theme/index.css';
import './theme/variables.css';


import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, setView } from './application/actions/ui';
import { getCurrentPortalElement, getCurrentView } from './application/selectors/ui';
import CreateSolution from './components/ReDoCreateSolution';
import useNavigator from './hooks/useNavigator';
import Solution from './views/solution/Solution';
import UserCreateProfile from './views/userAccount/UserCreateProfile';
import UserProfile from './views/userAccount/UserProfile';

import { createElement } from 'react';
import CreateChallenge from './components/ReDoCreateChallenge';
import ReDoHiddenFieldContainer from './components/ReDoHiddenFieldContainer';
import ReDoPricing from './components/ReDoPricing';
import ReDoProfileContainer from './components/ReDoProfileContainer';
import ReDoShareModal from './components/ReDoShareModal';
import ReDoSpinner from './components/ReDoSpinner';
import useCognito from './hooks/useCognito';
import useUrlRedirect from './hooks/useUrlRedirect';
import Challenge from './views/challenge/Challenge';
import Chat from './views/chat/Chat';
import CompanyCreateProfile from './views/companyAccount/CompanyCreateProfile';
import CompanyProfile from './views/companyAccount/CompanyProfile';
import ForgotPassword from './views/login/ForgotPassword';
import Login from './views/login/Login';
import Signup from './views/login/Signup';
import SolutionsOverview from './views/solution/SolutionsOverview';
import { Block } from 'baseui/block';


setupIonicReact({
  mode: 'ios'
});

const views = {
  login: Login,
  userProfile: UserProfile,
  userCreateProfile: UserCreateProfile,
  signup: Signup,
  forgotPassword: ForgotPassword,
  createSolution: CreateSolution,
  solution: Solution,
  companyCreateProfile: CompanyCreateProfile,
  companyProfile: CompanyProfile,
  createChallenge: CreateChallenge,
  challenge: Challenge,
  solutionsOverview: SolutionsOverview,
  chat: Chat
}

const portalElements = {
  profileContainer: ReDoProfileContainer,
  hiddenFieldContainer: ReDoHiddenFieldContainer,
  redoPricing: ReDoPricing,
  shareModal: ReDoShareModal
}

const App = () => {
  const cognito = useCognito()
  const dispatch = useDispatch();

  const currentView = useSelector(getCurrentView);
  const currentPortalElement = useSelector(getCurrentPortalElement)

  const screen = useNavigator({
    views,
    currentView,
    setView: (view) => dispatch(setView(view)),
    setTab: (tab) => dispatch(setActiveTab(tab))
  });
  const portalElement = currentPortalElement ? createElement(portalElements[currentPortalElement.element], { ...(currentPortalElement?.payload || {}) }) : null

  const { loading } = useUrlRedirect({ cognito })

  return (
    <IonApp>

      {portalElement}

      {loading && <ReDoSpinner />}

      {screen}

      <Block position='fixed' bottom='0' left='0'>v0.1.8</Block>

    </IonApp>
  );
};

export default App;
