import users from './users';
import auth from './auth';
import solutions from './solutions';
import company from './company';
import challenges from './challenges';
import inbox from './inbox';
import notifications from './notifications';
import licenses from './licenses';
import wallet from './wallet'


export default {
  users,
  auth,
  solutions,
  company,
  challenges,
  inbox,
  notifications,
  licenses,
  wallet
};