import { Amplify, Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { loadUserRole } from '../application/actions/auth';

const isLocalhost = !!(window.location.hostname === "localhost");

const useCognito = (props) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState('')

  Amplify.configure({
    Auth: {
      region: 'eu-central-1',
      userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOL_WEBCLIENT_ID,
      signUpVerificationMethod: 'code',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: [
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: !isLocalhost ? process.env.REACT_APP_REDIRECT_URL : "http://localhost:3000",
        redirectSignOut: !isLocalhost ? process.env.REACT_APP_REDIRECT_URL : "http://localhost:3000",
        responseType: "code"
      }
    }
  });

  const config = Auth.configure()

  useEffect(() => {
    const loadRole = async () => {
      const role = await dispatch(loadUserRole)
      setRole(role)
    }
    loadRole()
    
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setUser(data);
          setLoading(false);
          break;
        case "signOut":
          setUser(null);
          setLoading(false);
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        setUser(currentUser)
        setLoading(false);
      })
      .catch(() => {
        console.log("Not signed in")
        setLoading(false);
      });

    const refreshSession = async () => {
      try {
        await Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        console.log("Refreshing session");
        setUser(user);
      } catch (e) {
        console.log(e);
      }
    };

    const refreshInterval = setInterval(() => {
      refreshSession();
    }, 1000 * 60 * 10); // refresh every 10 minutes

    return () => {
      clearInterval(refreshInterval);
      unsubscribe();
    };
  }, [dispatch]);

  return { user, role, loading, config };

};

export default useCognito;
