export const LOAD_NOTIFICATIONS = '[inbox] load notifications';
export const LOAD_NOTIFICATIONS_SUCESS = '[inbox] load notifications success';


export const loadNotifications = {
  type: LOAD_NOTIFICATIONS,
}

export const loadNotificationsSuccess = ntf => ({
  type: LOAD_NOTIFICATIONS_SUCESS,
  payload: ntf,
});
