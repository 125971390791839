export const SIGN_IN = '[auth] try sign in';
export const SIGN_OUT = '[auth] try sign out';
export const SIGN_UP = '[auth] try sign up';
export const FORGOT_PASSWORD = '[auth] try forgot password';
export const RESET_PASSWORD = '[auth] try reset password';
export const FEDERATED_SIGN_IN = '[auth] try federated sign in';
export const SAVE_USER_ROLE = '[auth] save user role'
export const LOAD_USER_ROLE = '[auth] load user role'


export const signIn = form => ({
  type: SIGN_IN,
  payload: form
})

export const signOut = {
  type: SIGN_OUT
}

export const loadUserRole = {
  type: LOAD_USER_ROLE
}

export const signUp = form => ({
  type: SIGN_UP,
  payload: form
})

export const forgotPassword = form => ({
  type: FORGOT_PASSWORD,
  payload: form
})

export const resetPassword = form => ({
  type: RESET_PASSWORD,
  payload: form
})

export const federatedSignIn = provider => ({
  type: FEDERATED_SIGN_IN,
  payload: provider
})

export const saveUserRole = role => ({
  type: SAVE_USER_ROLE,
  payload: role
})