import { BUY_LICENSE_FOR_CHALLENGE, GET_LICENSE, buyLicenseSuccess } from "../actions/licenses";


export const licenses = {

  [BUY_LICENSE_FOR_CHALLENGE]: async ({ api, dispatch, getState, action }) => {
    const response = await api.licenses.postLicense(action.payload);
    
    dispatch(buyLicenseSuccess(response.data));
  },

  [GET_LICENSE]: async ({ api, dispatch, getState, action }) => {
    const response = await api.licenses.getLicenses();

    return response.data
  },


}