import { IonSpinner } from "@ionic/react";
import { Block } from "baseui/block";


const ReDoSpinner = () => {

  return (
    <Block backgroundColor='rgb(255 255 255 / 60%)' overrides={{ Block: { style: { zIndex: '99' } } }} position='fixed' top='0' left='0' width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>

      <IonSpinner name="dots" />

    </Block>

  )
}

export default ReDoSpinner;