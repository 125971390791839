export const LOAD_COMPANY = '[company] load company';
export const LOAD_COMPANY_SUCCESS = '[company] load company success';
export const CREATE_COMPANY = '[company] create company';
export const UPLOAD_LOGO = '[company] upload avatar';
export const LOAD_COMPANY_BY_ID = '[company] load company by id';


export const loadCompany = company => ({
    type: LOAD_COMPANY,
    payload: company,
});

export const loadCompanySuccess = company => ({
    type: LOAD_COMPANY_SUCCESS,
    payload: company,
});

export const createCompany = company => ({
    type: CREATE_COMPANY,
    payload: company,
});

export const uploadLogo = company => ({
    type: UPLOAD_LOGO,
    payload: company,
});

export const loadCompanyById = id => ({
    type: LOAD_COMPANY_BY_ID,
    payload: id,
})