import { useStyletron } from 'baseui';
import { Button, SHAPE } from 'baseui/button';


const ReDoButton = ({ fontWeight, fontSize, color, overrides, kind, ...props }) => {
  const [, theme] = useStyletron();

  if (kind === 'brand')
    return (
      <Button
        overrides={{
          ...overrides,
          BaseButton: {
            style: {
              fontSize: theme.sizing.scale700,
              width: '100%',
              fontWeight: 'bold',
              boxShadow: 'none',
              ...overrides?.BaseButton?.style
            }
          }
        }}
        shape={SHAPE.pill} size='large' {...props}
      >
        {props.children}
      </Button>
    )

  if (kind === 'link')
    return (
      <Button overrides={{
        BaseButton: {
          style: {
            color,
            marginLeft: '4px',
            marginRight: '2px',
            paddingTop: '0',
            paddingBottom: '0',
            paddingLeft: '0',
            paddingRight: '0',
            fontWeight: fontWeight || 600,
            fontSize: fontSize || theme.sizing.scale500,
            borderRadius: '10px',
            height: '1px',
            boxShadow: `0 9px 0 0 ${color ? color : theme.colors.black}`,
            ':active': { backgroundColor: 'none' },
            ":hover": {
              backgroundColor: 'none',
              color: theme.colors.borderInverseOpaque
            },
            ...overrides?.BaseButton?.style
          }
        }
      }} kind='tertiary' size='compact' {...props}>
        {props.children}
      </Button >
    )

  if (kind === 'inverse')
    return (
      <Button overrides={{
        ...overrides,
        BaseButton: {
          style: {
            lineHeight: '28px',
            padding: '14px 16px',
            fontSize: theme.sizing.scale700,
            width: '100%',
            backgroundColor: theme.colors.primaryB,
            color: theme.colors.primaryA,
            boxShadow: 'none',
            ...overrides?.BaseButton?.style,
            ":hover": {
              backgroundColor: theme.colors.secondary200
            }
          }
        }
      }}
        shape={SHAPE.pill} kind='tertiary' size='large' {...props}>
        {props.children}
      </Button>
    )

  if (kind === 'outline')
    return (
      <Button
        overrides={{
          BaseButton: {
            style: {
              fontSize: theme.sizing.scale750,
              width: '100%',
              backgroundColor: theme.colors.primaryB,
              border: '1px solid',
              color: theme.colors.primaryA,
              boxShadow: 'none',
              ":hover": {
                backgroundColor: theme.colors.backgroundTertiary
              },
              ...overrides?.BaseButton?.style
            }
          }
        }}
        shape={SHAPE.pill} size='large' {...props}
      >
        {props.children}
      </Button>
    )
};

export default ReDoButton;