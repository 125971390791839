import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { Badge } from 'baseui/badge';
import { Block } from 'baseui/block';
import { Button, SHAPE } from 'baseui/button';
import { Card } from 'baseui/card';
import { HeadingSmall, LabelLarge } from 'baseui/typography';
import { useState } from 'react';
import { FaShare } from 'react-icons/fa';
import { MdBookmarkAdded, MdBookmarkBorder, MdOutlineHowToVote } from 'react-icons/md';
import { useDispatch } from "react-redux";
import { favoriteChallenge, shareChallenge } from '../application/actions/challenges';
import { favoriteSolution, shareSolution } from '../application/actions/solutions';
import { setPortalElement } from '../application/actions/ui';
import useWhoAmI from '../hooks/useWhoAmI';
import ReDoMatched from './ReDoMatched';
import logo from '../assets/images/logo.jpg';


const ReDoProjectCard = ({ onClick, onFavorite, project, noDescription, shadow, noButton, noBanner, ...props }) => {
  const dispatch = useDispatch()
  const [css, theme] = useStyletron();
  const [matchedClicked, setMatchedClicked] = useState()

  const { isCompany } = useWhoAmI()

  const onCardClick = () => {
    onClick && onClick(project)
  }

  const share = () => {

    dispatch(setPortalElement({
      element: 'shareModal', payload: {
        modalTitle: 'Share',
        visible: true,
        modalDescription: 'Forward this project to your friends and colleagues.',
        link: `/solution/${project.id}`,
        title: 'Check out this project on ReDo!',
        url: `${window.location.origin}/solution/${project.id}`,
        onBeforeClick: !project.companyId ? () => dispatch(shareSolution({ solutionId: project.id })) : () => dispatch(shareChallenge({ challengeId: project.id }))
      }
    }))
  }

  const favorite = () => {
    if (project.challengeId) {
      dispatch(favoriteChallenge({ challengeId: project.id })).then((response) => {
        onFavorite && onFavorite(response)
      })
    } else {
      dispatch(favoriteSolution({ solutionId: project.id })).then((response) => {
        onFavorite && onFavorite(response)
      })
    }
  }

  return (
    <>
      {matchedClicked && <ReDoMatched onClose={() => setMatchedClicked()} solution={matchedClicked} challengeId={matchedClicked.challengeID} />}

      <Card overrides={{
        Root: {
          style: {
            boxShadow: shadow ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : 'none',
            position: 'relative', background: 'white', border: 'none', borderRadius: '25px !important', height: '100%', maxHeight: '550px', width: '100%', maxWidth: '375px', display: 'flex', flexDirection: 'column'
          }
        },
        HeaderImage: {
          component: () =>
            <Block height='50%' position='relative' >
              {!noBanner && <div className="ribbon ribbon-top-right"><span className={css({ backgroundColor: !project.companyId ? 'white' : theme.colors.primary400, color: !project.companyId ? theme.colors.primary400 : 'white' })}>{!project.companyId ? 'Solution' : 'Challenge'}</span></div>}
              <Block top='1ch' left='1ch' position='absolute' overrides={{ Block: { style: { zIndex: '3' } } }}>
                <Button onClick={favorite} overrides={{ BaseButton: { style: { backgroundColor: 'white', height: '4ch', width: '4ch' } } }} kind="tertiary" shape={SHAPE.circle}>
                  {!project.isFavorite ? <MdBookmarkBorder /> : <MdBookmarkAdded />}
                </Button>
              </Block>
              <img className={css({ objectFit: 'cover', width: '100% !important', height: '100% !important', opacity: project.status === 'matched' ? 0.5 : 1 })} src={project.photos?.[0]?.photo || logo} alt="" />
              {project.status === 'matched' && <LabelLarge color='white' overrides={{ Block: { style: { zIndex: 2, top: '0', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(235,234,228,0.7)' } } }} position='absolute' margin='0'>MATCHED</LabelLarge>}
              {/*
                project.challengeID &&
                <Block onClick={() => setMatchedClicked(project)} display='flex' alignItems='center' justifyContent='center' width='34px' height='34px' overrides={{ Block: { style: { borderRadius: '100%', fontSize: '24px', zIndex: '2' } } }} backgroundColor='black' position='absolute' right='12px' bottom='12px'>
                  <TbArrowsUpDown color="white" />
                </Block>
              */}
            </Block>
        },
        Contents: { style: { margin: 'auto 0 0 0 !important', display: 'flex', flexDirection: 'column', height: '50%', overflow: 'hidden', padding: '16px' } },
        Title: { style: { margin: '0 0 1ch 0 !important' } },
        Body: { style: { margin: '0 !important', height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column', gap: '1ch' } },
      }}
        headerImage={project.photos[0]?.photo || logo}
        title={
          <Block position='relative' >
            <Block display='flex' alignItems='center' gridGap='5px' justifyContent='space-between' >
              <Block flexWrap='wrap' display='flex' alignItems='center' gridGap='5px' overrides={{ Block: { style: { lineHeight: '0' } } }}>
                <Badge overrides={{ Badge: { style: { fontSize: '12px', backgroundColor: theme.colors.secondary0, color: 'black', textTransform: 'capitalize', fontWeight: '300' } } }} content={project.companyId ? project.period : project.stage + ' stage'} />
                {!project.companyId && <Badge overrides={{ Badge: { style: { fontSize: '12px', backgroundColor: theme.colors.secondary0, color: 'black', fontWeight: '300' } } }} content={'ReDo: ' + project.what} />}
              </Block>
              <Block gridGap='0.5ch' overrides={{ Block: { style: { fontSize: '14px' } } }} display='flex' alignItems='center' justifyContent='center'>
                <MdOutlineHowToVote size='18px' />
                <LabelLarge>{project.voteCount}</LabelLarge>
              </Block>
            </Block>

          </Block>
        }
      >

        <Block width='100%' display='flex' alignItems='center' gridGap='1ch'>
          {project.thumbnail &&
            <Avatar overrides={{
              Root: {
                style: { flexShrink: '0' }
              },
              Avatar: {
                style: { width: '42px !important', height: '42px !important' }
              }
            }} src={project.thumbnail} />
          }
          <Block width='100%'>
            <HeadingSmall margin='0' color={theme.colors.primary400}>{project.title}</HeadingSmall>
          </Block>
        </Block>

        {!noDescription &&
          <div className={css({
            color: theme.colors.primary400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            lineClamp: 3,
            fontSize: '14px',
          })}>
            {!project.companyId ? project.description : project.problem}
          </div>
        }

        <Block marginTop='auto' display='flex' alignItems='center' justifyContent='flex-end' gridGap='1ch'>
          <Button onClick={share} overrides={{ BaseButton: { style: { backgroundColor: 'white', height: '4ch', width: '4ch' } } }} kind="tertiary" shape={SHAPE.circle}>
            <FaShare />
          </Button>
          {!noButton && <Button onClick={onCardClick} shape="pill" overrides={{ BaseButton: { style: { color: 'white', backgroundColor: 'black', fontWeight: 'bold', minWidth: '30%', padding: '1ch 2ch !important' } } }}>
            {isCompany ? !project.companyId ? project.purchased ? 'See more' : 'Get access' : 'See more' : 'See more'}
          </Button>}
        </Block>

      </Card >
    </>
  );
};

export default ReDoProjectCard;