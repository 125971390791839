import { useStyletron } from "baseui";
import { AppNavBar } from "baseui/app-nav-bar";
import { Avatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { Tab, Tabs } from "baseui/tabs-motion";
import { IoAddCircle, IoCompass, IoNotifications } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from "../application/actions/ui";
import { getActiveTab } from "../application/selectors/ui";
import useBreakpoint from "../hooks/useBreakpoint";
import useWhoAmI from "../hooks/useWhoAmI";
import ReDoNotificationsHover from "./ReDoNotificationsHover";


const ReDoNavBar = () => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint()
  const activeTab = useSelector(getActiveTab);
  const [, theme] = useStyletron();

  const { userProfile } = useWhoAmI();

  const tabs = [
    { label: "Explore", title: <IoCompass size={36} />, id: '0' },
    { label: "Create", title: <IoAddCircle size={36} />, id: '1' },
    { label: "Notifications", title: <IoNotifications size={36} />, id: '2' },
    {
      label: "Profile",
      title: <Avatar
        name={userProfile.fullname}
        size='36px'
        src={userProfile.photo}
      />,
      id: '3'
    }
  ].map(i => ({ ...i, active: activeTab === i.id }))

  const onTabChange = ({ activeKey }) => {
    /*if (activeKey === '1') {
      dispatch(routeTo({ view: 'createSolution' }))
    } else {*/
    dispatch(setActiveTab(activeKey))
    //}
  }

  const onMainItemSelect = item => {
    /*if (item.id === '1') {
      dispatch(routeTo({ view: 'createSolution' }))
    } else {*/
    dispatch(setActiveTab(item.id))
    //}
  }

  return (
    <>
      {breakpoint === 'large' &&
        <Block display='flex' justifyContent='space-between'>
          <AppNavBar
            mapItemToNode={(item) => {
              if (item.id === '2') {
                return <ReDoNotificationsHover item={item} />
              } else {
                return item.label
              }
            }}
            overrides={{
              Root: {
                style: {
                  border: '0',
                  backgroundColor: 'transparent',
                  padding: '0 !important'
                }
              },
              MainMenuItem: {
                style: (item) => {
                  return {
                    ...item.style,
                    borderBottom: 'none',
                    fontWeight: item.$active ? 'bold' : 'normal',
                  }
                }
              },
              PrimaryMenuContainer: {
                style: {
                  gap: '4ch',
                  paddingBottom: '2ch',
                }
              },
            }}
            mainItems={tabs}
            onMainItemSelect={onMainItemSelect}
          />
        </Block>
      }

      {(breakpoint === 'small' || breakpoint === 'medium' || breakpoint === 'xsmall') &&
        <div className="nav-bar">
          <Tabs
            activeKey={activeTab.split('/')[0]}
            onChange={onTabChange}
            activateOnFocus
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  position: 'fixed',
                  bottom: 0,
                  width: '100%',
                  backgroundColor: theme.colors.primary100,
                  left: 0,
                })
              },
              TabBorder: {
                style: ({ $theme }) => ({
                  backgroundColor: 'transparent'
                })
              },
              TabHighlight: {
                style: () => ({
                  display: 'none'
                })
              },
              TabList: {
                style: () => ({
                  justifyContent: 'space-evenly',
                  paddingBottom: '0',
                })
              },
            }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                overrides={{
                  Tab: {
                    style: () => ({
                      background: 'transparent',
                      ':hover': {
                        background: 'transparent',
                      },
                      opacity: activeTab === tab.id ? '1' : '0.3',
                      transition: 'all 0.2s ease-in-out',
                    })
                  }
                }} title={tab.title}></Tab>
            ))}
          </Tabs>
        </div>
      }
    </>
  );
}

export default ReDoNavBar