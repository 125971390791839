import { useSelector } from "react-redux";
import { getUserProfile } from '../application/selectors/users';
import { getCompany } from '../application/selectors/company';


const useWhoAmI = () => {
  const profile = useSelector(getUserProfile);
  const company = useSelector(getCompany);

  const isCompany = !!Object.keys(company).length
  const userProfile = isCompany ? company : profile

  return { isCompany, userProfile }
};

export default useWhoAmI;