import { loadLicenses } from "../actions/licenses";
import { loadUserSolutionsSuccess, LOAD_SOLUTIONS, LOAD_USER_SOLUTIONS, SEARCH_SOLUTIONS, SUBMIT_SOLUTION, UPLOAD_SOLUTION_PHOTOS, LOAD_SOLUTION_BY_ID, VOTE_SOLUTION, LOAD_SOLUTION_COMMENTS, SUBMIT_COMMENT, LOAD_SOLUTION_FEED, FAVORITE_SOLUTION, LOAD_FAVORITE_SOLUTIONS, LOAD_SOLUTIONS_FOR_CHALLENGE, LOAD_BOUGHT_SOLUTIONS, SHARE_SOLUTION, LOAD_SOLUTION_OVERVIEW, DELETE_SOLUTION } from "../actions/solutions";


export const solutions = {

  [LOAD_USER_SOLUTIONS]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.getUserSolutions(action.payload);
    dispatch(loadUserSolutionsSuccess(response.data.filter(solution => solution.title)));
  },

  [LOAD_SOLUTIONS_FOR_CHALLENGE]: async ({ api, dispatch, getState, action }) => {
    const licenses = await dispatch(loadLicenses)
    const response = await api.solutions.getSolutionsForChallenge(action.payload);
    const solutions = response.data.filter(solution => solution.title).map(solution => ({ ...solution, purchased: licenses.map(license => license.solutionId).includes(solution.id) }))

    return solutions;
  },

  [LOAD_SOLUTION_BY_ID]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.getSolutionById(action.payload);
    return response.data;
  },

  [LOAD_SOLUTION_OVERVIEW]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.getSolutionOverview(action.payload);
    return response.data.filter(solution => solution.title);
  },

  [SUBMIT_SOLUTION]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.submitSolution(action.payload);
    return response.data;
  },

  [UPLOAD_SOLUTION_PHOTOS]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.uploadSolutionPhotos(action.payload);
    return response.data;
  },

  [LOAD_SOLUTIONS]: async ({ api, dispatch, getState, action }) => {
    const licenses = await dispatch(loadLicenses)
    const response = await api.solutions.getSolutions();
    const solutions = response.data.filter(solution => solution.title).map(solution => ({ ...solution, purchased: licenses.map(license => license.solutionId).includes(solution.id) }))
    return solutions;
  },

  [SEARCH_SOLUTIONS]: async ({ api, dispatch, getState, action }) => {
    const licenses = await dispatch(loadLicenses)
    const response = await api.solutions.searchSolutions(action.payload);
    const solutions = response.data.filter(solution => solution.title).map(solution => ({ ...solution, purchased: licenses.map(license => license.solutionId).includes(solution.id) }))
    return solutions;
  },

  [VOTE_SOLUTION]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.voteSolution(action.payload);
    return response.data;
  },

  [DELETE_SOLUTION]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.deleteSolution(action.payload);
    return response.data;
  },

  [LOAD_SOLUTION_COMMENTS]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.loadSolutionComments(action.payload);

    const timeSince = (dateString) => {
      const date = new Date(dateString);
      const today = new Date();
      const timeDiff = today.getTime() - date.getTime();
      const minutesDiff = Math.round(timeDiff / (1000 * 60));

      if (minutesDiff < 60 * 24) {
        if (minutesDiff < 60) {
          return `${minutesDiff > 1 ? `${minutesDiff} minutes ago` : 'just now'}`;
        } else {
          const hoursDiff = Math.floor(minutesDiff / 60);
          return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago`;
        }
      } else if (minutesDiff < 60 * 24 * 30) {
        const daysDiff = Math.floor(minutesDiff / (60 * 24));
        return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
      } else if (minutesDiff < 60 * 24 * 365) {
        const monthsDiff = Math.floor(minutesDiff / (60 * 24 * 30));
        return `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ago`;
      } else {
        const yearsDiff = Math.floor(minutesDiff / (60 * 24 * 365));
        return `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} ago`;
      }
    }

    const mapComments = (comments) => comments?.map((comment) => ({
      text: comment.content,
      fullName: comment.displayName,
      avatarUrl: comment.displayPhoto,
      comId: comment.id,
      userId: comment.userID,
      createdAt: timeSince(comment.createdAt),
      parentId: comment.parentID,
      projectId: comment.projectID,
      rootId: comment.rootID,
      replies: comment.children ? mapComments(comment.children) : []
    }))
    const flattenedComments = (comments) => {
      const flattened = [];

      function flattenComment(comment) {
        const flatComment = { ...comment };
        if (comment.replies && comment.replies.length > 0) {
          flatComment.replies = [];
          for (const reply of comment.replies) {
            flatComment.replies.push(reply);
            if (reply.replies && reply.replies.length > 0) {
              // recursively flatten subreplies
              const subreplies = flattenComment(reply).replies;
              if (Array.isArray(subreplies)) {
                flatComment.replies.push(...subreplies);
              }
            }
          }
        }
        return flatComment;
      }

      for (const comment of comments) {
        const flatComment = flattenComment(comment);
        flattened.push(flatComment);
      }

      const remap = flattened.flatMap((el) => [el, ...(el.replies || [])])

      return flattened.map(cmt => ({ ...cmt, replies: cmt.replies?.map(rep => ({ ...rep, parentUser: '@' + remap.find(fcmt => fcmt.comId === rep.parentId).fullName })) }));
    }

    return flattenedComments(mapComments(response.data))
  },

  [SUBMIT_COMMENT]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.submitComment(action.payload);
    return response.data
  },

  [LOAD_SOLUTION_FEED]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.loadSolutionFeed();
    return response.data.filter(solution => solution.title)
  },

  [FAVORITE_SOLUTION]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.favoriteSolution(action.payload.solutionId);
    return response.data
  },

  [SHARE_SOLUTION]: async ({ api, dispatch, getState, action }) => {

    const response = await api.solutions.shareSolution(action.payload.solutionId);

    return response.data
  },

  [LOAD_FAVORITE_SOLUTIONS]: async ({ api, dispatch, getState, action }) => {
    const licenses = await dispatch(loadLicenses)
    const response = await api.solutions.loadFavoriteSolutions();
    const solutions = response.data.filter(solution => solution.title).map(solution => ({ ...solution, isFavorite: true, purchased: licenses.map(license => license.solutionId).includes(solution.id) }))

    return solutions;
  },

  [LOAD_BOUGHT_SOLUTIONS]: async ({ api, dispatch, getState, action }) => {
    const response = await api.solutions.loadBoughtSolutions();
    const solutions = response.data.filter(solution => solution.title).map(solution => ({ ...solution, purchased: true }))

    return solutions;
  }

}