import { IonContent, IonPage } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { ChevronLeft, ChevronRight, Delete } from "baseui/icon";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { DisplayLarge, HeadingXLarge } from 'baseui/typography';
import { City, Country } from 'country-state-city';
import { forwardRef, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { createCompany, loadCompany, uploadLogo } from "../../application/actions/company";
import { routeTo } from "../../application/actions/ui";
import ReDoBanner from "../../components/ReDoBanner";
import ReDoButton from "../../components/ReDoButton";
import ReDoFileUpload from "../../components/ReDoFileUpload";
import ReDoLogo from "../../components/ReDoLogo";
import ReDoPicklist from "../../components/ReDoPicklist";
import ReDoProgressBar from "../../components/ReDoProgressBar";
import ReDoSpinner from "../../components/ReDoSpinner";
import ReDoTags from "../../components/ReDoTags";
import ReDoTextarea from "../../components/RedoTextarea";


const stages = [
  { label: 'Personal', curr: 1, max: 7 },
  { label: 'Company', curr: 2, max: 7 },
  { label: 'Location', curr: 3, max: 7 },
  { label: 'Description', curr: 4, max: 7 },
  { label: 'Industry', curr: 5, max: 7 },
  { label: 'Interests', curr: 6, max: 7 },
  { label: 'Logo', curr: 7, max: 7 },
]

const industries = [
  'Fashion & Textiles',
  'Construction & Housing',
  'Electronics',
  'Packaging',
  'Waste',
  'Products & Consumables',
  'Information & Communication Education',
  'Plastics',
  'Mobility & Transport',
  'Food & Agriculture',
  'Manufacturing & Production',
  'Health Care'
]

const areas = [
  'Energy',
  'Water',
  'Waste',
  'Procurement',
  'Materials',
  'Communication',
  'Supply Chains',
  'Packaging',
  'End of Life',
  'Infrastructure',
]

const CompanyCreateProfile = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();

  const [loading, setLoading] = useState(false)

  const [stage, setStage] = useState(stages[0])
  const [payload, setPayload] = useState({})

  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [searchTerm, setSearchTerm] = useState({ country: '', city: '' })

  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    margin: '0 auto',
    gap: '3ch'
  });

  const countries = useMemo(() => {
    return Country.getAllCountries().filter(c => c.name.toLowerCase().includes(searchTerm?.country?.toLowerCase())).map(c => ({ id: c.id, label: c.name }))
  }, [searchTerm?.country])

  const cities = useMemo(() => {
    const country = Country.getAllCountries().find(c => c.name === selectedCountry)
    const cities = City.getCitiesOfCountry(country?.isoCode).filter(c => c.name.toLowerCase().includes(searchTerm?.city?.toLowerCase()))
    return cities.map(c => ({ id: c.stateCode, label: c.name }))
  }, [searchTerm?.city, selectedCountry])

  const onNext = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const stepPayload = {}

    for (const pair of data.entries()) {
      stepPayload[pair[0]] = pair[1]
    }

    const currPayload = { ...payload, ...stepPayload }
    const currStage = stages.findIndex(s => s.curr === stage.curr)

    if (currStage < stages.length - 1) {
      setStage(stages[currStage + 1])
      setPayload(currPayload)
    } else {
      setLoading(true)
      const { photo, ...profile } = currPayload
      
      dispatch(createCompany(profile))
        .then(() => {
          if (photo && photo !== 'data:') {
            dispatch(uploadLogo({ photo: photo.photo })).then(() => {
              dispatch(loadCompany()).then(() => {
                setLoading(false)
                dispatch(routeTo({ view: 'companyProfile' }))
              }).catch((e) => {
                setLoading(false)

                enqueue({ message: e.message, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
              })
            }).catch((e) => {
              setLoading(false)

              enqueue({ message: e.message, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
            })
          } else {
            dispatch(loadCompany()).then(() => {
              setLoading(false)
              dispatch(routeTo({ view: 'companyProfile' }))
            })
          }
        })
        .catch((e) => {
          setLoading(false)
          enqueue({ message: e.message + ' ' + e.response?.data?.error, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
        })
    }
  }

  const onPrev = (e) => {
    e.preventDefault();
    const currStage = stages.findIndex(s => s.curr === stage.curr)
    if (currStage > 0) {
      setStage(stages[currStage - 1])
    }
  }

  const onCountryChange = (e) => {
    setSelectedCity('')
    setSelectedCountry(e)
    setSearchTerm({ country: e, city: '' })
  }

  const onCityChange = (e) => {
    setSelectedCity(e)
    setSearchTerm({ city: e, country: '' })
  }

  const onPhotoUpload = (p) => {
    setPayload({ ...payload, photo: p })
  }

  return (
    <IonPage ref={ref} >
      <IonContent className="ion-padding">
        {loading && <ReDoSpinner />}
        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
          height='100%'
          alignItems='center'
        >
          <FlexGridItem position='relative' height='100%' display={['none', 'none', 'none', 'flex']}>
            <div className={css({ position: 'fixed', top: '50px', right: '75px' })}>
              <ReDoLogo size={theme.sizing.scale2400} />
            </div>
            <ReDoBanner>
              <div className={css({ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', padding: `${theme.sizing.scale1600} ${theme.sizing.scale3200}` })}>
                <div className={css({ paddingTop: theme.sizing.scale2400 })}>
                  <DisplayLarge>Create your ReDo Profile</DisplayLarge>
                </div>
                <Block display='flex' flexDirection='column' marginTop='auto'>
                  <ReDoProgressBar label={stage.label} value={stage.curr} maxValue={stage.max} />
                </Block>
              </div>
            </ReDoBanner>
          </FlexGridItem>

          <FlexGridItem height='100%'>
            <div className={column}>

              <Block alignSelf='flex-end' display={['unset', 'unset', 'unset', 'none']}>
                <ReDoLogo size={theme.sizing.scale1600} />
              </Block>

              <Block alignItems='center' justifyContent='space-between' margin='0 2px' display={['flex', 'flex', 'flex', 'none']}>
                <HeadingXLarge margin='0'>{stage.label}</HeadingXLarge>
                <ReDoProgressBar kind='text' label={stage.label} value={stage.curr} maxValue={stage.max} />
              </Block>

              <Block position='relative' marginTop={['0', '0', '0', '130px']} height={['auto', 'auto', 'auto', 'calc(100% - 130px)']}>
                <Block overrides={{ Block: { style: { zIndex: '1' } } }} display={['none', 'none', 'none', 'block']} position='absolute' top='0px' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgb(243,242,238) 0%, rgba(255, 255, 255, 0) 100%)' />

                <form className={css({ paddingTop: '25px', height: '100%', overflow: 'auto' })} onSubmit={onNext}>
                  <div className={css({ maxWidth: '375px', margin: 'auto', paddingBottom: '28px', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%' })}>

                    {stage.curr === 1 &&
                      <>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTextarea
                              label='What is your full name?'
                              placeholder="Your full name"
                              name="fullname"
                              required
                              value={payload.fullname}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTags
                              onChange={(e) => setPayload({ ...payload, pronouns: e })}
                              label='What are your pronouns?'
                              options={['He/Him', 'She/Her', 'They/Them']}
                              limit={1}
                              additionLabel='Add your pronoun'
                              values={payload.pronouns}
                            />
                          </FormControl>
                          <a className={css({ fontWeight: 'normal', fontSize: '10px', color: theme.colors.secondary200 })} href="./">What are pronouns?</a>
                        </Block>
                      </>
                    }

                    {stage.curr === 2 &&
                      <>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTextarea
                              label='Which company are you representing?'
                              placeholder="Type here"
                              name="name"
                              required
                              value={payload.name}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTextarea
                              label='What is your position?'
                              placeholder="Type here"
                              name='position'
                              value={payload.position}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 3 &&
                      <>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoPicklist
                              options={countries}
                              label='Where is your company located? 
                              Please select below.'
                              placeholder='Select country'
                              name='country'
                              required
                              value={selectedCountry}
                              onChange={onCountryChange}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop='0'>
                          <FormControl>
                            <ReDoPicklist
                              options={cities}
                              placeholder='Select city'
                              name='city'
                              required
                              value={selectedCity}
                              onChange={onCityChange}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTextarea
                              label='Please link the company website below.'
                              placeholder="Type here"
                              name='website'
                              value={'https://' + (!payload.website ? '' : payload.website)}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 4 &&
                      <>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTextarea
                              label='Please write a short company description.'
                              placeholder='Type here'
                              maxLength={250}
                              name='description'
                              value={payload.description}
                              optionalLabel='(optional)'
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 5 &&
                      <>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTags
                              onChange={(e) => setPayload({ ...payload, industries: e })}
                              label='Please declare which industries (your 
                                company) is active in.'
                              options={industries}
                              limit={1}
                              additionLabel='Add industry'
                              values={payload.industries}
                              optionalLabel='(optional)'
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 6 &&
                      <>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoTags
                              onChange={(e) => setPayload({ ...payload, areas: e })}
                              label='Which areas are you interested in? 
                              What would you like to learn more 
                              about? Select up to 4 areas or 
                              write your own.'
                              options={areas}
                              limit={1}
                              submitUpTo={4}
                              additionLabel='Add interest'
                              values={payload.areas}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 7 &&
                      <>
                        <Block width='100%' marginTop={['auto', 'auto', 'auto', '5%']}>
                          <FormControl>
                            <ReDoFileUpload
                              label='Please upload the logo of your company.'
                              maxCount={1}
                              avatar
                              optionalLabel='(optional)'
                              onUpload={onPhotoUpload}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    <Block paddingBottom={['0', '0', '0', '50px']} justifyContent='space-between' display='flex' width='100%' marginTop='5%'>
                      {stage.curr !== 1 &&
                        <ReDoButton
                          type='button'
                          onClick={onPrev}
                          kind='inverse'
                          overrides={{
                            BaseButton: {
                              style: {
                                marginTop: '0',
                                marginBottom: '0',
                                paddingLeft: '0 !important',
                                paddingTop: '0 !important',
                                paddingBottom: '0 !important',
                                backgroundColor: 'transparent',
                                color: theme.colors.black,
                                width: 'auto',
                                fontWeight: 'bold'
                              }
                            },
                            StartEnhancer: {
                              style: {
                                marginRight: '0'
                              }
                            }
                          }}
                          startEnhancer={() => <ChevronLeft size={theme.sizing.scale1000} />}
                        >
                          Back
                        </ReDoButton>
                      }

                      <ReDoButton
                        type='submit'
                        kind='brand'
                        overrides={{
                          BaseButton: {
                            style: {
                              marginBottom: '0',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              paddingRight: '0',
                              width: 'auto',
                              marginLeft: 'auto'
                            }
                          },
                          EndEnhancer: {
                            style: {
                              marginLeft: '0'
                            }
                          }
                        }}
                        endEnhancer={() => <ChevronRight size={theme.sizing.scale1000} />}
                      >
                        {stage.curr !== 7 ? 'Next' : 'Submit'}
                      </ReDoButton>

                    </Block>

                  </div>
                </form>

                <Block display={['none', 'none', 'none', 'block']} position='absolute' bottom='0' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(243,242,238) 100%)' />

              </Block>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </IonContent>
    </IonPage>
  );
});

export default CompanyCreateProfile;