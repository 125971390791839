import axios from 'axios';
import { baseUrl, withAuth } from '../auth/helper';


export default {
  getCompanyChallenges: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/challenge/company`, options)
    return response
  }),
  getChallengesForSolution: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/challenge?solution=${params}`, options)
    return response
  }),
  submitChallenge: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/challenge`, params, options)
    return response
  }),
  uploadChallengePhotos: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/challenge/photo`, params, options)
    return response
  }),
  getChallengeFeed: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/challenge/feed`, options)
    return response
  }),
  getChallengeById: async (params) => {
    const response = await axios.get(`${baseUrl}/project/challenge/${params}`)
    return response
  },
  deleteChallenge: async (params) => {
    const response = await axios.delete(`${baseUrl}/project/challenge/${params}`)
    return response
  },
  getChallengeComments: async (params) => {
    const response = await axios.get(`${baseUrl}/project/challenge/${params}/comments`)
    return response
  },
  submitComment: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/challenge/comments`, params, options)
    return response
  }),
  favoriteChallenge: withAuth(async (params, options) => {
    const response = await axios.put(`${baseUrl}/project/challenge/${params}/favorite`, params, options)
    return response
  }),
  shareChallenge: withAuth(async (params, options) => {
    const response = await axios.put(`${baseUrl}/project/challenge/${params}/share`, params, options)
    return response
  }),
  getFavoriteChallenges: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/challenge/favorite`, options)
    return response
  })
}