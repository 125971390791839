import { Auth } from 'aws-amplify';


export default {
  signIn: async ({ username, password }) => {
    const user = await Auth.signIn(username, password);
    return user
  },
  signOut: async () => {
    await Auth.signOut();
  },
  signUp: async (pl) => {
    const user = await Auth.signUp(pl);
    return user
  },
  forgotPassword: async ({ username }) => {
    const user = await Auth.forgotPassword(username);
    return user
  },
  resetPassword: async ({ username, code, password }) => {
    const user = await Auth.forgotPasswordSubmit(username, code, password);
    return user
  },
  completeNewPassword: async (user, password) => {
    const res = await Auth.completeNewPassword(user, password)
    return res;
  },
  federatedSignIn: async ({ config, provider }) => {
    //erste war das original, auskommentiert für Testzwecke
    const target = `https://${config.oauth.domain}/oauth2/authorize?identity_provider=${provider}&redirect_uri=${config.oauth.redirectSignIn}&response_type=${config.oauth.responseType}&client_id=${config.userPoolWebClientId}&scope=${config.oauth.scope.join(' ')}`
    //const target = `https://redo-please.auth.eu-central-1.amazoncognito.com/login?client_id=7to2o57316mlu5qmuuqcnvh49o&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile+users%2Fcompany+users%2Fprofile&redirect_uri=https%3A%2F%2Fapp.redo-please.com%2Fuser%2F`
    window.location.replace(target)
  }
}