export const LOAD_INBOXES = '[inbox] load inboxes';
export const SUBMIT_INBOX = '[inbox] submit inbox';
export const LOAD_INBOXES_SUCCESS = '[inbox] load inboxes success';


export const loadInboxes = {
  type: LOAD_INBOXES,
}

export const submitInbox = (inbox) => ({
  type: SUBMIT_INBOX,
  payload: inbox
})

export const loadInboxesSuccess = (inbox) => ({
  type: LOAD_INBOXES_SUCCESS,
  payload: inbox
})