import { Block } from 'baseui/block';
import { PLACEMENT, StatefulTooltip } from 'baseui/tooltip';
import { useSelector } from 'react-redux';
import { getNotifications } from '../application/selectors/notifications';
import ReDoNotifications from './ReDoNotifications';



const ReDoNotificationsHover = ({ item, ...props }) => {

  const notifications = useSelector(getNotifications);

  return (
    <>
      <StatefulTooltip
        placement={PLACEMENT.bottomLeft}
        overrides={{
          Arrow: {
            style: {
              backgroundColor: 'white',
            }
          },
          Body: {
            style: {
              backgroundColor: 'white',
            }
          },
          Inner: {
            style: {
              backgroundColor: 'white',
              color: 'black',
              padding: '0',
            }
          }
        }}
        showArrow
        content={() => (
          <Block padding='1ch 2ch' maxHeight='600px' overflow='auto'>
            {notifications.length > 0 ?
              <ReDoNotifications noHeading onChatClick={() => { }} />
              :
              <Block padding='1ch 2ch'>
                <Block display='flex' justifyContent='space-between' alignItems='center'>
                  <Block>
                    <Block as='p' font='font300' color='mono1000'>You have no notifications</Block>
                  </Block>
                </Block>
              </Block>
            }
          </Block>
        )}
        returnFocus
        autoFocus
      >
        {item.label}
      </StatefulTooltip>
    </>
  );
};

export default ReDoNotificationsHover;