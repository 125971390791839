import { createAnimation } from '@ionic/react';
import { useEffect, useRef, createElement } from 'react';


const useNavigator = ({ views, currentView, setView, setTab }) => {

  const animationRef = useRef();

  const Screen = currentView.view ? createElement(views[currentView.view], { ...(currentView?.payload || {}), ref: animationRef }) : '';

  useEffect(() => {
    currentView?.tab && setTab(currentView.tab)
    const handlePopstate = (event) => {

      const view = event?.state?.view
      view && setView({ view, payload: event?.state?.payload })
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [setView, setTab, currentView?.tab, currentView?.payload]);

  useEffect(() => {
    const animation = createAnimation()
      .addElement(animationRef.current)
      .duration(600)
      .fromTo('opacity', '0.2', '1')
      .fromTo('transform', 'scale(80%)', 'scale(100%)')
      .easing('cubic-bezier(.66,.28,0,.64)');

    animation.play();
  }, [currentView.view]);


  return Screen;
};

export default useNavigator;
