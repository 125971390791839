import { useDispatch, useSelector } from 'react-redux'
import { routeTo } from "../application/actions/ui";
import { Block } from "baseui/block";
import { LabelSmall, HeadingLarge } from "baseui/typography";
import ReDoMenuCard from "./ReDoMenuCard";
import { useEffect, useState } from "react";
import { loadInboxes, submitInbox } from "../application/actions/inbox";
import { loadNotifications } from '../application/actions/notifications';
import { loadSolutionById } from '../application/actions/solutions';
import { ChevronDown } from 'baseui/icon';
import { ANCHOR, Drawer, SIZE } from 'baseui/drawer';
import { useStyletron } from 'baseui';
import { getNotifications } from '../application/selectors/notifications';
import { getInbox } from '../application/selectors/inbox';


const ReDoNotifications = ({ noHeading, onChatClick }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);
  const inboxes = useSelector(getInbox);
  const [, theme] = useStyletron();
  const [isOpen, setIsOpen] = useState({});

  const isWeb = !!onChatClick

  const onInboxClick = (ev, e) => {
    ev.stopPropagation();
    
    dispatch(submitInbox({ recipient: e.recipientID, project: e.projectID, projectType: e.projectType })).then(r => {
      
      onChatClick ? onChatClick({ item: r }) : dispatch(routeTo({ view: 'chat', payload: { item: r } }))
    })
  }

  const onNotificationClick = (ev, e) => {
    ev.stopPropagation();
    
    if (e.data.projectType === 'solution') {
      dispatch(loadSolutionById(e.data.projectId)).then((solution) => {
        dispatch(routeTo({ view: 'solution', payload: { solution } }));
      })
    }
  }

  useEffect(() => {
    dispatch(loadInboxes)
    dispatch(loadNotifications)
  }, [dispatch])


  return (
    <>
      {!isWeb &&
        <Drawer
          isOpen={!!isOpen?.elements?.length}
          autoFocus
          onClose={() => setIsOpen({})}
          anchor={ANCHOR.bottom}
          size={SIZE.default}
          overrides={{
            DrawerContainer: {
              style: {
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                height: 'calc(100vh - 190px)'
              }
            },
            DrawerBody: {
              style: {
                margin: '0 !important',
                padding: '0',
                overflow: 'hidden'
              }
            },
            Close: {
              style: {
                color: theme.colors.primary300,
                width: '48px',
                height: '48px',
              }
            }
          }}
        >
          <Block height='100%' padding='0 1ch 7ch 1ch' overflow='auto' marginTop='7ch'>
            <HeadingLarge padding='0 12px 12px 12px'>All {isOpen.kind}</HeadingLarge>
            {!!isOpen?.elements?.length && <ReDoMenuCard onClick={isOpen.kind === 'Notifications' ? onNotificationClick : onInboxClick} options={isOpen.elements} />}
          </Block>
        </Drawer>
      }
      <Block display='flex' gridGap='2ch' flexDirection='column' height='100%'>
        <Block>
          {!noHeading && <Block display='flex' justifyContent='space-between' alignItems='center'>
            <HeadingLarge>Notifications</HeadingLarge>
            {!isWeb && <Block onClick={() => setIsOpen({ kind: 'Notifications', elements: notifications })} alignItems='center' display='flex'>
              <LabelSmall overrides={{ Block: { style: { fontWeight: '600' } } }}>See all</LabelSmall>
              <ChevronDown size={24} />
            </Block>}
          </Block>}
          <Block>
            <ReDoMenuCard onClick={onNotificationClick} options={isWeb ? notifications : notifications.slice(0, 3)} />
          </Block>
        </Block>

        {!noHeading &&
          <Block>
            <Block display='flex' justifyContent='space-between' alignItems='center'>
              <HeadingLarge>Messages</HeadingLarge>
              {!isWeb && <Block onClick={() => setIsOpen({ kind: 'Messages', elements: inboxes })} alignItems='center' display='flex'>
                <LabelSmall overrides={{ Block: { style: { fontWeight: '600' } } }}>See all</LabelSmall>
                <ChevronDown size={24} />
              </Block>}
            </Block>
            <Block>
              <ReDoMenuCard onClick={onInboxClick} options={isWeb ? inboxes : inboxes.slice(0, 3)} />
            </Block>
          </Block>
        }
      </Block>
    </>
  );
}

export default ReDoNotifications