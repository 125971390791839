import { LabelXSmall, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { FileUploader } from 'baseui/file-uploader';
import { Check, Delete, Upload } from 'baseui/icon';
import { useState, useCallback } from 'react';
import { useStyletron } from 'baseui';
import { Button, SHAPE } from 'baseui/button';
import { Block } from 'baseui/block';
import getCroppedImg from './cropImage'
import Cropper from 'react-easy-crop'
import { Slider } from "baseui/slider";
import { Avatar } from 'baseui/avatar';
import { DURATION, useSnackbar } from 'baseui/snackbar';


const blobToDataUrl = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
  reader.readAsDataURL(blob);
});

const blobToBase64 = blob => blobToDataUrl(blob).then(text => text.split(',').pop())

const ReDoFileUpload = ({ optionalLabel, values, label, avatar, name, multiple, maxCount, onUpload, onRemove, ...props }) => {
  const [css, theme] = useStyletron();
  const [file, setFile] = useState(values || []);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedImageTemp, setCroppedImageTemp] = useState(null)
  const { enqueue } = useSnackbar();

  const onDropRejected = (rejectedFiles) => {
    enqueue({ message: 'File size is too large', dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
  };

  const fileToBlob = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result;
        const blob = new Blob([arrayBuffer], { type: file.type });
        resolve(blob);
      };

      reader.onerror = () => {
        reject(new Error('Error reading file.'));
      };

      reader.readAsArrayBuffer(file);
    });
  }

  const onDrop = async (acceptedFiles) => {
    const newFiles = acceptedFiles.slice(0, maxCount - file.length).map((f) => {
      return {
        file: f,
        preview: f.preview || URL.createObjectURL(f),
      };
    });

    const blobPromises = newFiles.map(async (f) => f.file ? ({
      file: f.file,
      blob: await fileToBlob(f.file),
    }) : f);


    const blobs = await Promise.all(blobPromises);

    const promises = [];
    const b64 = [];

    for (const file of blobs) {
      const promise = file.blob ? blobToBase64(file.blob).then(base64 => {
        b64.push({ file, photo: base64 });
      }) : b64.push(file);
      promises.push(promise);
    }

    Promise.all(promises).then(() => {
      if (multiple) {
        onUpload && onUpload([...file, ...b64]);
      } else {
        onUpload && onUpload(b64[0]);
      }
    });



    setFile((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const onRearrange = async (e) => {
    e.preventDefault();
    const sourceIndex = parseInt(e.dataTransfer.getData('text/plain'));
    const targetIndex = parseInt(e.target.getAttribute('data-index'));

    if (sourceIndex !== targetIndex) {
      const newFile = [...file];
      const [removed] = newFile.splice(sourceIndex, 1);
      newFile.splice(targetIndex, 0, removed);

      const blobPromises = newFile.map(async (f) => f.file ? ({
        file: f.file,
        blob: await fileToBlob(f.file),
      }) : f);

      try {
        const blobs = await Promise.all(blobPromises);

        const promises = [];
        const b64 = [];

        for (const file of blobs) {
          const promise = file.blob ? blobToBase64(file.blob).then(base64 => {
            b64.push({ file, photo: base64 });
          }) : b64.push(file);
          promises.push(promise);
        }

        Promise.all(promises).then(() => {
          if (multiple) {
            onUpload && onUpload(b64);
          } else {
            onUpload && onUpload(b64[0]);
          }
        });

      } catch (error) {
        console.error(error);
      }

      setFile(newFile);
    }
  }

  const removeImg = (e, f) => {
    e.stopPropagation();
    setFile(fi => {
      onRemove && onRemove(fi.filter(i => i !== f));
      return fi.filter(i => i !== f)
    });
    setCroppedImage(null);
    setCroppedImageTemp(null)
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const cropImage = useCallback(async (e, f) => {
    try {
      const image = croppedImageTemp?.file?.preview || f.preview

      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      setCroppedImageTemp({ file: f, blob: croppedImage.blob })

      const base64 = await blobToBase64(croppedImage.blob)

      onUpload && onUpload({ file: f, photo: base64 });
    } catch (e) {
      console.error(e)
    }
  }, [croppedImageTemp?.file?.preview, croppedAreaPixels, rotation, onUpload])

  const showCroppedImage = useCallback(async (e, f) => {
    try {
      const croppedImage = await getCroppedImg(
        f.preview,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage.url)
      const base64 = await blobToBase64(croppedImage.blob)
      onUpload && onUpload({ file: f, photo: base64 });
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, onUpload])

  return (

    <div>

      <ParagraphMedium marginTop='0' paddingLeft='5px'>{label} <span className={css({ color: theme.colors.primary200 })}>{optionalLabel}</span></ParagraphMedium>

      {(!file.length || (maxCount && !!file.length !== maxCount)) &&

        <FileUploader disabled={(!!file.length && !multiple) || (maxCount && file.length >= maxCount)}
          overrides={{
            FileDragAndDrop: {
              style: ({ $theme }) => ({
                backgroundColor: 'transparent',
                borderColor: $theme.colors.black
              })
            },
            ContentMessage: {
              component: () => <ParagraphSmall marginTop='0' marginBottom='2ch'>Drop or browse to upload (max size 5MB)</ParagraphSmall>
            },
            ButtonComponent: {
              props: {
                endEnhancer: <Upload size={24} />,
                overrides: {
                  BaseButton: {
                    style: ({ $theme }) => ({
                      border: `1px ${$theme.colors.black} solid`,
                      marginTop: '4ch',
                      padding: '1ch 2ch !important',
                      backgroundColor: 'transparent',
                    })
                  }
                }
              }
            }
          }
          }
          onDropRejected={onDropRejected}
          maxSize={5000000}
          onDrop={onDrop}
          name={name}
          multiple={multiple}
        />

      }

      {!!file.length &&
        <>
          <div
            className={css({
              padding: '2px',
              display: 'flex',
              gap: '1ch',
              marginTop: '1.5ch',
              width: '100%',
              justifyContent: avatar ? 'center' : 'flex-start',
            })}
            onDrop={(e) => onRearrange(e)}
            onDragOver={(e) => {
              e.preventDefault();
              e.dataTransfer.dropEffect = 'move';
            }}
          >
            {file.filter(f => f).map((f, i) =>
              avatar ?

                <Block width='100%' key={i} display='flex' flexDirection='column' gridGap='3ch'>
                  <Block height={!croppedImage ? '400px' : 'auto'} width='100%' position='relative'>
                    {!croppedImage ?
                      <Cropper
                        image={f.preview}
                        crop={crop}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        zoom={zoom}
                        rotation={rotation}
                        cropShape='round'
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      /> :
                      <Avatar
                        overrides={{
                          Avatar: {
                            style: ({ $theme }) => ({
                              height: 'auto',
                            })
                          }
                        }}
                        size="400px"
                        src={croppedImage}
                      />
                    }
                    <Block position='absolute' top='5px' right='5px'>
                      <Button type='button' onClick={(e) => removeImg(e, f)} shape={SHAPE.circle} overrides={{ BaseButton: { style: { height: 'auto', width: 'auto', padding: '0' } } }}>
                        <Delete size={28} />
                      </Button>
                    </Block>
                    {!croppedImage &&
                      <Block position='absolute' top='5px' right='36px'>
                        <Button type='button' onClick={(e) => showCroppedImage(e, f)} shape={SHAPE.circle} overrides={{ BaseButton: { style: { height: 'auto', width: 'auto', padding: '0' } } }}>
                          <Check size={28} />
                        </Button>
                      </Block>
                    }
                  </Block>
                  {!croppedImage &&
                    <Block>
                      <Block display='flex' alignItems='center' gridGap='1ch' marginBottom='1ch'>
                        <LabelXSmall color={theme.colors.primary200} overrides={{ Block: { style: { width: '58px', flexShrink: '0' } } }}>ZOOM</LabelXSmall>
                        <Slider
                          value={[zoom]}
                          min={1}
                          max={3}
                          step={0.1}
                          onChange={({ value }) => value && setZoom(value)}
                          overrides={{ TickBar: { style: { display: 'none' } }, Thumb: { style: { backgroundColor: theme.colors.primary200 } } }}
                          onFinalChange={(e) => cropImage(e, f)}
                        />
                      </Block>
                      <Block display='flex' alignItems='center' gridGap='1ch'>
                        <LabelXSmall color={theme.colors.primary200} overrides={{ Block: { style: { width: '58px', flexShrink: '0' } } }}>ROTATION</LabelXSmall>
                        <Slider
                          value={[rotation]}
                          min={0}
                          max={360}
                          step={1}
                          onChange={({ value }) => value && setRotation(value)}
                          overrides={{ TickBar: { style: { display: 'none' } }, Thumb: { style: { backgroundColor: theme.colors.primary200 } } }}
                          onFinalChange={(e) => cropImage(e, f)}
                        />
                      </Block>
                    </Block>
                  }
                </Block>

                :
                <div
                  key={i}
                  className={css({
                    padding: '2px',
                    display: 'flex',
                    gap: '1ch',
                    width: `100%`,
                    position: 'relative',
                    maxWidth: file.length === 1 ? '50%' : 'none'
                  })}
                >
                  <Block position='absolute' top='.5ch' right='.5ch'><Button type='button' onClick={(e) => removeImg(e, f)} shape={SHAPE.circle} overrides={{ BaseButton: { style: { height: 'auto', width: 'auto', padding: '0' } } }}><Delete size={28} /></Button></Block>
                  <img
                    data-index={i}
                    height='100px'
                    draggable
                    onDragStart={(e) => {
                      e.dataTransfer.setData('text/plain', i);
                    }}
                    className={css({
                      flexGrow: '1',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      padding: '2px',
                      outline: `2px solid ${theme.colors.primary200}`,
                    })}
                    key={i}
                    src={f.preview}
                    alt={file.name} />
                </div>
            )}
          </div>
          {file.length > 1 && <LabelXSmall color={theme.colors.primary200} paddingLeft='2px' paddingTop='2px'>{`<-->`} Drag to change order</LabelXSmall>}
        </>
      }


    </div>
  );
};

export default ReDoFileUpload;