import { CREATE_PROFILE, loadProfileSuccess, LOAD_PROFILE, LOAD_PROFILE_BY_ID, UPLOAD_AVATAR } from "../actions/users";
import { loadWallet } from "../actions/wallet";



export const users = {

  [LOAD_PROFILE]: async ({ api, dispatch, getState, action }) => {
    const response = await api.users.getProfile();
    dispatch(loadProfileSuccess(response.data));
    dispatch(loadWallet)
    return response.data
  },

  [CREATE_PROFILE]: async ({ api, dispatch, getState, action }) => {
    await api.users.createProfile(action.payload);
    
  },

  [UPLOAD_AVATAR]: async ({ api, dispatch, getState, action }) => {
    await api.users.uploadAvatar(action.payload);
    
  },

  [LOAD_PROFILE_BY_ID]: async ({ api, dispatch, getState, action }) => {
    const response = await api.users.getProfileById(action.payload);
    return response.data;
  },

}