import { LabelXSmall, ParagraphMedium } from 'baseui/typography';
import { StatefulTextarea } from 'baseui/textarea';
import { useState } from 'react';
import { useStyletron } from 'baseui';


const ReDoTextarea = ({ link, value, optionalLabel, placeholder, label, name, maxLength, shadow, required, ...props }) => {
  const [css, theme] = useStyletron();
  const [charCount, setCharcount] = useState(0)

  const calc = (e) => {
    setCharcount(e.target.value.length);
  }

  const getHeight = () => {
    if (link) {
      return '57px';
    }
    if (maxLength < 21 || !maxLength) {
      return '57px';
    } else if (maxLength > 20 && maxLength < 66) {
      return theme.sizing.scale2400;
    } else if (maxLength > 65 && maxLength < 250) {
      return theme.sizing.scale3200;
    } else if (maxLength > 249) {
      return theme.sizing.scale4800;
    }
  }

  return (
    <div className={css({ position: 'relative' })}>
      <ParagraphMedium marginTop='0' paddingLeft='5px'>{label} <span className={css({ color: theme.colors.primary200 })}>{optionalLabel}</span></ParagraphMedium>
      <StatefulTextarea
        onChange={calc}
        initialState={{ value: value }}
        placeholder={placeholder}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              border: '0 !important',
              boxShadow: !shadow ? 'none' : `0 0 15px 0px rgba(94, 94, 91, 20%)`,
            })
          },
          Input: {
            style: ({ $theme }) => ({
              height: getHeight(),
              padding: '1.5ch',
            })
          }
        }}
        name={name}
        maxLength={maxLength}
        required={required}
        {...props}
      />
      {maxLength && <LabelXSmall overrides={{ Block: { style: { textAlign: 'end' } } }} paddingRight='5px' paddingTop='5px' color={theme.colors.primary200}>{charCount}/{maxLength}</LabelXSmall>}
    </div>
  );
};

export default ReDoTextarea;