import { combineReducers } from 'redux';
import ui from './ui';
import users from './users';
import solutions from './solutions';
import company from './company';
import challenges from './challenges';
import licenses from './licenses';
import notifications from './notifications';
import inbox from './inbox';
import wallet from './wallet';

export default combineReducers({
    ui,
    users,
    solutions,
    company,
    challenges,
    licenses,
    notifications,
    inbox,
    wallet
})