import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { HeadingLarge, HeadingXLarge } from 'baseui/typography';
import ReDoBanner from './ReDoBanner';
import ReDoLogo from './ReDoLogo';


const ReDoSplitView = ({ cover, title, titleBottomEnhancer, titleEndEnhancer, leftSection, bottomSection, mainSection }) => {
  const [css, theme] = useStyletron();

  return (
    <FlexGrid
      flexGridColumnCount={[1, 1, 1, 2]}
      flexGridColumnGap="scale800"
      flexGridRowGap="scale800"
      height='100%'
      alignItems='center'
    >
      <FlexGridItem position='relative' height='100%' display={['none', 'none', 'none', 'flex']}>
        <div className={css({ position: 'fixed', top: '30px', right: '75px' })}>
          <ReDoLogo size={theme.sizing.scale2400} />
        </div>
        <ReDoBanner>
          <div className={css({ padding: `118px ${theme.sizing.scale900} ${theme.sizing.scale900} ${theme.sizing.scale900}`, height: '100%', display: 'flex', flexDirection: 'column' })}>
            <Block marginBottom='1ch' alignItems='center' display='flex' justifyContent='space-between'>
              <HeadingXLarge overrides={{ Block: { style: { margin: '0 .5ch 0 0' } } }}>{title}</HeadingXLarge>
              {titleEndEnhancer}
            </Block>
            {titleBottomEnhancer}
            <Block marginTop='4ch'>{cover}</Block>
            {leftSection}
          </div>
        </ReDoBanner>
      </FlexGridItem>

      <FlexGridItem height='100%'>
        <Block position='relative' display='flex' flexDirection='column' width='100%' height='100%' margin='0 auto' gridGap='3ch' maxWidth={['370px', '370px', '370px', 'none']}>

          <Block overrides={{ Block: { style: { zIndex: '1' } } }} display={['none', 'none', 'none', 'block']} position='absolute' top='100px' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgb(243,242,238) 0%, rgba(255, 255, 255, 0) 100%)' />

          <Block padding={['unset', 'unset', 'unset', '40px 1ch']} overflow={['unset', 'unset', 'unset', 'auto']} display='flex' flexDirection='column' gridGap='4ch' marginTop={[0, 0, 0, '100px']} height={['100%', '100%', '100%', 'calc(100% - 100px)']}>

            <Block alignItems='center' display={['flex', 'flex', 'flex', 'none']} justifyContent='flex-end'>
              {titleEndEnhancer}
            </Block>

            <Block display={['block', 'block', 'block', 'none']}>
              {cover}
              <HeadingLarge overrides={{ Block: { style: { margin: '1ch 0 5px 0' } } }}>{title}</HeadingLarge>
              {titleBottomEnhancer}
            </Block>

            {mainSection}

            <Block display={['block', 'block', 'block', 'none']} paddingBottom='28px'>
              {bottomSection}
            </Block>

          </Block>

          <Block overrides={{ Block: { style: { zIndex: '1' } } }} display={['none', 'none', 'none', 'block']} position='absolute' bottom='0' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(243,242,238) 100%)' />


        </Block>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default ReDoSplitView