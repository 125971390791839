import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { Block } from 'baseui/block';
import { HeadingLarge, LabelXSmall, ParagraphLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { chatbubble, helpCircle, informationCircle, lockClosedSharp, refreshSharp, settingsSharp, wallet } from 'ionicons/icons';
import { useState } from 'react';
import { MdCookie } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../application/actions/auth';
import { routeTo, setPortalElement } from '../application/actions/ui';
import { getWallet } from '../application/selectors/wallet';
import useCognito from '../hooks/useCognito';
import useWhoAmI from '../hooks/useWhoAmI';
import ReDoBackButton from './ReDoBackButton';
import ReDoButton from './ReDoButton';



const ReDoSettings = ({ defaultItem }) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const [walletVisible, setWalletVisible] = useState(defaultItem || false)
  const [profileSettingsVisible, setProfileSettingsVisible] = useState(false)

  const walletData = useSelector(getWallet)

  const { isCompany, userProfile } = useWhoAmI();
  const { user } = useCognito()

  const onWalletClick = () => {
    setWalletVisible(true)
  }

  const onProfileClick = () => {
    !isCompany && dispatch(setPortalElement({ element: 'profileContainer', payload: { user: userProfile } }))
  }

  const onProfileSettingsClick = () => {
    setProfileSettingsVisible(true)
  }

  const onForgotPassword = () => {
    dispatch(routeTo({ view: 'forgotPassword', payload: { settings: true } }))
  }

  return (
    <>
      {!walletVisible && !profileSettingsVisible ? (
        <>
          <IonList inset className={css({ margin: '1ch 0 !important' })}>

            <IonItem onClick={onProfileClick} lines='none' button={!isCompany} className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <Block alignItems='center' display='flex' margin='1ch 0' gridGap='2ch'>
                <Avatar name={userProfile.fullname} size="64px" src={userProfile.photo} />
                <Block display='flex' flexDirection='column'>
                  <ParagraphMedium marginTop='0' marginBottom='0' overrides={{ Block: { style: { fontWeight: 'bold' } } }}>
                    {userProfile.fullname}
                  </ParagraphMedium>
                  <ParagraphMedium overrides={{ Block: { style: { fontWeight: '500' } } }} color={theme.colors.primary400} marginTop='0' marginBottom='0'>My profile</ParagraphMedium>
                </Block>
              </Block>
            </IonItem>

            <Block backgroundColor='white' display='flex' justifyContent='center'>
              <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
            </Block>

            <IonItem lines='none' onClick={onProfileSettingsClick} button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <IonIcon className={css({ color: theme.colors.secondary200 })} icon={settingsSharp} slot="start"></IonIcon>
              <IonLabel className={css({ fontWeight: '500' })}>Profile Settings</IonLabel>
            </IonItem>

          </IonList>

          <IonList inset className={css({ margin: '1ch 0 !important' })}>

            <IonItem onClick={onWalletClick} lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <IonIcon className={css({ color: theme.colors.secondary200 })} icon={wallet} slot="start"></IonIcon>
              <IonLabel className={css({ fontWeight: '500' })}>Wallet</IonLabel>
            </IonItem>

          </IonList>

          <IonList inset className={css({ margin: '1ch 0 !important' })}>

            <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <IonIcon className={css({ color: theme.colors.secondary200 })} icon={informationCircle} slot="start"></IonIcon>
              <IonLabel className={css({ fontWeight: '500' })}>About ReDo</IonLabel>
            </IonItem>
            <Block backgroundColor='white' display='flex' justifyContent='center'>
              <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
            </Block>
            <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <IonIcon className={css({ color: theme.colors.secondary200 })} icon={refreshSharp} slot="start"></IonIcon>
              <IonLabel className={css({ fontWeight: '500' })}>Sustainability</IonLabel>
            </IonItem>

          </IonList>

          <IonList inset className={css({ margin: '1ch 0 !important' })}>

            <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <MdCookie color={theme.colors.secondary200} size='1.6em' slot='start' />
              <IonLabel className={css({ fontWeight: '500' })}>Cookies</IonLabel>
            </IonItem>
            <Block backgroundColor='white' display='flex' justifyContent='center'>
              <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
            </Block>
            <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <IonIcon className={css({ color: theme.colors.secondary200 })} icon={lockClosedSharp} slot="start"></IonIcon>
              <IonLabel className={css({ fontWeight: '500' })}>Terms and conditions</IonLabel>
            </IonItem>
            <Block backgroundColor='white' display='flex' justifyContent='center'>
              <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
            </Block>
            <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <IonIcon className={css({ color: theme.colors.secondary200 })} icon={helpCircle} slot="start"></IonIcon>
              <IonLabel className={css({ fontWeight: '500' })}>Help-center</IonLabel>
            </IonItem>
            <Block backgroundColor='white' display='flex' justifyContent='center'>
              <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
            </Block>
            <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
              <IonIcon className={css({ color: theme.colors.secondary200 })} icon={chatbubble} slot="start"></IonIcon>
              <IonLabel className={css({ fontWeight: '500' })}>Send us your feedback</IonLabel>
            </IonItem>

          </IonList>

          <Block display='flex' justifyContent='center' marginTop='3ch'>
            <ReDoButton kind='link' onClick={() => dispatch(signOut)}>Sign out</ReDoButton>
          </Block>
        </>
      ) : (

        <>
          {walletVisible &&
            <>
              <ReDoBackButton onClick={() => setWalletVisible()} />
              {isCompany ?
                <>
                  <Block marginBottom='4ch'>
                    <ParagraphLarge>My Subscription</ParagraphLarge>
                    <IonList inset className={css({ margin: '1ch 0 !important' })}>
                      <IonItem lines='none' className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                        <Block margin='1ch 0' paddingRight='1ch' width='100%' display='flex' justifyContent='space-between'>
                          <Block width='75%'>
                            <ParagraphMedium overrides={{ Block: { style: { fontWeight: 'bold' } } }}>{walletData.subscription?.subscriptionName} ({walletData.subscription?.price}/month)</ParagraphMedium>
                            <ParagraphSmall color={theme.colors.primary400} marginBottom='0'>You have {walletData.balance} coins</ParagraphSmall>
                            <ParagraphSmall color={theme.colors.primary400} marginTop='0'>New coins: {new Date(walletData.subscription?.nextPayment).toLocaleDateString()}</ParagraphSmall>
                          </Block>

                          <Block paddingLeft='2ch' overrides={{ Block: { style: { borderLeft: `1px solid ${theme.colors.secondary0}` } } }} width='25%'>
                            <ParagraphMedium overrides={{ Block: { style: { fontWeight: 'bold' } } }}>You get</ParagraphMedium>
                            <ParagraphSmall color={theme.colors.primary400} marginBottom='0'>{walletData.subscription?.amountOfCoins} per month</ParagraphSmall>
                            <ParagraphSmall color={theme.colors.primary400} marginTop='0'>{walletData.subscription?.amountOfChallenges} challenges</ParagraphSmall>
                          </Block>
                        </Block>
                      </IonItem>
                    </IonList>
                  </Block>

                  <IonList inset className={css({ margin: '1ch 0 !important' })}>
                    <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                      <a className={css({ fontWeight: '500', textDecoration: 'none' })} target='_blank' rel='noreferrer' href="https://billing.stripe.com/p/login/test_5kA7thai2a0W1Bm9AA">Manage Wallet</a>
                    </IonItem>
                  </IonList>
                </>
                :
                <IonList inset className={css({ margin: '1ch 0 !important' })}>
                  <IonItem lines='none' className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                    <ParagraphSmall>You have {walletData.balance} coins</ParagraphSmall>
                  </IonItem>
                </IonList>

              }
            </>
          }
          {profileSettingsVisible &&
            <>
              <ReDoBackButton onClick={() => setProfileSettingsVisible()} />
              <Block display='flex' flexDirection='column'>
                <Block alignSelf='center'>
                  <Avatar
                    name={userProfile.fullname}
                    size="96px"
                    src={userProfile.photo}
                  />
                </Block>
                <Block alignSelf='center' display='flex' flexDirection='column' alignItems='center'>
                  <HeadingLarge marginBottom='0' overrides={{ Block: { style: { fontWeight: 'bold' } } }}>{userProfile.fullname}</HeadingLarge>
                  <ParagraphMedium marginTop='0'>{userProfile.username ? `@${userProfile.username}` : userProfile.name}</ParagraphMedium>
                </Block>

                <Block marginTop='3ch' alignSelf='end' display='flex' alignItems='end' paddingRight='1ch' paddingBottom='1ch'>
                  <ReDoButton overrides={{ BaseButton: { style: { boxShadow: 'none' } } }} kind='link'><LabelXSmall color='#0000ffa3'>Edit</LabelXSmall></ReDoButton>
                </Block>

                <IonList inset className={css({ margin: '1ch 0 !important' })}>
                  <IonItem lines='none' className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                    <Block alignItems='center' paddingRight='2ch' width='100%' display='flex' justifyContent='space-between'>
                      <LabelXSmall>NAME</LabelXSmall>
                      <ParagraphSmall>{userProfile.fullname}</ParagraphSmall>
                    </Block>
                  </IonItem>
                  <Block backgroundColor='white' display='flex' justifyContent='center'>
                    <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
                  </Block>
                  <IonItem lines='none' className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                    <Block alignItems='center' paddingRight='2ch' width='100%' display='flex' justifyContent='space-between'>
                      <LabelXSmall>PRONOUNS</LabelXSmall>
                      <ParagraphSmall>{userProfile.pronouns}</ParagraphSmall>
                    </Block>
                  </IonItem>
                  <Block backgroundColor='white' display='flex' justifyContent='center'>
                    <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
                  </Block>
                  <IonItem lines='none' className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                    <Block alignItems='center' paddingRight='2ch' width='100%' display='flex' justifyContent='space-between'>
                      <LabelXSmall>MAIL</LabelXSmall>
                      <ParagraphSmall>{user.attributes.email}</ParagraphSmall>
                    </Block>
                  </IonItem>
                  <Block backgroundColor='white' display='flex' justifyContent='center'>
                    <Block height='1px' backgroundColor={theme.colors.secondary200} width='96%' />
                  </Block>
                  <IonItem lines='none' className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                    <Block alignItems='center' paddingRight='2ch' width='100%' display='flex' justifyContent='space-between'>
                      <LabelXSmall>LOCATION</LabelXSmall>
                      <ParagraphSmall>{userProfile.city}, {userProfile.country}</ParagraphSmall>
                    </Block>
                  </IonItem>
                </IonList>

                <IonList onClick={onForgotPassword} inset className={css({ margin: '1ch 0 !important' })}>
                  <IonItem lines='none' button className={css({ '::part(native)': { background: 'white', paddingTop: '.5ch', paddingBottom: '.5ch' }, '::part(detail-icon)': { color: 'black' } })}>
                    <LabelXSmall>Change password</LabelXSmall>
                  </IonItem>
                </IonList>

                <Block display='flex' justifyContent='center' marginTop='6ch'>
                  <ReDoButton overrides={{ BaseButton: { style: { boxShadow: 'none' } } }} kind='link' onClick={() => dispatch(signOut)}><ParagraphMedium color='red'>Delete account</ParagraphMedium></ReDoButton>
                </Block>

              </Block>
            </>
          }
        </>
      )}

    </>
  );
};

export default ReDoSettings;