import { LOAD_WALLET_SUCCESS } from "../actions/wallet";


const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_WALLET_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}