import { loadCompanyChallengesSuccess, LOAD_COMPANY_CHALLENGES, SUBMIT_CHALLENGE, UPLOAD_CHALLENGE_PHOTOS, LOAD_CHALLENGE_FEED, LOAD_CHALLENGE_BY_ID, LOAD_CHALLENGE_COMMENTS, SUBMIT_COMMENT, FAVORITE_CHALLENGE, LOAD_FAVORITE_CHALLENGES, LOAD_CHALLENGES_FOR_SOLUTION, SHARE_CHALLENGE, DELETE_CHALLENGE } from "../actions/challenges";


export const challenges = {

  [LOAD_COMPANY_CHALLENGES]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.getCompanyChallenges();
    dispatch(loadCompanyChallengesSuccess(response.data));
  },

  [LOAD_CHALLENGES_FOR_SOLUTION]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.getChallengesForSolution(action.payload);
    return response.data
  },

  [SUBMIT_CHALLENGE]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.submitChallenge(action.payload);
    return response.data;
  },

  [DELETE_CHALLENGE]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.deleteChallenge(action.payload);
    return response.data;
  },

  [UPLOAD_CHALLENGE_PHOTOS]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.uploadChallengePhotos(action.payload);
    return response.data;
  },

  [LOAD_CHALLENGE_FEED]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.getChallengeFeed();
    return response.data;
  },

  [LOAD_CHALLENGE_BY_ID]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.getChallengeById(action.payload);
    return response.data;
  },

  [LOAD_CHALLENGE_COMMENTS]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.getChallengeComments(action.payload);
    const timeSince = (dateString) => {
      const date = new Date(dateString);
      const today = new Date();
      const timeDiff = today.getTime() - date.getTime();
      const minutesDiff = Math.round(timeDiff / (1000 * 60));

      if (minutesDiff < 60 * 24) {
        if (minutesDiff < 60) {
          return `${minutesDiff > 1 ? `${minutesDiff} minutes ago` : 'just now'}`;
        } else {
          const hoursDiff = Math.floor(minutesDiff / 60);
          return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago`;
        }
      } else if (minutesDiff < 60 * 24 * 30) {
        const daysDiff = Math.floor(minutesDiff / (60 * 24));
        return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
      } else if (minutesDiff < 60 * 24 * 365) {
        const monthsDiff = Math.floor(minutesDiff / (60 * 24 * 30));
        return `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ago`;
      } else {
        const yearsDiff = Math.floor(minutesDiff / (60 * 24 * 365));
        return `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} ago`;
      }
    }

    const mapComments = (comments) => comments?.map((comment) => ({
      text: comment.content,
      fullName: comment.displayName,
      avatarUrl: comment.displayPhoto,
      comId: comment.id,
      userId: comment.userID,
      createdAt: timeSince(comment.createdAt),
      parentId: comment.parentID,
      projectId: comment.projectID,
      rootId: comment.rootID,
      replies: comment.children ? mapComments(comment.children) : []
    }))
    const flattenedComments = (comments) => {
      const flattened = [];

      function flattenComment(comment) {
        const flatComment = { ...comment };
        if (comment.replies && comment.replies.length > 0) {
          flatComment.replies = [];
          for (const reply of comment.replies) {
            flatComment.replies.push(reply);
            if (reply.replies && reply.replies.length > 0) {
              // recursively flatten subreplies
              const subreplies = flattenComment(reply).replies;
              if (Array.isArray(subreplies)) {
                flatComment.replies.push(...subreplies);
              }
            }
          }
        }
        return flatComment;
      }

      for (const comment of comments) {
        const flatComment = flattenComment(comment);
        flattened.push(flatComment);
      }

      const remap = flattened.flatMap((el) => [el, ...(el.replies || [])])

      return flattened.map(cmt => ({ ...cmt, replies: cmt.replies?.map(rep => ({ ...rep, parentUser: '@' + remap.find(fcmt => fcmt.comId === rep.parentId).fullName })) }));
    }

    return flattenedComments(mapComments(response.data))
  },

  [SUBMIT_COMMENT]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.submitComment(action.payload);
    return response.data;
  },

  [FAVORITE_CHALLENGE]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.favoriteChallenge(action.payload.challengeId);
    return response.data
  },

  [SHARE_CHALLENGE]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.shareChallenge(action.payload.challengeId);
    return response.data
  },

  [LOAD_FAVORITE_CHALLENGES]: async ({ api, dispatch, getState, action }) => {
    const response = await api.challenges.getFavoriteChallenges();
    return response.data;
  }

}