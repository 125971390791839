import { compose, applyMiddleware, createStore } from "redux";
import reducers from './reducers';
import middleware from './middleware';
import services from '../infrastructure/services';


const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const asyncMiddleware = (args) => ({ dispatch, getState }) => next => action => {
  return new Promise(async (resolve, reject) => {
    try {
      if (typeof args.middleware[action.type] === 'function') {
        args.config.loading && dispatch({
          type: 'ASYNC_MIDDLEWARE_SET_LOADING_SLOT',
          payload: { state: true, context: action.type },
        })
        const res = await args.middleware[action.type]({ ...args.services, dispatch, getState, action })
        resolve(res || action)
        args.config.loading && dispatch({
          type: 'ASYNC_MIDDLEWARE_SET_LOADING_SLOT',
          payload: { state: false, context: action.type },
        })
      } else {
        resolve(next(action))
      }
    } catch (error) {
      console.log(action, error);
      args.config.error && dispatch({
        type: 'ASYNC_MIDDLEWARE_SET_ERROR',
        payload: { error: error?.stack, location: action.type },
      })
      reject(error)
    }
  })
}

export const configureStore = () => createStore(
  reducers,
  composeEnhancers(applyMiddleware(asyncMiddleware({ services, middleware, config: { loading: false, error: true } })))
);