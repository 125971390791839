import axios from 'axios';
import { baseUrl, withAuth } from '../auth/helper';

export default {
  getInboxes: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/inbox`, options)
    return response
  }),
  submitInbox: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/inbox`, params, options)
    return response
  }),
}