import axios from 'axios';
import { baseUrl, withAuth } from '../auth/helper';

export default {
  getProfile: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/profile`, options)
    return response
  }),
  getProfileById: async (params) => {
    const response = await axios.get(`${baseUrl}/profile/${params}`)
    return response
  },
  createProfile: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/profile`, params, options)
    return response
  }),
  uploadAvatar: withAuth(async (params, options) => {
    const response = await axios.put(`${baseUrl}/profile/photo`, params, options)
    return response
  })
}