import { IonContent } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { Check, Delete } from "baseui/icon";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { DisplayLarge, HeadingXLarge, ParagraphLarge, ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import { forwardRef } from "react";
import { useDispatch } from 'react-redux';
import { federatedSignIn, signUp } from "../../application/actions/auth";
import { routeTo } from "../../application/actions/ui";
import gLogo from '../../assets/images/g-logo.png';
import ReDoBanner from "../../components/ReDoBanner";
import ReDoButton from "../../components/ReDoButton";
import ReDoInput from "../../components/ReDoInput";
import ReDoLogo from "../../components/ReDoLogo";
import ReDoProjectCard from "../../components/ReDoProjectCard";


const Signup = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const { enqueue } = useSnackbar();

  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: '370px',
    margin: '0 auto',
  });

  const or = css({
    color: theme.colors.primary200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    "::before": {
      content: '""',
      display: 'block',
      background: theme.colors.primaryB,
      width: '100%',
      height: '1px',
      margin: '0 10px'
    },
    "::after": {
      content: '""',
      display: 'block',
      background: theme.colors.primaryB,
      width: '100%',
      height: '1px',
      margin: '0 10px'
    }
  })

  const onLogIn = () => {
    dispatch(routeTo({ view: 'login' }))
  }

  const onGoogleLogin = () => {
    dispatch(federatedSignIn({ provider: 'Google' }))
  }

  const onSignUp = (e) => {
    e.preventDefault();
    const email = e.target.email.value.toLowerCase();
    const password = e.target.password.value;
    dispatch(signUp({ username: email, password: password }))
      .then(r => {
        enqueue({ message: 'Confirm via Email', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> });
      })
      .catch(e => {
        enqueue({ message: e.message, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
      });
  }

  

  return (
    <IonContent ref={ref} className="ion-padding">

      {props.project &&
        <Block height='550px' width='350px' position='fixed' overrides={{ Block: { style: { zIndex: 2, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } } }}>
          <ReDoProjectCard noButton shadow project={props.project} />
        </Block>
      }

      <FlexGrid
        flexGridColumnCount={[1, 1, 1, 2]}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
        height='100%'
        alignItems='center'
      >
        <FlexGridItem position='relative' height='100%' display={['none', 'none', 'none', 'flex']}>
          <div className={css({ position: 'fixed', top: '50px', right: '75px' })}>
            <ReDoLogo size={theme.sizing.scale2400} />
          </div>
          <ReDoBanner>
            <div className={css({ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', padding: `${theme.sizing.scale1600} ${theme.sizing.scale3200}` })}>
              <div className={css({ paddingTop: theme.sizing.scale2400 })}>
                <DisplayLarge>Join the ReDo Community</DisplayLarge>
              </div>
              <div className={css({ gap: '2ch', display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' })}>
                <ParagraphLarge>Already have an account?</ParagraphLarge>
                <Block width={theme.sizing.scale3200}>
                  <ReDoButton
                    kind='outline'
                    onClick={onLogIn}
                    overrides={{ BaseButton: { style: { paddingTop: '0.5ch', paddingBottom: '0.5ch' } } }}
                  >
                    Log in
                  </ReDoButton>
                </Block>
              </div>
            </div>
          </ReDoBanner>
        </FlexGridItem>
        <FlexGridItem height='100%'>
          <div className={column}>
            <Block alignSelf='flex-end' display={['unset', 'unset', 'unset', 'none']}>
              <ReDoLogo size={theme.sizing.scale1600} />
            </Block>
            <HeadingXLarge marginTop='auto' marginRight='2px' marginLeft='2px' display={['flex', 'flex', 'flex', 'none']}>Welcome</HeadingXLarge>
            <Block paddingBottom='28px' marginBottom={['auto', 'auto', 'auto', '0']} marginTop={['5%', '5%', '5%', '70%']}>
              <form className={css({ height: '100%' })} onSubmit={onSignUp}>
                <div className={css({ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' })}>

                  <Block width='100%' marginBottom='5%' marginTop={['auto', 'auto', 'auto', '0']}>
                    <FormControl>
                      <ReDoInput
                        id="email-input"
                        type="email"
                        placeholder="Enter your email"
                        name='email'
                        required
                      />
                    </FormControl>
                    <FormControl overrides={{ ControlContainer: { style: { marginBottom: 0 } } }}>
                      <ReDoInput
                        id="password-input"
                        type="password"
                        placeholder="Enter your password"
                        name='password'
                        required
                      />
                    </FormControl>
                  </Block>

                  <div className={css({ textAlign: 'center', marginBottom: '5%' })}>
                    <ParagraphXSmall margin='0' color='primary300'>
                      By signing up, you agree to ReDo's
                      <ReDoButton kind='link' type='button' color={theme.colors.primary300}>
                        terms of use,
                      </ReDoButton>
                      <ReDoButton kind='link' type='button' color={theme.colors.primary300}>
                        cookies,
                      </ReDoButton> and
                      <ReDoButton kind='link' type='button' color={theme.colors.primary300}>
                        privacy policy.
                      </ReDoButton>
                    </ParagraphXSmall>
                  </div>

                  <Block width={['100%', '100%', '100%', '320px']}>
                    <ReDoButton overrides={{ BaseButton: { style: { marginBottom: '5%' } } }} kind='brand' type="submit">
                      Sign up
                    </ReDoButton>
                    <div className={or}> or </div>
                    <ReDoButton overrides={{ BaseButton: { style: { marginTop: '5%', marginBottom: '5%', boxShadow: `0 0 15px 0px rgba(94, 94, 91, 20%)` } } }} kind='inverse' type="button" onClick={onGoogleLogin}
                      startEnhancer={() => <img src={gLogo} alt='' height={theme.sizing.scale850} />}
                    >
                      Continue with Google
                    </ReDoButton>
                  </Block>

                  <ParagraphMedium marginTop='auto' marginBottom='0' display={['flex', 'flex', 'flex', 'none']} alignItems='center' justifyContent='center' color='primary300'>
                    Already have an account? <ReDoButton fontWeight='bold' fontSize={theme.sizing.scale650} kind='link' type='button' color={theme.colors.black} onClick={onLogIn}>Log in</ReDoButton>
                  </ParagraphMedium>

                </div>
              </form>
            </Block>
          </div>
        </FlexGridItem>
      </FlexGrid>
    </IonContent >
  );
});

export default Signup;
