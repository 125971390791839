import { useStyletron } from 'baseui';
import { Avatar } from 'baseui/avatar';
import { Block } from 'baseui/block';
import { Button, SHAPE } from 'baseui/button';
import { ArrowUp } from 'baseui/icon';
import { Input } from 'baseui/input';
import { LabelXSmall, ParagraphMedium } from 'baseui/typography';
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import ReDoBackButton from './ReDoBackButton';

const socketUrl = process.env.REACT_APP_CHAT_SOCKET_URL


const ReDoChat = ({ target, className, align, ...props }) => {
  const [, theme] = useStyletron();
  const chatContainer = useRef();
  const [currMessage, setCurrMessage] = useState('')
  const [messages, setMessages] = useState([]);

  const { sendJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      sendJsonMessage({
        action: 'JOIN',
        data: {
          inboxId: props.item.id,
          userId: props.item.userID,
          inboxSecret: props.item.secret
        }
      })
    },
    onMessage: (e) => {
      setMessages([...messages, ...(JSON.parse(e.data)?.messages || [])])
    },
  });

  const onSend = () => {
    if (currMessage) {
      sendJsonMessage({
        action: 'SEND_MESSAGE',
        data: {
          inboxId: props.item.id,
          userId: props.item.userID,
          inboxSecret: props.item.secret,
          content: currMessage
        }
      })
      setCurrMessage('')
    }
  }

  const messagesGroupedByDate = useMemo(() => {
    const grouped = messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).reduce((acc, curr) => {
      const date = new Date(curr.createdAt).toLocaleDateString('default', { weekday: 'short' }) + '. ' + new Date(curr.createdAt).toLocaleDateString('default', { month: '2-digit', day: 'numeric' })
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(curr)
      return acc
    }, {})
    return Object.entries(grouped)
  }, [messages])

  useEffect(() => {
    if (chatContainer.current) {
      chatContainer.current.scrollTo({ top: chatContainer.current.scrollHeight, behavior: 'smooth' })
    }
  }, [messages])


  return (
    <Block display='flex' flexDirection='column' height='calc(100% - 28px)'>
      <Block paddingBottom='1ch' gridGap='1ch' alignItems='center' display='flex'>
        <Block height='24px' width='28px'>
          <ReDoBackButton />
        </Block>
        <Block>
          <Block gridGap='1ch' display='flex' alignItems='center'>
            <Avatar
              name={props.item.recipientDisplayName}
              size="48px"
              src={props.item.recipientPhotoURL}
            />
            <Block display='flex' flexDirection='column'>
              <ParagraphMedium overrides={{ Block: { style: { fontWeight: '700', margin: '0' } } }}>
                {props.item.recipientDisplayName}
              </ParagraphMedium>
              <ParagraphMedium color={theme.colors.primary200} overrides={{ Block: { style: { fontWeight: '400', margin: '0' } } }}>
                {props.item.recipientLocation}
              </ParagraphMedium>
            </Block>
          </Block>
        </Block>
      </Block>

      <Block margin={align ? '0' : '0 -28px'} overrides={{ Block: { style: { borderBottom: `1px solid ${theme.colors.secondary200}` } } }} />

      <Block ref={chatContainer} padding={align ? '1.5ch 50px' : '1.5ch 0'} overflow='auto' gridGap='2ch' display='flex' flexDirection='column' height='100%'>

        {messagesGroupedByDate.map(group =>
          <Fragment key={group[0]}>
            <Block display='flex' flexDirection='column' alignItems='center' gridGap='1ch'>
              <LabelXSmall color={theme.colors.primary200} overrides={{ Block: { style: { textTransform: 'uppercase' } } }}>{group[0]}</LabelXSmall>
            </Block>
            {group[1].map(msg =>
              <Block
                alignSelf={msg.userId !== props.item.userID ? 'flex-start' : 'flex-end'}
                key={msg.id}
                width='75%'
                overrides={{ Block: { style: { border: msg.userId !== props.item.userID ? `1px solid white` : !align ? `1px solid ${theme.colors.secondary200}` : '0', borderRadius: msg.userId !== props.item.userID ? '21px 21px 21px 0' : '21px 21px 0 21px' } } }}
                padding='1ch 2ch'
                backgroundColor={msg.userId !== props.item.userID ? theme.colors.primary100 : 'white'}
              >
                {msg.content}
              </Block>
            )}
          </Fragment>
        )}

      </Block>

      <Block backgroundColor={theme.colors.primary100} padding='1ch' gridGap='1ch' display='flex' margin={align ? '0' : '0 -28px -28px -28px'} width={align ? 'auto' : '100vw'}>
        <Input
          value={currMessage}
          onChange={e => setCurrMessage(e.target.value)}
          placeholder="Type here..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSend();
            }
          }}
        />
        <Block overrides={{ Block: { style: { flexShrink: '0' } } }}><Button onClick={onSend} shape={SHAPE.circle}><ArrowUp size='4ch' /></Button></Block>
      </Block>

    </Block>
  );
};

export default ReDoChat;