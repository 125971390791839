import { IonContent, IonPage } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { Check, Delete } from "baseui/icon";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { DisplayLarge, HeadingXLarge, ParagraphLarge, ParagraphMedium } from 'baseui/typography';
import { forwardRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { federatedSignIn, signIn } from "../../application/actions/auth";
import { loadCompany } from "../../application/actions/company";
import { routeTo } from "../../application/actions/ui";
import { loadProfile } from "../../application/actions/users";
import gLogo from '../../assets/images/g-logo.png';
import ReDoBanner from "../../components/ReDoBanner";
import ReDoButton from "../../components/ReDoButton";
import ReDoInput from "../../components/ReDoInput";
import ReDoLogo from "../../components/ReDoLogo";
import ReDoSpinner from "../../components/ReDoSpinner";
import useCognito from "../../hooks/useCognito";


const Login = forwardRef((props, ref) => {
  const { config } = useCognito()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState()
  const [css, theme] = useStyletron();
  const { enqueue, dequeue } = useSnackbar();


  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: '370px',
    margin: '0 auto',
  });

  const or = css({
    color: theme.colors.primary200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    "::before": {
      content: '""',
      display: 'block',
      background: theme.colors.primaryB,
      width: '100%',
      height: '1px',
      margin: '0 10px'
    },
    "::after": {
      content: '""',
      display: 'block',
      background: theme.colors.primaryB,
      width: '100%',
      height: '1px',
      margin: '0 10px'
    }
  })

  const onSignUp = () => {
    dispatch(routeTo({ view: 'signup' }))
  }

  const redirect = () => {
    setLoading(true);
    if (props.scope === 'company') {
      dispatch(loadCompany())
        .then(() => {
          setLoading(false);
          dispatch(routeTo({ view: 'companyProfile' }))
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setLoading(false);
            dispatch(routeTo({ view: 'companyCreateProfile' }))
          }
        })
    } else {
      dispatch(loadProfile())
        .then(() => {
          setLoading(false);
          dispatch(routeTo({ view: 'userProfile' }))
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setLoading(false);
            dispatch(routeTo({ view: 'userCreateProfile' }))
          }
        })
    }
  }

  const onGoogleLogin = () => {
    dispatch(federatedSignIn({ config, provider: 'Google' }))
  }

  const onLogin = (e) => {
    e.preventDefault();
    const email = e.target.email.value.toLowerCase();
    const password = e.target.password.value;
    enqueue({ message: 'Logging in...', progress: true }, DURATION.infinite)
    dispatch(signIn({ username: email, password: password }))
      .then(r => {
        dequeue()
        enqueue({ message: 'Login successful', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> });
        redirect()
      })
      .catch(e => {
        dequeue()
        enqueue({ message: e.message, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
      });
  }

  const onForgotPassword = () => {
    dispatch(routeTo({ view: 'forgotPassword' }))
  }

  return (
    <IonPage ref={ref} >
      <IonContent className="ion-padding">

        {loading && <ReDoSpinner />}

        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
          height='100%'
          alignItems='center'
        >
          <FlexGridItem position='relative' height='100%' display={['none', 'none', 'none', 'flex']}>
            <div className={css({ position: 'fixed', top: '50px', right: '75px' })}>
              <ReDoLogo size={theme.sizing.scale2400} />
            </div>
            <ReDoBanner>
              <div className={css({ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', padding: `${theme.sizing.scale1600} ${theme.sizing.scale3200}` })}>
                <div className={css({ paddingTop: theme.sizing.scale2400 })}>
                  <DisplayLarge>Welcome back, you've been missed.</DisplayLarge>
                </div>
                <div className={css({ gap: '2ch', display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' })}>
                  <ParagraphLarge>Don't have an account yet?</ParagraphLarge>
                  <Block width={theme.sizing.scale3200}>
                    <ReDoButton
                      kind='outline'
                      onClick={onSignUp}
                      overrides={{ BaseButton: { style: { paddingTop: '0.5ch', paddingBottom: '0.5ch' } } }}
                    >
                      Sign up
                    </ReDoButton>
                  </Block>
                </div>
              </div>
            </ReDoBanner>
          </FlexGridItem>

          <FlexGridItem height='100%'>
            <div className={column}>
              <Block alignSelf='flex-end' display={['unset', 'unset', 'unset', 'none']}>
                <ReDoLogo size={theme.sizing.scale1600} />
              </Block>
              <HeadingXLarge marginTop='auto' marginRight='2px' marginLeft='2px' display={['flex', 'flex', 'flex', 'none']}>Welcome</HeadingXLarge>
              <Block paddingBottom='28px' marginBottom={['auto', 'auto', 'auto', '0']} marginTop={['5%', '5%', '5%', '70%']}>
                <form className={css({ height: '100%' })} onSubmit={onLogin}>
                  <div className={css({ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' })}>

                    <Block width='100%' marginBottom='5%' marginTop={['auto', 'auto', 'auto', '0']}>
                      <FormControl>
                        <ReDoInput
                          id="email-input"
                          type="email"
                          placeholder="Enter your email"
                          name='email'
                          required
                        />
                      </FormControl>
                      <FormControl overrides={{ ControlContainer: { style: { marginBottom: 0 } } }}>
                        <ReDoInput
                          id="password-input"
                          type="password"
                          placeholder="Enter your password"
                          name='password'
                          required
                        />
                      </FormControl>
                    </Block>

                    <div className={css({ alignSelf: 'end', paddingRight: theme.sizing.scale400, marginBottom: '7%' })}>
                      <ReDoButton kind='link' onClick={onForgotPassword} type='button' color={theme.colors.primary300} overrides={{
                        BaseButton: {
                          style: { fontWeight: 600 }
                        }
                      }}>
                        Forgot password
                      </ReDoButton>
                    </div>

                    <Block width={['100%', '100%', '100%', '320px']}>
                      <ReDoButton overrides={{ BaseButton: { style: { marginBottom: '5%' } } }} kind='brand' type="submit">
                        Login
                      </ReDoButton>
                      <div className={or}> or </div>
                      <ReDoButton overrides={{ BaseButton: { style: { marginTop: '5%', marginBottom: 0 } } }} kind='inverse' type="button" onClick={onGoogleLogin}
                        startEnhancer={() => <img src={gLogo} alt='' height={theme.sizing.scale850} />}
                      >
                        Continue with Google
                      </ReDoButton>
                    </Block>

                    <ParagraphMedium marginTop='5%' display={['flex', 'flex', 'flex', 'none']} alignItems='center' justifyContent='center' color='primary300'>
                      Don't have an account yet? <ReDoButton fontWeight='bold' fontSize={theme.sizing.scale650} kind='link' type='button' color={theme.colors.black} onClick={onSignUp}>Sign up</ReDoButton>
                    </ParagraphMedium>

                  </div>
                </form>
              </Block>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </IonContent>
    </IonPage>
  );
});

export default Login;
