import { useStyletron } from 'baseui';
import { Input } from 'baseui/input';
import { ParagraphMedium } from 'baseui/typography';


const ReDoInput = ({ label, optionalLabel, name, maxLength, shadow, ...props }) => {
  const [css, theme] = useStyletron();

  return (
    <div>
      <ParagraphMedium marginTop='0' paddingLeft='5px'>{label} <span className={css({ color: theme.colors.primary200 })}>{optionalLabel}</span></ParagraphMedium>
      <Input
        name={name}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              height: $theme.sizing.scale1400,
              border: '0',
              boxShadow: !shadow ? 'none' : `0 0 15px 0px rgba(94, 94, 91, 20%)`,
            })
          },
          Input: {
            style: ({ $theme }) => ({
              paddingLeft: $theme.sizing.scale600,
              paddingRight: $theme.sizing.scale600,
              color: $theme.colors.black,
            })
          }
        }}
        maxLength={maxLength}
        {...props}
      />
    </div>
  );
};

export default ReDoInput;