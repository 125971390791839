import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Checkbox } from 'baseui/checkbox';
import { Blank, Delete } from 'baseui/icon';
import { Radio, StatefulRadioGroup } from 'baseui/radio';
import { LabelXSmall, ParagraphMedium } from 'baseui/typography';
import { useState } from 'react';


const ReDoInterests = ({ values, options, label, radio, name, invert, limit, ...props }) => {
  const [css, theme] = useStyletron();
  const [value, setValue] = useState(values);

  

  return (
    <div>
      <ParagraphMedium marginTop='0' paddingLeft='5px'>{label}</ParagraphMedium>
      {options?.map((option, index) =>
        <Block key={option.group} marginTop='2ch' padding='1.5ch' overrides={{ Block: { style: { borderRadius: '5px' } } }} backgroundColor={!invert ? option.color : theme.colors.primary100}>
          {option.group && <LabelXSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>{option.group}</LabelXSmall>}

          {radio &&
            <StatefulRadioGroup name={name} initialState={{ value: value?.toLowerCase() }}>
              {option.list.map((item, i) => (
                <Radio overrides={{
                  Label: {
                    style: ({ $theme }) => ({
                      fontSize: $theme.sizing.scale550,
                      whiteSpace: 'pre-line',
                    })
                  },
                  RadioMarkOuter: {
                    style: ({ $theme }) => ({
                      height: '2ch',
                      width: '2ch',
                      background: theme.colors.primary100,
                      backgroundColor: 'transparent',
                      borderRadius: '5px !important'
                    })
                  },
                  RadioMarkInner: {
                    component: ({ $checked }) => (
                      $checked ? (
                        <div className={css({ height: '2ch', width: '2ch', borderRadius: '5px !important', background: !invert ? theme.colors.primary100 : 'white', color: theme.colors.black })}>
                          <Delete size='100%' />
                        </div>
                      ) : (
                        <div className={css({ height: '2ch', width: '2ch', borderRadius: '5px !important', background: !invert ? theme.colors.primary100 : 'white' })}>
                          <Blank />
                        </div>
                      )
                    )
                  },

                }} key={item} value={item?.toLowerCase()}>{item}</Radio>
              ))}
            </StatefulRadioGroup>
          }

          {!radio && option.list.map((item, i) =>
            <Block key={item} paddingTop={option.group ? '1ch' : i === 0 ? '0' : '1ch'}>
              <Checkbox
                onChange={() => setValue(value?.includes(item) ? value.filter((v) => v !== item) : value.length >= limit ? value : [...(value || []), item])}
                name={item}
                checked={value?.includes(item)}
                overrides={{
                  Checkmark: () => (
                    value?.includes(item) ? (
                      <div className={css({ height: '2ch', width: '2ch', alignSelf: 'center', background: !invert ? theme.colors.primary100 : 'white', borderRadius: '5px', color: theme.colors.black })}>
                        <Delete size='100%' />
                      </div>
                    ) : (
                      <div className={css({ height: '2ch', width: '2ch', marginTop: '4px', background: !invert ? theme.colors.primary100 : 'white', borderRadius: '5px' })}>
                        <Blank />
                      </div>
                    )
                  ),
                  Label: {
                    style: ({ $theme }) => ({
                      fontSize: $theme.sizing.scale550,
                      whiteSpace: 'pre-line',
                    })
                  }
                }}
              >
                {item}
              </Checkbox>
            </Block>
          )}
        </Block>
      )
      }
    </div >
  );
};

export default ReDoInterests;