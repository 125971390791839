import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { Check, Delete, Plus } from 'baseui/icon';
import { Input } from 'baseui/input';
import { ParagraphMedium } from 'baseui/typography';
import { useState } from 'react';


const ReDoTags = ({ submitUpTo, values, options, label, additionLabel, onChange, limit, optionalLabel, background, ...props }) => {
  const [css, theme] = useStyletron();
  const [value, setValue] = useState(values || []);

  const [additionalValueCreation, setAdditionalValueCreation] = useState(false);
  const [additionalValues, setAdditionalValues] = useState([]);
  const [additionalValue, setAdditionalValue] = useState([]);

  const onOptionClick = (option) => {
    if (value.includes(option)) {
      setValue(value.filter(item => item !== option));
      onChange && onChange(value.filter(item => item !== option));
    } else {
      setValue([...value, option]);
      onChange && onChange([...value, option]);
    }
  }

  const createAdditionalOption = () => {
    setAdditionalValueCreation(true);
  }

  const onAdditionalValueBlur = () => {
    if (additionalValue.length > 0) {
      setAdditionalValues([...additionalValues, additionalValue]);
      setValue([...value, additionalValue]);
      onChange && onChange([...value, additionalValue]);
      setAdditionalValue('')
    } else {
      setAdditionalValueCreation(false);
    }
  }

  const onRemoveAdditionalOption = (option) => {
    setAdditionalValues(additionalValues.filter(item => item !== option));
    setValue(value.filter(item => item !== option));
    setAdditionalValueCreation(false);
    onChange && onChange(value.filter(item => item !== option));
  }


  return (
    <div>

      <ParagraphMedium marginTop='0' paddingLeft='5px'>{label} <span className={css({ color: theme.colors.primary200 })}>{optionalLabel}</span></ParagraphMedium>


      <Block display='flex' gridGap='1ch' flexWrap='wrap'>

        {options?.map((option, index) =>
          <Button disabled={value.length >= submitUpTo && !value.includes(option?.toLowerCase())} onClick={() => onOptionClick(option?.toLowerCase())} type='button' endEnhancer={() => value.includes(option?.toLowerCase()) ? <Check size={18} /> : <Plus size={18} />} key={index} overrides={{
            BaseButton: {
              style: () => ({
                padding: '10px 10px 10px 18px',
                fontSize: '12px',
                ':hover': {
                  backgroundColor: value.includes(option?.toLowerCase()) ? theme.colors.secondary0 : theme.colors.primary100,
                  color: value.includes(option?.toLowerCase()) ? theme.colors.primary300 : theme.colors.secondary200,
                },
                backgroundColor: value.includes(option?.toLowerCase()) ? theme.colors.secondary0 : background || 'white',
                color: value.includes(option?.toLowerCase()) ? theme.colors.primary300 : theme.colors.secondary200,
              })
            }
          }} shape='pill'>{option}</Button>
        )}
        {additionalValues?.map((option, index) =>
          <Block gridGap='.5ch' display='flex' alignItems='center' key={index}>
            <Button disabled={value.length >= submitUpTo && !value.includes(option)} onClick={() => onOptionClick(option)} type='button' endEnhancer={() => value.includes(option) ? <Check size={18} /> : <Plus size={18} />} overrides={{
              BaseButton: {
                style: () => ({
                  padding: '10px 10px 10px 18px',
                  fontSize: '12px',
                  ':hover': {
                    backgroundColor: value.includes(option) ? theme.colors.secondary0 : theme.colors.primary100,
                    color: value.includes(option) ? theme.colors.primary300 : theme.colors.secondary200,
                  },
                  backgroundColor: value.includes(option) ? theme.colors.secondary0 : background || 'white',
                  color: value.includes(option) ? theme.colors.primary300 : theme.colors.secondary200,
                })
              }
            }} shape='pill'>{option}</Button>
            <Button onClick={() => onRemoveAdditionalOption(option)} type='button' overrides={{
              BaseButton: {
                style: () => ({
                  fontSize: '12px',
                  ':hover': {
                    backgroundColor: value.includes(option) ? theme.colors.secondary0 : theme.colors.primary100,
                    color: value.includes(option) ? theme.colors.primary300 : theme.colors.secondary200,
                  },
                  padding: '0',
                  backgroundColor: value.includes(option) ? theme.colors.secondary0 : background || 'white',
                  color: value.includes(option) ? theme.colors.primary300 : theme.colors.secondary200,
                })
              }
            }} shape='pill'><Delete size={18} /></Button>
          </Block>
        )}

        {additionalValues.length < limit &&
          <Button disabled={value.length >= submitUpTo} onClick={createAdditionalOption} type='button' startEnhancer={() => value.includes(additionLabel) ? <Check size={18} /> : <Plus size={18} />} overrides={{
            BaseButton: {
              style: () => ({
                padding: '10px 10px 10px 18px',
                fontSize: '12px',
                boxShadow: 'none',
                ':hover': {
                  backgroundColor: value.includes(additionLabel) ? theme.colors.secondary0 : theme.colors.primary100,
                  color: value.includes(additionLabel) ? theme.colors.primary300 : theme.colors.secondary200,
                },
                backgroundColor: value.includes(additionLabel) ? theme.colors.secondary0 : background || 'white',
                color: value.includes(additionLabel) ? theme.colors.primary300 : theme.colors.secondary200,
              })
            },
            StartEnhancer: {
              style: () => ({
                marginRight: '12px'
              })
            }
          }} shape='pill'>

            {additionalValueCreation ?
              <Input
                type='text'
                value={additionalValue}
                onChange={(e) => setAdditionalValue(e.target.value)}
                onBlur={onAdditionalValueBlur}
                onKeyDown={(e) => { e.key === 'Enter' && onAdditionalValueBlur() }}
                autoFocus
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      height: '20px',
                      border: '0 !important',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      fontSize: '12px',
                    })
                  },
                  Input: {
                    style: ({ $theme }) => ({
                      paddingLeft: '0',
                      paddingRight: $theme.sizing.scale600,
                      color: $theme.colors.black,
                      height: '20px',
                      backgroundColor: 'transparent',
                      fontSize: '12px',
                      border: '0 !important',
                    })
                  },
                  InputContainer: {
                    style: ({ $theme }) => ({
                      backgroundColor: 'transparent',
                      fontSize: '12px',
                      border: '0 !important',
                    })
                  },
                }}
              />
              :
              <Block marginRight='6px'>{additionLabel}</Block>
            }

          </Button>
        }
      </Block>

    </div >
  );
};

export default ReDoTags;