import { useStyletron } from 'baseui';


const ReDoLogo = ({ size }) => {
  const [css, theme] = useStyletron();

  return (
    <div className={css({
      fontFamily: 'RD-Vertikal',
      fontSize: size,
      color: theme.colors.accent,
      lineHeight: '0.7',
    })}>
      RE DO
    </div>
  );
};

export default ReDoLogo;