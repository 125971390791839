import { IonContent, IonPage } from "@ionic/react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { Check, Delete } from "baseui/icon";
import { DURATION, useSnackbar } from "baseui/snackbar";
import { DisplayLarge, HeadingXLarge, ParagraphLarge } from "baseui/typography";
import { forwardRef, useRef } from "react";
import { useDispatch } from 'react-redux';
import { forgotPassword, resetPassword } from "../../application/actions/auth";
import { routeTo } from "../../application/actions/ui";
import ReDoBanner from "../../components/ReDoBanner";
import ReDoButton from "../../components/ReDoButton";
import ReDoInput from "../../components/ReDoInput";
import ReDoLogo from "../../components/ReDoLogo";
import ReDoBackButton from "../../components/ReDoBackButton";


const ForgotPassword = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const { enqueue, dequeue } = useSnackbar();
  const formRef = useRef()

  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxWidth: '370px',
    margin: '0 auto',
  });

  const onSendCode = (e) => {
    e.preventDefault();
    const email = e.target.email.value.toLowerCase();
    dispatch(forgotPassword({ username: email }))
      .then(r => {
        enqueue({ message: 'Confirm via Email', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> });
      })
      .catch(e => {
        enqueue({ message: e.message, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
      });
  }

  const onResetPassword = (e) => {
    e.preventDefault();
    const email = formRef.current['email'].value.toLowerCase();
    const code = e.target.code.value;
    const password = e.target.password.value;
    enqueue({ message: 'Resetting password...', progress: true }, DURATION.infinite)
    dispatch(resetPassword({ username: email, code, password }))
      .then(r => {
        dequeue()
        enqueue({ message: 'Password reset', dismissAfter: DURATION.shortest, startEnhancer: () => <Check size={theme.sizing.scale800} /> });
        !props.settings && dispatch(routeTo({ view: 'login' }))
      })
      .catch(e => {
        dequeue()
        enqueue({ message: e.message, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
      });
  }

  const onSignUp = () => {
    dispatch(routeTo({ view: 'signup' }))
  }

  return (
    <IonPage ref={ref} >
      <IonContent className="ion-padding">
        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 2]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
          height='100%'
          alignItems='center'
        >
          <FlexGridItem position='relative' height='100%' display={['none', 'none', 'none', 'flex']}>
            <div className={css({ position: 'fixed', top: '50px', right: '75px' })}>
              <ReDoLogo size={theme.sizing.scale2400} />
            </div>
            <ReDoBanner>
            {props.settings && <ReDoBackButton />}
              <div className={css({ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', padding: `${theme.sizing.scale1600} ${theme.sizing.scale3200}` })}>

                <div className={css({ paddingTop: theme.sizing.scale2400 })}>
                  
                  <DisplayLarge>{!props.settings ? "Welcome back, you've been missed." : 'Change your password.'}</DisplayLarge>
                </div>
                {!props.settings && <div className={css({ gap: '2ch', display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' })}>
                  <ParagraphLarge>Don't have an account yet?</ParagraphLarge>
                  <Block width={theme.sizing.scale3200}>
                    <ReDoButton
                      kind='outline'
                      onClick={onSignUp}
                      overrides={{ BaseButton: { style: { paddingTop: '0.5ch', paddingBottom: '0.5ch' } } }}
                    >
                      Sign up
                    </ReDoButton>
                  </Block>
                </div>}
              </div>
            </ReDoBanner>
          </FlexGridItem>

          <FlexGridItem height='100%'>
            <div className={column}>
              <Block alignSelf='flex-end' display={['unset', 'unset', 'unset', 'none']}>
                <ReDoLogo size={theme.sizing.scale1600} />
              </Block>
              <HeadingXLarge marginTop='auto' marginRight='2px' marginLeft='2px' display={['flex', 'flex', 'flex', 'none']}>Welcome</HeadingXLarge>
              <Block paddingBottom='28px' marginBottom={['auto', 'auto', 'auto', '0']} marginTop={['5%', '5%', '5%', '70%']}>
                <form ref={formRef} onSubmit={onSendCode}>
                  <div className={css({ marginTop: 'auto', display: 'flex', flexDirection: 'column', gap: theme.sizing.scale400 })}>
                    <FormControl overrides={{ ControlContainer: { style: { marginBottom: 0 } } }}>
                      <ReDoInput
                        id="email-input"
                        type="email"
                        placeholder="Enter your email"
                        name='email'
                        required
                      />
                    </FormControl>
                    <div className={css({ textAlign: 'center', margin: '12px 0', marginLeft: 'auto' })}>
                      <ReDoButton kind='link' color={theme.colors.primary300} type="submit" overrides={{ BaseButton: { style: { marginBottom: theme.sizing.scale400 } } }}
                      >
                        Send me a code
                      </ReDoButton>
                    </div>
                  </div>
                </form>
                <form onSubmit={onResetPassword}>
                  <div className={css({ marginBottom: 'auto', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: theme.sizing.scale400 })}>
                    <FormControl overrides={{ ControlContainer: { style: { marginBottom: 0 } } }}>
                      <ReDoInput
                        id="code-input"
                        placeholder="Enter your verification code"
                        name='code'
                        required
                      />
                    </FormControl>
                    <FormControl overrides={{ ControlContainer: { style: { marginBottom: 0 } } }}>
                      <ReDoInput
                        id="password-input"
                        type="password"
                        placeholder="Enter your new password"
                        name='password'
                        required
                      />
                    </FormControl>
                    <Block marginTop='15%' width={['100%', '100%', '100%', '320px']}>
                      <ReDoButton kind='brand' type="submit">
                        Reset password
                      </ReDoButton>
                    </Block>
                  </div>
                </form>
              </Block>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </IonContent>
    </IonPage>
  );
});

export default ForgotPassword;
