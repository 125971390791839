import axios from 'axios';
import { baseUrl, withAuth } from '../auth/helper';

export default {
  getUserSolutions: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/solution/user/${params}`, options)
    return response
  }),
  submitSolution: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/solution`, params, options)
    return response
  }),
  uploadSolutionPhotos: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/solution/photo`, params, options)
    return response
  }),
  getSolutions: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/solution`, options)
    return response
  }),
  searchSolutions: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/solution/search`, params, options)
    return response
  }),
  getSolutionById: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/solution/${params}`, options)
    return response
  }),
  getSolutionOverview: async (params) => {
    const response = await axios.get(`${baseUrl}/project/solution/${params}/public`)
    return response
  },
  deleteSolution: withAuth(async (params, options) => {
    const response = await axios.delete(`${baseUrl}/project/solution/${params}`, options)
    return response
  }),
  voteSolution: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/solution/vote`, params, options)
    return response
  }),
  loadSolutionComments: async (params) => {
    const response = await axios.get(`${baseUrl}/project/solution/${params}/comments`)
    return response
  },
  getSolutionsForChallenge: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/solution?challenge=${params}`, options)
    return response
  }),
  submitComment: withAuth(async (params, options) => {
    const response = await axios.post(`${baseUrl}/project/solution/comments`, params, options)
    return response
  }),
  loadSolutionFeed: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/solution/feed`, options)
    return response
  }),
  favoriteSolution: withAuth(async (params, options) => {
    const response = await axios.put(`${baseUrl}/project/solution/${params}/favorite`, params, options)
    return response
  }),
  loadFavoriteSolutions: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/solution/favorite`, options)
    return response
  }),
  loadBoughtSolutions: withAuth(async (params, options) => {
    const response = await axios.get(`${baseUrl}/project/solution/purchased`, options)
    return response
  }),
  shareSolution: withAuth(async (params, options) => {
    const response = await axios.put(`${baseUrl}/project/solution/${params}/share`, params, options)
    return response
  }),
}