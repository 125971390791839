import useWhoAmI from "../hooks/useWhoAmI";

const StripePriceTable = (props) => {
  const { userProfile } = useWhoAmI();

  return (
    <>
      {props.products &&
        <stripe-pricing-table
          pricing-table-id="prctbl_1Nya3pJEgdKh0E90BO6BoT3s"
          client-reference-id={userProfile.userId}
          publishable-key="pk_test_51NkoQXJEgdKh0E90jxysPRWHG8tkSOJbcGw0N31fYlAmTeCpvSv6zrbNpxuGaiAEUJ46FwbOIbpkm76adfPfMEW900exncGeeY">
        </stripe-pricing-table>
      }

      {props.subscriptions &&

        <stripe-pricing-table
          pricing-table-id="prctbl_1NrQaSJEgdKh0E90VyxRIJS8"
          client-reference-id={userProfile.userId}
          publishable-key="pk_test_51NkoQXJEgdKh0E90jxysPRWHG8tkSOJbcGw0N31fYlAmTeCpvSv6zrbNpxuGaiAEUJ46FwbOIbpkm76adfPfMEW900exncGeeY">

        </stripe-pricing-table>
      }

    </>
  );
}

export default StripePriceTable;