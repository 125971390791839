export const BUY_LICENSE_FOR_CHALLENGE = '[licenses] buy license for challenge';
export const GET_LICENSE = '[licenses] get license';
export const BUY_LICENSE_SUCCESS = '[licenses] buy license success'


export const buyLicenseForChallenge = payload => ({
    type: BUY_LICENSE_FOR_CHALLENGE,
    payload: payload,
});

export const buyLicenseSuccess = payload => ({
    type: BUY_LICENSE_SUCCESS,
    payload: payload,
});

export const loadLicenses = {
    type: GET_LICENSE,
  }

