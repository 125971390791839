import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from './application/store';
import services from './infrastructure/services';

import { BaseProvider } from 'baseui';
import { createTheme } from 'baseui/themes';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';

import { PLACEMENT, SnackbarProvider } from 'baseui/snackbar';
import App from './App';


const engine = new Styletron('redocss');

const container = document.getElementById('root');
const root = createRoot(container);

const primitives = {
  primaryFontFamily: 'Manrope',
  primary100: 'rgb(243,242,238)',
  primary200: '#959594',
  primary300: '#5e5e5b',
  primary400: '#2f2f2e',
  primary500: '#181717',
  secondary0: 'rgb(218,217,214)',
  secondary200: '#c0c0bc',
  accent: '#ff0000',
  badge: '#9bafe2'
};

const overrides = {
  colors: {
    inputFill: '#FFF',
    inputPlaceholder: '#959594',
    tickFillSelectedHover: 'transparent',
  },
  typography: {
    HeadingLarge: {
      fontWeight: 'normal',
    },
    HeadingXLarge: {
      fontSize: '48px',
      fontWeight: 'normal',
    },
    DisplayLarge: {
      lineHeight: '1.15',
      fontWeight: '300',
      fontSize: 'min(4.5vw,88px)',
    },
    DisplaySmall: {
      fontWeight: '300'
    },
    ParagraphMedium: {
      fontSize: '18px',
    },
    ParagraphLarge: {
      fontSize: '26px',
    }
  },
};

const theme = createTheme(primitives, overrides);

root.render(
  <React.StrictMode>
    <Provider store={configureStore(services)}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <SnackbarProvider placement={PLACEMENT.top}>
            <App />
          </SnackbarProvider>
        </BaseProvider>
      </StyletronProvider>
    </Provider>
  </React.StrictMode>
);
