import { LOAD_WALLET, loadWalletSuccess } from "../actions/wallet";


export const wallet = {

  [LOAD_WALLET]: async ({ api, dispatch, getState, action }) => {
    const response = await api.wallet.getWallet();

    

    dispatch(loadWalletSuccess(response.data));
  },

}