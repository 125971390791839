import { ArrowLeft } from 'baseui/icon';
import { useDispatch } from 'react-redux'
import { routeBack } from '../application/actions/ui';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import ReDoButton from './ReDoButton';


const ReDoBackButton = ({ onClick, className, ...props }) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();

  const onBackClick = () => {
    !onClick ? dispatch(routeBack) : onClick()
  }

  return (
    <Block display='flex'>
      <div className={`${css({ height: '40px', position: 'relative' })} ${className}`}>
        <ReDoButton kind='link' color={theme.colors.black} onClick={onBackClick} overrides={{
          BaseButton: { style: { paddingLeft: '0', position: 'absolute', left: '-10px', top: '10px', boxShadow: 'none' } }
        }}>
          <ArrowLeft size={theme.sizing.scale1000} />
        </ReDoButton>
      </div>
    </Block>
  );
};

export default ReDoBackButton;