import { Block } from 'baseui/block';
import { Modal, ModalBody } from 'baseui/modal';
import { useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { setPortalElement } from '../application/actions/ui';
import useBreakpoint from '../hooks/useBreakpoint';
import StripePriceTable from './StripePriceTable';


const ReDoPricing = (props) => {
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()

  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false)
    dispatch(setPortalElement())
  }

  return (
    <Modal
      overrides={{
        Dialog: {
          style: {
            width: '57vw',
            borderRadius: '21px'
          }
        },
        Close: {
          component: () => <Block overrides={{ Block: { style: { ':hover': { cursor: 'pointer' } } } }} onClick={onClose} position='absolute' top='2ch' right='2ch'>
            <IoCloseOutline size={32} />
          </Block>
        }
      }}
      onClose={onClose}
      isOpen={visible}>
      <ModalBody>
        <Block padding={breakpoint === 'large' ? '2ch 4ch' : 0}>
          <StripePriceTable {...props} />
        </Block>
        {/*<Block marginTop='4ch' padding={breakpoint === 'large' ? '2ch 4ch' : 0} display='flex' flexDirection='column'>
          <HeadingSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>ReDo is free for now. </HeadingSmall>
          <ParagraphMedium>We will not charge for usage right away. When we are ready to start charging, we will
            give you a heads up at least 45 days in advance. <br /><br />
            We haven’t finalized our pricing model, but our current thinking is:
          </ParagraphMedium>


          <Block margin='0 -20px' paddingTop={breakpoint === 'large' ? '1ch' : '0'} gridGap='2ch' display='flex' justifyContent='space-between'>
            {breakpoint !== 'large' &&
              <IonSlides options={{ initialSlide: 1, spaceBetween: '-20%' }} pager className={css({ width: '100%', '--bullet-background-active': theme.colors.black, '--bullet-background': theme.colors.primary300 })}>
                <IonSlide>
                  <Block margin='1ch 0 4ch 0' minHeight='320px' padding='2ch' overrides={{ Block: { style: { textAlign: 'start', flexShrink: '0', borderRadius: '8px', boxShadow: '0 0 15px 0px rgba(94, 94, 91, 20%)' } } }}>
                    <HeadingSmall overrides={{ Block: { style: { fontWeight: 'bold', margin: '0' } } }}>Single </HeadingSmall>
                    <LabelSmall>Access to one project only</LabelSmall>
                    <Block padding='4ch 0' display='flex' flexDirection='column' gridGap='1ch'>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                      </Block>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>See detail page</LabelMedium>
                      </Block>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                      </Block>
                    </Block>
                    <ReDoButton kind='outline'>Get in touch</ReDoButton>
                  </Block>
                </IonSlide>
                <IonSlide>
                  <Block margin='1ch 0 4ch 0' minHeight='320px' padding='2ch' overrides={{ Block: { style: { textAlign: 'start', flexShrink: '0', borderRadius: '8px', boxShadow: '0 0 15px 0px rgba(94, 94, 91, 20%)' } } }}>
                    <HeadingSmall overrides={{ Block: { style: { fontWeight: 'bold', margin: '0' } } }}>Basic </HeadingSmall>
                    <LabelSmall>Access to one project only</LabelSmall>
                    <Block padding='4ch 0' display='flex' flexDirection='column' gridGap='1ch'>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                      </Block>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>See detail page</LabelMedium>
                      </Block>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                      </Block>
                    </Block>
                    <ReDoButton kind='brand'>Get in touch</ReDoButton>
                  </Block>
                </IonSlide>
                <IonSlide>
                  <Block margin='1ch 0 4ch 0' minHeight='320px' padding='2ch' overrides={{ Block: { style: { textAlign: 'start', flexShrink: '0', borderRadius: '8px', boxShadow: '0 0 15px 0px rgba(94, 94, 91, 20%)' } } }}>
                    <HeadingSmall overrides={{ Block: { style: { fontWeight: 'bold', margin: '0' } } }}>Premium </HeadingSmall>
                    <LabelSmall>Access to one project only</LabelSmall>
                    <Block padding='4ch 0' display='flex' flexDirection='column' gridGap='1ch'>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                      </Block>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>See detail page</LabelMedium>
                      </Block>
                      <Block display='flex' gridGap='1ch' alignItems='center'>
                        <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                      </Block>
                    </Block>
                    <ReDoButton kind='outline'>Get in touch</ReDoButton>
                  </Block>
                </IonSlide>
              </IonSlides>
            }
          </Block>

          <Block paddingTop={breakpoint === 'large' ? '1ch' : '0'} gridGap='2ch' display='flex' justifyContent='space-between'>
            {breakpoint === 'large' &&
              <>
                <Block minHeight='320px' padding='2ch 2ch 1ch 1ch' overrides={{ Block: { style: { flexShrink: '0', borderRadius: '8px', boxShadow: '0 0 15px 0px rgba(94, 94, 91, 20%)' } } }}>
                  <Block padding='0 1ch'>
                    <HeadingXSmall overrides={{ Block: { style: { fontWeight: 'bold', margin: '0' } } }}>Single </HeadingXSmall>
                    <LabelSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Access to one project only</LabelSmall>
                  </Block>
                  <Block padding='4ch 0' display='flex' flexDirection='column' gridGap='1ch'>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                    </Block>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>See detail page</LabelMedium>
                    </Block>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                    </Block>
                  </Block>
                  <ReDoButton overrides={{ BaseButton: { style: { transform: 'scale(0.8)' } } }} kind='outline'>Get in touch</ReDoButton>
                </Block>
                <Block minHeight='320px' padding='2ch 2ch 1ch 1ch' overrides={{ Block: { style: { flexShrink: '0', borderRadius: '8px', boxShadow: '0 0 15px 0px rgba(94, 94, 91, 20%)' } } }}>
                  <Block padding='0 1ch'>
                    <HeadingXSmall overrides={{ Block: { style: { fontWeight: 'bold', margin: '0' } } }}>Basic </HeadingXSmall>
                    <LabelSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Access to one project only</LabelSmall>
                  </Block>
                  <Block padding='4ch 0' display='flex' flexDirection='column' gridGap='1ch'>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                    </Block>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>See detail page</LabelMedium>
                    </Block>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                    </Block>
                  </Block>
                  <ReDoButton overrides={{ BaseButton: { style: { transform: 'scale(0.8)' } } }} kind='brand'>Get in touch</ReDoButton>
                </Block>
                <Block minHeight='320px' padding='2ch 2ch 1ch 1ch' overrides={{ Block: { style: { flexShrink: '0', borderRadius: '8px', boxShadow: '0 0 15px 0px rgba(94, 94, 91, 20%)' } } }}>
                  <Block padding='0 1ch'>
                    <HeadingXSmall overrides={{ Block: { style: { fontWeight: 'bold', margin: '0' } } }}>Premium </HeadingXSmall>
                    <LabelSmall overrides={{ Block: { style: { fontWeight: 'bold' } } }}>Access to one project only</LabelSmall>
                  </Block>
                  <Block padding='4ch 0' display='flex' flexDirection='column' gridGap='1ch'>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                    </Block>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>See detail page</LabelMedium>
                    </Block>
                    <Block display='flex' gridGap='1ch' alignItems='center'>
                      <Check color={theme.colors.secondary200} size={32} /> <LabelMedium>Access to one project</LabelMedium>
                    </Block>
                  </Block>
                  <ReDoButton overrides={{ BaseButton: { style: { transform: 'scale(0.8)' } } }} kind='outline'>Get in touch</ReDoButton>
                </Block>
              </>
            }
          </Block>

          <Block height='28px' paddingTop={breakpoint === 'large' ? '4ch' : '2ch'} display='flex' justifyContent='center'>
            <ReDoButton overrides={{ BaseButton: { style: { fontSize: '1.8ch' } } }} kind='link' color={theme.colors.black} >Get in touch with ReDo</ReDoButton>
          </Block>

          </Block>*/}
      </ModalBody>
    </Modal>
  );
};

export default ReDoPricing;