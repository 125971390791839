export const LOAD_WALLET = '[wallet] load';
export const LOAD_WALLET_SUCCESS = '[wallet] load success';


export const loadWallet = {
  type: LOAD_WALLET
}

export const loadWalletSuccess = (payload) => ({
  type: LOAD_WALLET_SUCCESS,
  payload
})

