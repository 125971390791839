import { useStyletron } from 'baseui';


const ReDoBanner = ({ align, padding, overrides, children }) => {
  const [css] = useStyletron();

  return (
    <div className={css({
      width: '100%',
      height: align ? '100%' : '100vh',
      margin: align ? '0 -28px' : '-28px',
      position: 'absolute',
      top: '0',
      left: '0',
      background: 'white',
      display: 'flex',
      padding: padding || '28px',
      flexDirection: 'column',
      ...overrides
    })}>
      {children}
    </div>
  );
};

export default ReDoBanner;