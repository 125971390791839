import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Card, StyledAction, StyledBody } from "baseui/card";
import { ANCHOR, Drawer, SIZE } from "baseui/drawer";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { ChevronLeft, ChevronRight, Delete } from "baseui/icon";
import { HeadingXLarge, ParagraphMedium } from 'baseui/typography';
import { forwardRef, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { submitChallenge, uploadChallengePhotos } from "../application/actions/challenges";
import { routeTo } from "../application/actions/ui";
import create_solution from '../assets/images/create_solution.png';
import useBreakpoint from "../hooks/useBreakpoint";
import ReDoBanner from "./ReDoBanner";
import ReDoButton from "./ReDoButton";
import ReDoFileUpload from "./ReDoFileUpload";
import ReDoInterests from "./ReDoInterests";
import ReDoProgressBar from "./ReDoProgressBar";
import ReDoSpinner from "./ReDoSpinner";
import ReDoTextarea from "./RedoTextarea";
import { DURATION, useSnackbar } from "baseui/snackbar";


const stages = [
  { label: 'Title', curr: 1, max: 8 },
  { label: 'Problem', curr: 2, max: 8 },
  { label: 'Target Group', curr: 3, max: 8 },
  { label: 'Goal', curr: 4, max: 8 },
  { label: 'Time', curr: 5, max: 8 },
  { label: 'Incentives', curr: 6, max: 8 },
  { label: 'Why', curr: 7, max: 8 },
  { label: 'Visuals', curr: 8, max: 8 }
]

const time = [
  { group: '', list: ['Short', 'Medium', 'Long'], color: '#fff' }
]

const ReDoCreateChallenge = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const breakpoint = useBreakpoint()

  const { enqueue } = useSnackbar();

  const [stage, setStage] = useState(stages[0])
  const [payload, setPayload] = useState(props.challenge || {})
  const [photos, setPhotos] = useState([])
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false)

  const scrollable = useRef()

  const column = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    marginTop: '7ch',
    gap: '3ch'
  });

  const onNext = (e) => {
    e.preventDefault();
    scrollable.current.scrollTo(0, 0)

    const data = new FormData(e.target);
    const stepPayload = {}

    for (const pair of data.entries()) {
      stepPayload[pair[0]] = pair[1]
    }

    const finalPayload = { ...payload, ...stepPayload }

    setPayload(finalPayload)

    const currStage = stages.findIndex(s => s.curr === stage.curr)
    if (currStage < stages.length - 1) {
      setStage(stages[currStage + 1])
    } else {
      setLoading(true)
      dispatch(submitChallenge({ ...finalPayload, period: finalPayload.period?.toLowerCase() })).then(async res => {
        let challenge = res
        if (photos.length) {
          for (const photo of photos) {
            try {
              const photoRes = await dispatch(uploadChallengePhotos({ solution: challenge.id, title: photo.file ? photo.file.file.name : photo.title, photo: photo.photo }))
              challenge = photoRes
            } catch (e) {
              setLoading(false)
              enqueue({ message: e.message + ' ' + e.response?.data?.error, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> });
            }
          }
        }
        setLoading(false)
        dispatch(routeTo({ view: 'challenge', payload: { challenge: res } }))
      }).catch(e => {
        setLoading(false)
        enqueue({ message: e.response.data.error, dismissAfter: DURATION.shortest, startEnhancer: () => <Delete size={theme.sizing.scale800} /> })
      })
    }
  }


  const onPrev = (e) => {
    e.preventDefault();

    const currStage = stages.findIndex(s => s.curr === stage.curr)
    if (currStage > 0) {
      setStage(stages[currStage - 1])
    }
  }

  const onUpload = (e) => {
    setPhotos(e.map(f => f.preview ? ({ ...f, photo: f.preview }) : f))
  };



  if (breakpoint === 'large') {
    return (
      <>
        {loading && <ReDoSpinner />}
        <FlexGrid
          flexGridColumnCount={[1, 1, 1, 2]}
          flexGridRowGap="scale800"
          height='100%'
          alignItems='center'
          overflow='hidden'
          margin='0 -28px'
        >

          <FlexGridItem position='relative' height='100%' display='flex'>
            <ReDoBanner align>
              <div className={css({ height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', padding: `${theme.sizing.scale1600} ${theme.sizing.scale3200}` })}>
                <div className={css({ paddingTop: theme.sizing.scale2400 })}>
                  <HeadingXLarge overrides={{ Block: { style: { lineHeight: '48px', fontWeight: '300' } } }} width='230px'>Create Challenge</HeadingXLarge>
                </div>
                <Block display='flex' flexDirection='column' marginTop='auto'>
                  <ReDoProgressBar label={stage.label} value={stage.curr} maxValue={stage.max} />
                </Block>
              </div>
            </ReDoBanner>
          </FlexGridItem>

          <FlexGridItem height='100%'>
            <div className={column}>
              <Block position='relative' marginTop={'70px'} height='calc(100% - 215px)'>
                <Block overrides={{ Block: { style: { zIndex: '1' } } }} display={['none', 'none', 'none', 'block']} position='absolute' top='0px' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgb(243,242,238) 0%, rgba(255, 255, 255, 0) 100%)' />

                <form ref={scrollable} className={css({ height: '100%', overflow: 'auto' })} onSubmit={onNext}>
                  <div className={css({ paddingTop: '20px', maxWidth: '375px', margin: 'auto', paddingBottom: '28px', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', paddingRight: '1ch' })}>

                    {stage.curr === 1 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Give your challenge a catchy title.'
                              placeholder="Title"
                              name='title'
                              required
                              value={payload.title}
                              maxLength={20}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Summarize your challenge in one sentence.'
                              placeholder='Summary'
                              maxLength={65}
                              name='summary'
                              required
                              value={payload.summary}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 2 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Clearly define the problem you want to solve.'
                              placeholder='Type here'
                              maxLength={500}
                              name='problem'
                              required
                              value={payload.problem}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 3 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Define who you’re addressing and 
                                who will benefit from your solved 
                                challenge.'
                              id="target-input"
                              placeholder="Type here"
                              name='target'
                              required
                              value={payload.target}
                              maxLength={500}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 4 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Set a clear goal you want to achieve at 
                                the end of the challenge.'
                              placeholder='Type here'
                              maxLength={250}
                              name='goal'
                              required
                              value={payload.goal}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 5 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoInterests
                              label='Specify how long your challenge is 
                                open for entries. Choose an option 
                                below.'
                              options={time}
                              values={payload?.period}
                              name='period'
                              radio
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 6 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Offer incentives such as prize money, 
                                funding or mentorship to increase the 
                                impact of the challenge and attract a 
                                wide range of participants. Define 
                                what you’re offering to the ReDo 
                                Community.'
                              placeholder='Type here'
                              maxLength={250}
                              name='incentives'
                              required
                              value={payload.incentives}
                            />
                          </FormControl>
                        </Block>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='If you want to link your companies own legal 
                            documents such as terms & conditions 
                            please link or upload them below. '
                              placeholder="Insert link"
                              name='agbUrl'
                              value={payload.agbUrl}
                              maxLength={40}
                              link
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 7 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoTextarea
                              label='Tell the ReDo Community why they 
                                should solve your challenge. Write a 
                                few motivating and engaging 
                                sentences.'
                              placeholder='Type here'
                              maxLength={250}
                              name='why'
                              required
                              value={payload.why}
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    {stage.curr === 8 &&
                      <>
                        <Block width='100%' marginTop='5%'>
                          <FormControl>
                            <ReDoFileUpload
                              multiple
                              maxCount={3}
                              onUpload={onUpload}
                              onRemove={onUpload}
                              label='Please upload up to 3 images or 
                                drawings, promoting and visualizing 
                                your challenge. (Recommended size 
                                abc x abc px)'
                            />
                          </FormControl>
                        </Block>
                      </>
                    }

                    <Block paddingBottom='50px' justifyContent='space-between' display='flex' width='100%' marginTop='5%'>
                      {stage.curr !== 1 &&
                        <ReDoButton
                          type='button'
                          onClick={onPrev}
                          kind='inverse'
                          overrides={{
                            BaseButton: {
                              style: {
                                marginTop: '0',
                                marginBottom: '0',
                                paddingLeft: '0 !important',
                                paddingTop: '0 !important',
                                paddingBottom: '0 !important',
                                backgroundColor: 'transparent',
                                color: theme.colors.black,
                                width: 'auto',
                                fontWeight: 'bold'
                              }
                            },
                            StartEnhancer: {
                              style: {
                                marginRight: '0'
                              }
                            }
                          }}
                          startEnhancer={() => <ChevronLeft size={theme.sizing.scale1000} />}
                        >
                          Back
                        </ReDoButton>
                      }
                      <ReDoButton
                        type='submit'
                        kind='brand'
                        overrides={{
                          BaseButton: {
                            style: {
                              marginBottom: '0',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              paddingRight: '0',
                              width: 'auto',
                              marginLeft: 'auto'
                            }
                          },
                          EndEnhancer: {
                            style: {
                              marginLeft: '0'
                            }
                          }
                        }}
                        endEnhancer={() => <ChevronRight size={theme.sizing.scale1000} />}
                      >
                        {stage.curr !== 8 ? 'Next' : 'Submit'}
                      </ReDoButton>
                    </Block>

                  </div>
                </form>

                <Block display={['none', 'none', 'none', 'block']} position='absolute' bottom='0' left='0' width='100%' height='50px' backgroundImage='linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(243,242,238) 100%)' />

              </Block>
            </div>
          </FlexGridItem>
        </FlexGrid>
      </>
    );
  } else {
    return (
      <>

        <Card
          title='Create a Challenge!'
          overrides={{
            Root: {
              style: {
                height: '75%', border: 'none', borderRadius: '25px !important',
                backgroundImage: `url(${create_solution})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                margin: 'auto 0'
              }
            },
            Title: {
              style: {
                color: 'white',
                fontSize: '2rem',
                fontWeight: 'normal',
              }
            },
            Contents: {
              style: {
                height: '100%',
                margin: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '2ch'
              }
            }
          }}
        >
          <StyledBody>
            <ParagraphMedium color='white'>
              Complete this form and tell
              us about your solution. Your
              input helps us understand your
              goals and approach. Take your time
              - the more detail you share, the better!

              *Please note that all of the information
              you provide will be visible to the community.
              There is an option in section
              3 to upload 'hidden information'
              which will only be accessible once your solution
              is purchased.

            </ParagraphMedium>
          </StyledBody>
          <StyledAction>
            <Block display='flex' alignItems='center' justifyContent='flex-end'>
              <Button onClick={() => setIsOpen(true)} shape="pill" overrides={{ BaseButton: { style: { fontWeight: 'bold', color: 'black', backgroundColor: 'white', minWidth: '30%', padding: '1ch 2ch !important', ':hover': { backgroundColor: theme.colors.secondary200 } } } }}>
                Upload now
              </Button>
            </Block>
          </StyledAction>
        </Card>
        <Drawer
          isOpen={isOpen}
          autoFocus
          onClose={() => {
            setIsOpen(false)
          }}
          anchor={ANCHOR.bottom}
          size={SIZE.default}
          showBackdrop={true}
          overrides={{
            DrawerContainer: {
              style: {
                borderTopRightRadius: '32px',
                borderTopLeftRadius: '32px',
                height: 'calc(100vh - 190px)'
              }
            },
            DrawerBody: {
              style: {
                margin: '0 !important',
                padding: '0',
                overflow: 'hidden'
              }
            },
            Close: {
              style: {
                color: theme.colors.primary300,
                width: '48px',
                height: '48px',
                background: 'white'
              }
            }
          }}
        >
          {loading && <ReDoSpinner />}
          <div ref={scrollable} className={`${column} ${css({ paddingTop: '3ch', overflow: 'auto' })}`}>

            <Block padding='0 3ch' justifyContent='space-between' display='flex' flexDirection='column'>
              <Block alignSelf='flex-end' justifyContent='space-between' display='flex'>
                <ReDoProgressBar kind='text' label={stage.label} value={stage.curr} maxValue={stage.max} />
              </Block>

              <HeadingXLarge paddingTop='1ch' margin='0'>{stage.label}</HeadingXLarge>
            </Block>

            <Block height='100%'>
              <form className={css({ padding: '0 3ch', alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%' })} onSubmit={onNext}>
                <div className={css({ width: '100%', maxWidth: '375px', paddingBottom: '10ch' })}>
                  {stage.curr === 1 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Give your challenge a catchy title.'
                            placeholder="Title"
                            name='title'
                            required
                            value={payload.title}
                            maxLength={20}
                            shadow
                          />
                        </FormControl>
                      </Block>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Summarize your challenge in one sentence.'
                            placeholder='Summary'
                            maxLength={65}
                            name='summary'
                            required
                            value={payload.summary}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 2 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Clearly define the problem you want to solve.'
                            placeholder='Type here'
                            maxLength={500}
                            name='problem'
                            required
                            value={payload.problem}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 3 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Define who you’re addressing and
                                    who will benefit from your solved
                                    challenge.'
                            id="target-input"
                            placeholder="Type here"
                            name='target'
                            required
                            value={payload.target}
                            maxLength={500}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 4 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Set a clear goal you want to achieve at
                                    the end of the challenge.'
                            placeholder='Type here'
                            maxLength={250}
                            name='goal'
                            required
                            value={payload.goal}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 5 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoInterests
                            label='Specify how long your challenge is
                                    open for entries. Choose an option
                                    below.'
                            options={time}
                            values={payload?.period}
                            name='period'
                            radio
                            invert
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 6 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Offer incentives such as prize money,
                                    funding or mentorship to increase the
                                    impact of the challenge and attract a
                                    wide range of participants. Define
                                    what you’re offering to the ReDo
                                    Community.'
                            placeholder='Type here'
                            maxLength={250}
                            name='incentives'
                            required
                            value={payload.incentives}
                            shadow
                          />
                        </FormControl>
                      </Block>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='If you want to link your companies own legal 
                            documents such as terms & conditions 
                            please link or upload them below. '
                            placeholder="Insert link"
                            name='agbUrl'
                            value={payload.agbUrl}
                            maxLength={40}
                            link
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 7 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoTextarea
                            label='Tell the ReDo Community why they
                                    should solve your challenge. Write a
                                    few motivating and engaging
                                    sentences.'
                            placeholder='Type here'
                            maxLength={250}
                            name='why'
                            required
                            value={payload.why}
                            shadow
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  {stage.curr === 8 &&
                    <>
                      <Block width='100%' marginTop='5%'>
                        <FormControl>
                          <ReDoFileUpload
                            multiple
                            maxCount={3}
                            onUpload={onUpload}
                            onRemove={onUpload}
                            label='Please upload up to 3 images or
                                    drawings, promoting and visualizing
                                    your challenge. (Recommended size
                                    abc x abc px)'
                          />
                        </FormControl>
                      </Block>
                    </>
                  }
                  <Block justifyContent='space-between' display='flex' width='100%' marginTop='5%'>
                    {stage.curr !== 1 &&
                      <ReDoButton
                        type='button'
                        onClick={onPrev}
                        kind='inverse'
                        overrides={{
                          BaseButton: {
                            style: {
                              marginTop: '0',
                              marginBottom: '0',
                              paddingLeft: '0 !important',
                              paddingTop: '0 !important',
                              paddingBottom: '0 !important',
                              backgroundColor: 'transparent',
                              color: theme.colors.black,
                              width: 'auto'
                            }
                          }
                        }}
                        startEnhancer={() => <ChevronLeft size={theme.sizing.scale1000} />}
                      >
                        Back
                      </ReDoButton>
                    }
                    <ReDoButton
                      type='submit'
                      kind='brand'
                      overrides={{
                        BaseButton: {
                          style: {
                            marginBottom: '0',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            paddingRight: '0',
                            width: 'auto',
                            marginLeft: 'auto'
                          }
                        }
                      }}
                      endEnhancer={() => <ChevronRight size={theme.sizing.scale1000} />}
                    >
                      {stage.curr !== 8 ? 'Next' : 'Submit'}
                    </ReDoButton>
                  </Block>
                </div>
              </form>
            </Block>

          </div >
        </Drawer >
      </>
    )
  }
});

export default ReDoCreateChallenge;