import { IonActionSheet, IonContent, IonModal, IonPage } from "@ionic/react";
import { useStyletron } from "baseui";
import { StatelessAccordion } from "baseui/accordion";
import { Avatar } from "baseui/avatar";
import { Badge } from "baseui/badge";
import { Block } from "baseui/block";
import { Button, SHAPE } from "baseui/button";
import { ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { forwardRef, useEffect, useState } from "react";
import { BsSend } from "react-icons/bs";
import { FaShare } from "react-icons/fa";
import { HiUpload } from "react-icons/hi";
import { MdBookmarkAdded, MdBookmarkBorder } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { TbClock } from "react-icons/tb";
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
import { CommentSection } from "react-swift-nested-comments";
import { favoriteChallenge, loadChallengeComments, shareChallenge, submitComment } from "../../application/actions/challenges";
import { loadCompanyById } from "../../application/actions/company";
import { routeTo, setPortalElement } from "../../application/actions/ui";
import ReDoButton from "../../components/ReDoButton";
import ReDoCustomPanel from "../../components/ReDoCustomPanel";
import ReDoShareModal from "../../components/ReDoShareModal";
import ReDoSplitView from "../../components/ReDoSplitView";
import useBreakpoint from "../../hooks/useBreakpoint";
import useWhoAmI from "../../hooks/useWhoAmI";


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};


const Challenge = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [css, theme] = useStyletron();
  const [challenge, setChallenge] = useState(props.challenge);
  const [actionSheetOpen, setActionSheetOpen] = useState(false)
  const [currentSlide, setCurrentSlide] = useState();
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState();
  const [comments, setComments] = useState([])

  const breakpoint = useBreakpoint();

  const { isCompany, userProfile } = useWhoAmI();

  const currentUser = !!Object.keys(userProfile).length ? {
    currentUserId: userProfile.userId,
    currentUserFullName: userProfile.username || userProfile.fullname
  } : null

  const daysLeftTillExpiration = challenge.expirationDate ? Math.floor((new Date(challenge.expirationDate) - new Date()) / (1000 * 60 * 60 * 24)) : null

  const share = () => {
    setShareModalOpen({ visible: true, title: 'Share', description: 'Forward this project to your friends and colleagues.' })
  }

  const favorite = () => {
    dispatch(favoriteChallenge({ challengeId: challenge.id })).then((response) => {

      setChallenge(response)
    })
  }

  const handleImageClick = (event, photo) => {
    event.stopPropagation();
    setCurrentSlide(photo);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onSubmitComment = (data) => {
    let comment = {}

    if (data.repliedToCommentId) {
      comment = {
        projectId: challenge.id,
        content: data.text,
        rootId: data.parentOfRepliedCommentId ? data.parentOfRepliedCommentId : data.repliedToCommentId,
        parentId: data.repliedToCommentId
      }
    } else {
      comment = {
        projectId: challenge.id,
        content: data.text
      }
    }

    dispatch(submitComment(comment)).then(r => dispatch(loadChallengeComments(challenge.id))
      .then((response) => {

        setComments(response.map(res => ({
          ...res,
          customAvatar: <Avatar
            name={res.fullName}
            size="28px"
            src={res.avatarUrl}
          />
        })))
      }))
  }

  const createSolution = () => {
    dispatch(routeTo({ view: 'userProfile', tab: '1', payload: { challenge } }))
  }

  /*const onAvatarClick = () => {
    dispatch(setPortalElement({ element: 'profileContainer', payload: { user } }))
  }*/

  useEffect(() => {
    dispatch(loadCompanyById(challenge.companyId))
      .then((response) => {
        setUser(response);
      })
    dispatch(loadChallengeComments(challenge.id))
      .then((response) => {

        setComments(response.map(res => ({
          ...res,
          customAvatar: <Avatar
            name={res.fullName}
            size="28px"
            src={res.avatarUrl}
          />
        })))
      })
  }, [dispatch, challenge.companyId, challenge.id])



  return (
    <IonPage ref={ref} >
      <IonContent className="ion-padding">

        <IonActionSheet
          isOpen={!!actionSheetOpen}
          buttons={[
            {
              text: 'Edit',
              data: {
                action: 'edit',
              },
              handler: () => !isCompany ? dispatch(routeTo({ view: 'createSolution', payload: { solution: actionSheetOpen } })) : dispatch(routeTo({ view: 'createChallenge', payload: { challenge: actionSheetOpen } }))
            },
            {
              text: 'Private Information',
              data: {
                action: 'privateInformation',
              },
              handler: () => dispatch(setPortalElement({ element: 'hiddenFieldContainer', payload: { note: actionSheetOpen?.note || actionSheetOpen?.why } }))
            },
            {
              text: 'Delete Solution',
              role: 'destructive',
              data: {
                action: 'delete',
              },
            },
          ]}
          onDidDismiss={() => setActionSheetOpen(false)}
        />

        <IonModal className={css({ '--height': '90vh', '--width': '90vw' })} isOpen={showModal} onDidDismiss={handleCloseModal}>
          <img onClick={handleCloseModal} className={css({ objectFit: 'contain', height: '100%', width: '100%', margin: 'auto' })} src={currentSlide} alt="" />
        </IonModal>

        <ReDoShareModal
          onBeforeClick={() => dispatch(shareChallenge({ challengeId: challenge.id }))}
          modalTitle={shareModalOpen?.title}
          modalDescription={shareModalOpen?.description}
          link={`/challenge/${challenge.id}`}
          visible={shareModalOpen?.visible}
          onClose={() => setShareModalOpen({ visible: false })}
          title='Check out this project on ReDo!'
          url={`${window.location.origin}/challenge/${challenge.id}`}
        />


        <ReDoSplitView
          title={challenge.title}
          titleEndEnhancer={
            <Block display='flex' gridGap='2ch'>
              <Block minWidth='6ch' display='flex' alignItems='center' justifyContent='center' gridGap='1ch' overrides={{ Block: { style: { borderRadius: '18px', boxShadow: breakpoint === 'large' ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : 'none', backgroundColor: 'white', height: '4ch', width: '4ch' } } }} >
                <HiUpload />
                {challenge.submittedSolutionCount}
              </Block>
              <Button onClick={favorite} overrides={{ BaseButton: { style: { boxShadow: breakpoint === 'large' ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : 'none', backgroundColor: 'white', height: '4ch', width: '4ch' } } }} kind="tertiary" shape={SHAPE.circle}>
                {!challenge.isFavorite ? <MdBookmarkBorder /> : <MdBookmarkAdded />}
              </Button>
              <Button onClick={share} overrides={{ BaseButton: { style: { boxShadow: breakpoint === 'large' ? `0 0 15px 0px rgba(94, 94, 91, 20%)` : 'none', backgroundColor: 'white', height: '4ch', width: '4ch' } } }} kind="tertiary" shape={SHAPE.circle}>
                <FaShare />
              </Button>
            </Block>
          }
          cover={
            <Block position='relative'>
              <Slider {...settings}>
                {challenge.photos.map((photo, index) =>
                  <div key={index}>
                    <img onClick={(e) => handleImageClick(e, photo.photo)} className={css({ objectFit: 'cover !important', height: '400px !important', width: '100% !important' })} src={photo.photo} alt='' />
                  </div>
                )}
              </Slider>
            </Block>
          }
          titleBottomEnhancer={
            <Block marginTop='1ch' display='flex' flexDirection='column'>
              <Block display='flex' alignItems='center' gridGap='1ch'>
                <Badge overrides={{ Badge: { style: { fontSize: '12px', backgroundColor: theme.colors.secondary0, color: 'black', textTransform: 'uppercase', fontWeight: '300' } } }} content={challenge.period} />
              </Block>

            </Block>
          }
          leftSection={
            <div className={css({ width: '100%', textAlign: 'center', alignSelf: 'center', marginTop: 'auto' })}>
              <Block>

                {
                  <>
                    {!isCompany && <Block display='flex' justifyContent='center'><Block width='75%' display='flex' justifyContent='center'><ReDoButton onClick={createSolution} overrides={{ BaseButton: { style: { width: '75% !important' } } }} kind='brand'>Solve this challenge</ReDoButton></Block></Block>}
                    {userProfile.id === challenge.companyId && <Block onClick={() => setActionSheetOpen(challenge)} margin='auto' width='56px'><ReDoButton kind='brand'>...</ReDoButton></Block>}
                  </>
                }

              </Block>
            </div>
          }
          mainSection={
            <>
              <Block>
                <Block marginBottom='2ch' marginLeft='1.5ch' color={theme.colors.primary200} display='flex' alignItems='center' gridGap='1ch'>

                  {daysLeftTillExpiration} days left
                  <TbClock />
                </Block>
                <Block overrides={{ Block: { style: { borderRadius: '12px' } } }} padding='20px' backgroundColor='white'>
                  <ParagraphSmall overrides={{ Block: { style: { margin: '0 0 16px 0', fontWeight: '600', textAlign: 'end', color: theme.colors.primary200 } } }}>CHALLENGE</ParagraphSmall>
                  <div className={css({ fontSize: '18px' })}>
                    <b>Dear Community,</b>
                    <br />
                    <br />
                    {challenge.problem}
                    <br />
                    <br />
                    Yours sincerely,
                    <br />
                    {user.name}
                  </div>
                </Block>
              </Block>

              <StatelessAccordion
                expanded={expanded}
                onChange={(props) => setExpanded(props.expanded)}
                accordion={false}
                overrides={{
                  Root: {
                    style: {
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4ch',
                    },
                  },
                }
                }
              >
                <ReDoCustomPanel
                  title='TARGET GROUP'
                  key='TARGET GROUP'
                  list={expanded}
                >
                  <div className={css({ fontSize: '18px' })}>
                    <b>Who are you addressing?</b>
                    <br /><br />
                    {challenge.target}
                  </div>
                </ReDoCustomPanel>

                <ReDoCustomPanel
                  title='GOAL'
                  key='GOAL'
                  list={expanded}
                >
                  <div className={css({ fontSize: '18px' })}>
                    <b>What outcome do you hope for?</b>
                    <br /><br />
                    {challenge.goal}
                  </div>
                </ReDoCustomPanel>

                <ReDoCustomPanel
                  title='TERMS AND CONDITIONS'
                  key='TERMS AND CONDITIONS'
                  list={expanded}
                >
                  <div className={css({ fontSize: '18px' })}>
                    <b>Additional terms and conditions from the
                      challange creator:</b>
                    <br />
                    <br />
                    <a className={css({ textUnderlineOffset: '.5ch' })} href={challenge.agbUrl}>{challenge.agbUrl}</a>
                  </div>
                </ReDoCustomPanel>

                <ReDoCustomPanel
                  title='INCENTIVES'
                  key='INCENTIVES'
                  list={expanded}
                >
                  <div className={css({ fontSize: '18px' })}>
                    <b>What are you offering?</b>
                    <br />
                    <br />
                    {challenge.incentives}
                  </div>
                </ReDoCustomPanel>
              </StatelessAccordion>

              <Block overrides={{ Block: { style: { borderRadius: '12px' } } }} padding='20px' backgroundColor='white'>
                <ParagraphSmall overrides={{ Block: { style: { margin: '0 0 16px 0', fontWeight: '600', textAlign: 'end', color: theme.colors.primary200 } } }}>GET INVOLVED</ParagraphSmall>
                <div className={css({ fontSize: '18px' })}>
                  <b>Dear Community,</b>
                  <br />
                  <br />
                  {challenge.why}
                  <br />
                  <br />
                  Thank you,
                  <br />
                  <Block alignItems='center' display='flex' marginTop='2ch' gridGap='1ch'>
                    <Block /*overrides={{ Block: { style: { cursor: 'pointer' } } }}*/ ><Avatar name={user.fullname} size="42px" src={user.photo} /></Block>
                    <Block display='flex' flexDirection='column'>
                      <ParagraphMedium marginTop='0' marginBottom='0' overrides={{ Block: { style: { fontWeight: 'bold' } } }}>{user.fullname}</ParagraphMedium>
                      <Block>
                        <ParagraphMedium marginTop='0' marginBottom='0'>{user.name}</ParagraphMedium>
                      </Block>
                    </Block>
                  </Block>
                </div>
              </Block>

              <Block position='relative'>
                <CommentSection
                  key={currentUser}
                  customAvatar={
                    <Avatar
                      name={userProfile.fullname}
                      size="38px"
                      src={userProfile.photo}
                    />
                  }
                  placeholder='comment here...'
                  submitBtnContent={<BsSend color="black" />}
                  cancelBtnContent={<RxCross1 color="black" />}
                  cancelBtnStyle={{ border: '0', backgroundColor: 'transparent', margin: '0 1ch 0 auto', padding: '0' }}
                  currentUser={currentUser}
                  logIn={{
                    loginLink: 'http://localhost:3001/',
                    signupLink: 'http://localhost:3001/'
                  }}
                  imgStyle={{ maxWidth: 'none', objectFit: 'cover' }}
                  inputStyle={{ border: '0', marginLeft: '1ch' }}
                  replyInputStyle={{ border: '0' }}
                  submitBtnStyle={{ border: '0', backgroundColor: 'transparent', margin: '0 1ch 0 0', padding: '0' }}
                  overlayStyle={{ fontFamily: 'unset', padding: '0' }}
                  titleStyle={{ fontFamily: 'unset', fontSize: '18px' }}
                  formStyle={{ backgroundColor: 'transparent', padding: '0 0 1ch 0', margin: '2ch 0', borderBottom: `1px solid ${theme.colors.secondary200}`, borderRadius: '0' }}
                  hrStyle={{ display: 'none' }}
                  commentData={comments}
                  onSubmitAction={onSubmitComment}
                  onReplyAction={onSubmitComment}
                  removeEmoji
                />
              </Block>
            </>
          }
          bottomSection={
            <Block display={['block', 'block', 'block', 'none']}>

              {
                <>
                  {!isCompany && <ReDoButton onClick={createSolution} kind='brand'>Solve this challenge</ReDoButton>}
                  {userProfile.id === challenge.companyId && <Block onClick={() => setActionSheetOpen(challenge)} margin='auto' width='56px'><ReDoButton kind='brand'>...</ReDoButton></Block>}
                </>
              }

            </Block>
          }
        />

      </IonContent>
    </IonPage >
  );
});

export default Challenge;