import { LOAD_NOTIFICATIONS, loadNotificationsSuccess } from "../actions/notifications";


export const notifications = {

  [LOAD_NOTIFICATIONS]: async ({ api, dispatch, getState, action }) => {
    const response = await api.notifications.getNotifications();

    

    const texts = {
      comment: 'commented on your',
      vote: 'voted for your',
      reply: 'replied to your comment on',
      inbox: 'unlocked a chat with you! Start a conversation now.',
      message: 'sent you a new message.',
      purchase: 'unlocked your solution. Check your wallet!',
    }

    const timeSince = (dateString) => {
      const date = new Date(dateString);
      const today = new Date();
      const timeDiff = today.getTime() - date.getTime();
      const minutesDiff = Math.round(timeDiff / (1000 * 60));

      if (minutesDiff < 60 * 24) {
        return new Date(date).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })
      } else if (minutesDiff < 60 * 24 * 30) {
        const daysDiff = Math.floor(minutesDiff / (60 * 24));
        return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
      } else if (minutesDiff < 60 * 24 * 365) {
        const monthsDiff = Math.floor(minutesDiff / (60 * 24 * 30));
        return `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ago`;
      } else {
        const yearsDiff = Math.floor(minutesDiff / (60 * 24 * 365));
        return `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} ago`;
      }
    }

    const mappedNotifications = response.data?.filter(notification => ['vote', 'comment', 'inbox', 'message', 'purchase'].includes(notification.type.split('_')?.[1])).map((notification) => ({
      ...notification,
      id: notification.id,
      user: { name: notification.data.name || notification.data.fromName || notification.data.companyName, photo: notification.data.photo || notification.data.fromPhoto || notification.data.companyPhoto },
      title: !['inbox', 'message', 'purchase'].includes(notification.type.split('_')?.[notification.type.split('_').length - 1]) ? `${texts[notification.type.split('_')?.[notification.type.split('_').length - 1]]} ${notification?.data?.type || notification?.data?.projectType} ${notification?.data?.projectTitle}` : `${texts[notification.type.split('_')?.[notification.type.split('_').length - 1]]}`,
      subtitle: timeSince(notification.timestamp * 1000),
      unread: !notification.isRead,
      icon: notification.data.projectPhoto
    })).sort((a, b) => b.timestamp - a.timestamp);

    dispatch(loadNotificationsSuccess(mappedNotifications))

    return mappedNotifications
  },

}