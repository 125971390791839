import { useStyletron } from 'baseui';
import ShareOnSocial from "react-share-on-social";
import logo from '../assets/images/logo.jpg';
import girl_glasses from '../assets/images/girl_glasses.png';
import { Modal, ModalBody } from "baseui/modal";
import { WhatsappShareButton, WhatsappIcon, FacebookIcon, FacebookShareButton, TwitterShareButton } from "react-share";
import { Block } from 'baseui/block';
import { HeadingLarge, LabelXSmall, ParagraphMedium } from 'baseui/typography';
import { Button, SHAPE } from 'baseui/button';
import { FaShare } from 'react-icons/fa';
import { useDispatch } from 'react-redux'
import { setPortalElement } from '../application/actions/ui';


const ReDoShareModal = ({ url, link, visible, onClose, title, modalTitle, modalDescription, onBeforeClick, ...props }) => {
  const dispatch = useDispatch()
  const [css, theme] = useStyletron();

  const handleClose = () => {
    onClose && onClose()
    dispatch(setPortalElement())
  }

  const handleBeforeOnClick = () => {
    if (onBeforeClick)
      return onBeforeClick()
  }

  return (
    <Modal
      overrides={{
        DialogContainer: {
          style: {
            backgroundColor: theme.colors.primary400 + 'e6',
          }
        },
        Dialog: {
          style: {
            maxWidth: '400px',
            padding: '2ch',
            borderRadius: '21px',
          }
        },
        Close: {
          style: {
            color: theme.colors.primary300,
            width: '36px',
            height: '36px',
            transform: 'scale(2)',
          }
        }
      }}
      onClose={handleClose}
      isOpen={visible}>
      <ModalBody>
        <Block marginTop='7ch' maxHeight='70vh' overflow='auto' display='flex' flexDirection='column'>
          <img height='200px' width='100%' className={css({ objectFit: 'contain' })} src={girl_glasses} alt="" />
          <HeadingLarge overrides={{ Block: { style: { fontWeight: '600' } } }}>{modalTitle}</HeadingLarge>
          <ParagraphMedium>{modalDescription}</ParagraphMedium>
          <Block marginTop='2ch' display='flex' gridGap='2ch' justifyContent='space-between'>
            <WhatsappShareButton
              beforeOnClick={handleBeforeOnClick}
              url={url}
              title={title}
            >
              <Block display='flex' flexDirection='column' alignItems='center' gridGap='1ch'>
                <WhatsappIcon size={42} round />
                <LabelXSmall>Whatsapp</LabelXSmall>
              </Block>
            </WhatsappShareButton>
            <FacebookShareButton
              beforeOnClick={handleBeforeOnClick}
              url={url}
              quote={title}
            >
              <Block display='flex' flexDirection='column' alignItems='center' gridGap='1ch'>
                <FacebookIcon size={42} round />
                <LabelXSmall>Facebook</LabelXSmall>
              </Block>
            </FacebookShareButton>
            <TwitterShareButton
              beforeOnClick={handleBeforeOnClick}
              url={url}
              title="Check out this project on ReDo!"
            >
              <Block display='flex' flexDirection='column' alignItems='center' gridGap='1ch'>
                <svg viewBox="0 0 64 64" width="40" height="40" fill="#ffffff">
                  <circle cx={32} cy={32} r={31} fill="#0B2972" />
                  <path
                    d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z"
                    fill="white"
                  ></path>
                </svg>
                <LabelXSmall>Twitter</LabelXSmall>
              </Block>
            </TwitterShareButton>
            <ShareOnSocial
              link={link}
              linkTitle={title}
              linkMetaDesc='Check out this project on ReDo!'
              textToShare='Check out this project on ReDo!'
              linkFavicon={logo}
            >
              <Block display='flex' flexDirection='column' alignItems='center' gridGap='1ch'>
                <Button overrides={{ BaseButton: { style: { backgroundColor: theme.colors.secondary200, height: '42px', width: '42px' } } }} kind="tertiary" shape={SHAPE.circle}>
                  <FaShare />
                </Button>
                <LabelXSmall>More</LabelXSmall>
              </Block>
            </ShareOnSocial>
          </Block>
        </Block>
      </ModalBody>
    </Modal>
  );
};

export default ReDoShareModal;